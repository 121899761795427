import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import queryString from "query-string";
import { index } from "./algolia";
import { auth, firestore, Timestamp } from './firebase';
import { UserContext } from './providers/UserProvider';

export default function Sidebar({ newItem, newItemText, collection, recordType, itemTitle, itemSubtitle, itemLink, orders, defaultOrder, filters, defaultOpen }) {
    const history = useHistory();
    const location = useLocation();

    const { role } = useContext(UserContext);

    const [queryParams, setQueryParams] = useState({});
    const [order, setOrder] = useState(orders?.find(e => e.id === defaultOrder));
    const [open, setOpen] = useState(defaultOpen??true);
    const [items, setItems] = useState([]);
    const [limit, setLimit] = useState(30);
    const [search, setSearch] = useState(null);
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => setQueryParams(queryString.parse(location.search)), [location.search]);
    useEffect(() => setOrder(orders?.find(e => e.id === (queryParams?.order??defaultOrder))), [defaultOrder, queryParams]);
    useEffect(() => {
        if(search) return;
        if(!collection) return;
        let q = firestore.collection(collection);
        if(order) q = q.orderBy(order.field, order.direction);
        if(order?.fromNow) q = q.where(order.field, order.direction === 'asc' ? '>' : '<', Timestamp.now());
        if(collection === 'events' && role === 'partner') q = q.where('city', '==', 'cologne'); //TODO: this is temporary
        for(const filter of filters??[]) {
            if(queryParams[filter.id]) {
                q = q.where(filter.field, filter.type === 'equals' ? '==' : filter.type, queryParams[filter.id]);
            }
        }
        q.limit(limit).get().then(({docs}) => setItems(docs.map((e) => ({objectID: '-' + e.id, ...e.data()}))));
    }, [collection, order, limit, filters, queryParams]);
    useEffect(() => search ? index.search(search, { filters: `recordType:"${recordType}"` }).then(({ hits }) => setItems(hits)) : null, [search]);
    useEffect(() => {
        window.addEventListener('resize', () => setWidth(window.innerWidth));
        return () => {
            window.removeEventListener('resize', () => setWidth(window.innerWidth));
        }
    }, []);

    const scroll = (e) => {
        if(e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 100 && items.length === limit)
            setLimit(limit + 30);
    }

    const sortItems = (items) => {
        if(!order) return items;
        items = items.sort((a, b) => {
            let aValue = a[order.field];
            let bValue = b[order.field];
            aValue = aValue?._seconds??aValue;
            bValue = bValue?._seconds??bValue;
            if(order.direction === 'asc') return aValue - bValue;
            else return bValue - aValue;
        });
        if(order.fromNow) {
            items = items.filter(e => {
                const now = Math.round(Date.now() / 1000);
                const seconds = e[order.field]?._seconds??e[order.field]?.seconds??0;
                return order.direction === 'asc' ? seconds > now : seconds < now;
            });
        }
        for(const filter of filters??[]) {
            if(queryParams[filter.id]) {
                items = items.filter(e => {
                    switch(filter.type) {
                        case 'array-contains': return e[filter.field]?.includes(queryParams[filter.id])??false;
                        case 'equals': return e[filter.field] === queryParams[filter.id];
                        default: return true;
                    }
                });
            }
        }
        return items;
    };

    // Only fpr changelogs
    const handleMenuClick = () => {
        if(!window.location.pathname.includes("/changelogs")) return
        if(document.getElementById("submenu") && open === false) {
            document.getElementById("submenu").classList.remove("hidden");
        }
        else if(document.getElementById("submenu") && open === true) {
            document.getElementById("submenu").classList.add("hidden");
        }
    }

    return open || width > 768 ? <div className="w-screen md:w-auto h-full flex text-white">
        <div className="overflow-hidden w-60 bg-black h-full flex-col flex">
            <div className="flex p-2 justify-between">
                {width <= 768 && <div className="m-2 font-semibold" onClick={(_) => {setOpen(false);handleMenuClick()}}>
                    ☰
                </div>}
                <div className="font-bold text-lg text-center p-3">
                    ELGIO ADMIN
                </div>
            </div>
            <div className="grow-1 min-h-0 overflow-y-scroll">
                <div className="font-semibold text-lg p-3 mt-5">
                    Datenbank
                </div>
                <NavLink className="flex block p-3 pl-8 text-gray-300" activeClassName="bg-gray-800" to="/events">
                    Events
                </NavLink>
                <NavLink className="flex block p-3 pl-8 text-gray-300" activeClassName="bg-gray-800" to="/recurring-events">
                    Eventreihen
                </NavLink>
                <NavLink className="flex block p-3 pl-8 text-gray-300" activeClassName="bg-gray-800" to="/organizers">
                    Organizer
                </NavLink>
                {role === 'admin' && <NavLink className="flex block p-3 pl-8 text-gray-300" activeClassName="bg-gray-800" to="/artists">
                    Artists
                </NavLink>}
                {role === 'admin' && <NavLink className="flex block p-3 pl-8 text-gray-300" activeClassName="bg-gray-800" to="/users">
                    Users
                </NavLink>}
                <NavLink className="flex block p-3 pl-8 text-gray-300" activeClassName="bg-gray-800" to="/genres">
                    Musikrichtungen
                </NavLink>
                <NavLink className="flex block p-3 pl-8 text-gray-300" activeClassName="bg-gray-800" to="/types">
                    Eventtypen
                </NavLink>
                {role === 'admin' && <NavLink className="flex block p-3 pl-8 text-gray-300" activeClassName="bg-gray-800" to="/cities">
                    Städte
                </NavLink>}
                {role === 'admin' && <NavLink className="flex block p-3 pl-8 text-gray-300" activeClassName="bg-gray-800" to="/subsites">
                    Subsites
                </NavLink>}
                {role === 'admin' && <NavLink className="flex block p-3 pl-8 text-gray-300" activeClassName="bg-gray-800" to="/articles">
                    Artikel
                </NavLink>}
                {role === 'admin' && <>
                    <div className="font-semibold text-lg p-3 mt-5">
                        Ticketing
                    </div>
                    <NavLink className="flex block p-3 pl-8 text-gray-300" activeClassName="bg-gray-800" to="/tickets">
                        Tickets
                    </NavLink>
                </>}
                {role === 'admin' && <>
                    <div className="font-semibold text-lg p-3 mt-5">
                        Support
                    </div>
                    <NavLink className="flex block p-3 pl-8 text-gray-300" activeClassName="bg-gray-800" to="/generate-documents">
                        PDF Generierung
                    </NavLink> 
                    <NavLink className="flex block p-3 pl-8 text-gray-300" activeClassName="bg-gray-800" to="/auto-posts">
                        Automatische Posts
                    </NavLink>
                    <NavLink className="flex block p-3 pl-8 text-gray-300" activeClassName="bg-gray-800" to="/updates">
                        Updates
                    </NavLink>
                    <NavLink className="flex block p-3 pl-8 text-gray-300" activeClassName="bg-gray-800" to="/backlog">
                        Backlog
                    </NavLink>
                    <NavLink className="flex block p-3 pl-8 text-gray-300" activeClassName="bg-gray-800" to="/overview">
                        Stadtübersicht
                    </NavLink>
                    <NavLink className="flex block p-3 pl-8 text-gray-300" activeClassName="bg-gray-800" to="/reports">
                        Meldungen
                    </NavLink>
                    <NavLink className="flex block p-3 pl-8 text-gray-300" activeClassName="bg-gray-800" to="/suggestions">
                        Vorschläge
                    </NavLink>
                    <NavLink className="flex block p-3 pl-8 text-gray-300" activeClassName="bg-gray-800" to="/pushnotifications">
                        Push-Notifications
                    </NavLink>
                    <NavLink className="flex block p-3 pl-8 text-gray-300" activeClassName="bg-gray-800" to="/changelogs">
                        Changelogs
                    </NavLink>
                </>}
                {role === 'admin' && <>
                    <div className="font-semibold text-lg p-3 mt-5">
                        Vertrieb
                    </div>
                    <NavLink className="flex block p-3 pl-8 text-gray-300" activeClassName="bg-gray-800" to="/sales">
                        Vertrieb
                    </NavLink>
                    <NavLink className="flex block p-3 pl-8 text-gray-300" activeClassName="bg-gray-800" to="/sales-city">
                        Städte
                    </NavLink>
                </>}
                {role === 'admin' && <>
                    <div className="font-semibold text-lg p-3 mt-5">
                        Scraper
                    </div>
                    <NavLink className="flex block p-3 pl-8 text-gray-300" activeClassName="bg-gray-800" to="/scraper">
                        Scraper
                    </NavLink>
                </>}
                {role === 'admin' && <>
                    <div className="font-semibold text-lg p-3 mt-5">
                        Statistiken
                    </div>
                    <NavLink className="flex block p-3 pl-8 text-gray-300" activeClassName="bg-gray-800" to="/performance" onClick={(_) => setOpen(false)}>
                        General
                    </NavLink>
                    <NavLink className="flex block p-3 pl-8 text-gray-300" activeClassName="bg-gray-800" to="/statistics/events" onClick={(_) => setOpen(false)}>
                        Events
                    </NavLink>
                    <NavLink className="flex block p-3 pl-8 text-gray-300" activeClassName="bg-gray-800" to="/statistics/users" onClick={(_) => setOpen(false)}>
                        Users
                    </NavLink>
                </>}
            </div>
            <button className="flex block p-3 mt-5 bg-red-500 justify-center mt-auto text-lg font-semibold" onClick={(_) => auth.signOut()}>
                Logout
            </button>
        </div>
        {itemTitle && <div className="w-full md:w-64 bg-gray-800 h-full flex-col flex">
            {newItem && <button className="flex block p-3 font-semibold bg-green-500 justify-center items-center" onClick={() => newItem() && setOpen(false)}>
                <div>{newItemText??'Neu'}</div>
                <div className="ml-2">+</div>
            </button>}
            {orders && <div className="flex block p-3 bg-gray-600">
                Sortieren:
                <select className="bg-gray-600 ml-2 w-full" value={queryParams.order??defaultOrder} onChange={(e) => history.replace({pathname: location.pathname, search: '?' + new URLSearchParams({...queryParams, order: e.target.value}).toString()})}>
                    {orders.map(e => <option value={e.id}>{e.label}</option>)}
                </select>
            </div>}
            {filters?.filter(e => queryParams[e.id])?.map(e => <div className="flex block p-3 bg-gray-600">
                <div className="text-ellipsis whitespace-nowrap overflow-hidden">{e.label}: {queryParams[e.id]}</div>
                <div className="ml-3 font-semibold cursor-pointer" onClick={_ => history.replace({pathname: location.pathname, search: '?' + new URLSearchParams({...queryParams, [e.id]: ''})})}>X</div>
            </div>)}
            {recordType && <input className="flex block pl-3 py-3 text-black" type="text" placeholder="Suche" onChange={(e) => setSearch(e.target.value)}/>}
            <div className="flex-1 overflow-auto divide-y divide-gray-700" onScroll={scroll}>
                {sortItems(items).map((item) => <NavLink className="block p-3" activeClassName="bg-gray-600" to={itemLink(item) + location.search} onClick={(_) => setOpen(false)}>
                    <div className="text-sm font-semibold">{(itemTitle??((_)=>''))(item)}</div>
                    <div className="text-xs text-gray-300">{(itemSubtitle??((_)=>''))(item)}</div>
                </NavLink>)}
            </div>
        </div>}
    </div> : <div className="h-full bg-black text-white" id="sidebar_closed">
        <div className="m-2 p-2 font-semibold" onClick={(_) => {setOpen(true);handleMenuClick()}}>
            ☰
        </div>
    </div>;
}