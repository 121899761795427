import {
    Switch,
    Route,
    useRouteMatch,
  } from "react-router-dom";
import Suggestion from "./Suggestion";
import Sidebar from "./Sidebar";

export default function Suggestions() {
    let match = useRouteMatch();
    
    return <div className="h-full flex w-auto">
        <Sidebar collection="suggestions" itemTitle={(item) => item.objectID.split('-')[1]} itemLink={(item) => `/suggestions/${item.objectID.split('-')[1]}`} />
        <div className="flex-grow overflow-auto">
            <Switch>
                <Route path={`${match.path}/:id`} component={Suggestion}/>
                <Route path={match.path}>
                    <p className="p-3">
                        Bitte selektiere eine Meldung.
                    </p>
                </Route>
            </Switch>
        </div>
    </div>;
}