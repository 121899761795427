import {
    Switch,
    Route,
    useHistory,
    useRouteMatch,
  } from "react-router-dom";
import Article from "./Article";
import { firestore } from "./firebase";
import Fusion from "./Fusion";
import Sidebar from "./Sidebar";

export default function Articles() {
    const history = useHistory();

    const newArticle = () => {
        const reference = firestore.collection('articles').doc();
        history.push(`/articles/${reference.id}`);
    };

    let match = useRouteMatch();
    
    return <div className="h-full flex w-auto">
        <Sidebar newItem={newArticle} newItemText="Neuer Artikel" collection="articles" itemTitle={(item) => item.title} itemSubtitle={(item) => new Date((item.created_timestamp?._seconds??item.created_timestamp?.seconds??0) * 1000).toLocaleString('de-DE')} itemLink={(item) => `/articles/${item.objectID.split('-')[1]}`} orders={[
            {
                id: 'new',
                label: 'Neue Artikel',
                field: 'created_timestamp',
                direction: 'desc',
            },
        ]} defaultOrder="new" />
        <div className="flex-grow overflow-auto">
            <Switch>
                <Route path={`${match.path}/:id`} component={Article} />
                <Route path={match.path}>
                    <p className="p-3">
                        Bitte selektiere ein Artikel.
                    </p>
                </Route>
            </Switch>
        </div>
    </div>;
}