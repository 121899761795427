import {
    Switch,
    Route,
    useRouteMatch,
  } from "react-router-dom";
import User from "./User";
import Sidebar from "./Sidebar";

export default function Users() {
    let match = useRouteMatch();
    
    return <div className="h-full flex w-auto">
        <Sidebar recordType="user" itemTitle={(item) => item.username??item.name} itemSubtitle={(item) => item.role} itemLink={(item) => `/users/${item.objectID.split('-')[1]}`} />
        <div className="flex-grow overflow-auto">
            <Switch>
                <Route path={`${match.path}/:id`} component={User}/>
                <Route path={match.path}>
                    <p className="p-3">
                        Bitte selektiere einen User.
                    </p>
                </Route>
            </Switch>
        </div>
    </div>;
}