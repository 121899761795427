import { useEffect, useState } from "react";
import {
    useHistory,
    useParams,
    useLocation,
} from "react-router-dom";
import { firestore } from "./firebase";
import queryString from "query-string";

export default function Scraper() {
    const history = useHistory();
    const location = useLocation();

    const { id } = useParams();

    const [queryParams, setQueryParams] = useState({});
    useEffect(() => setQueryParams(queryString.parse(location.search)), [location.search]);

    const [entries, setEntries] = useState([]);
    const [limit, setLimit] = useState(30);
    const [showLimit, setShowLimit] = useState(30);
    useEffect(() => firestore.collection('scraper').doc(id).collection('entries').orderBy(queryParams.orderField??'name', queryParams.orderDirection??'asc').limit(limit).onSnapshot(e => setEntries(e.docs)), [id, limit, queryParams]);

    const [search, setSearch] = useState('');
    useEffect(() => entries.length > limit - 50 && entries.filter(e => Object.entries({...e.data(), log: null}).some(e => e.toString().toLowerCase().includes(search.toLocaleLowerCase()))).length < showLimit && setLimit(limit + 250), [entries, search, showLimit, limit]);
    useEffect(() => console.log(entries.length > limit - 50 && entries.filter(e => Object.entries({...e.data(), log: null}).some(e => e.toString().toLowerCase().includes(search.toLocaleLowerCase()))).length < showLimit), [entries, search, showLimit, limit]);

    const [addRowOpen, setAddRowOpen] = useState(false);
    const [newEntry, setNewEntry] = useState({});
    
    const scroll = (e) => {
        if(e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 30 && entries.filter(e => Object.entries({...e.data(), log: null}).some(e => e.toString().toLowerCase().includes(search.toLocaleLowerCase()))).length === limit)
            setShowLimit(limit + 10);
    }

    const add = async () => {
        if(!newEntry.url) return;
        await firestore.collection('scraper').doc(id).collection('entries').add(newEntry);
        setNewEntry({});
        setAddRowOpen(false);
    };

    return <div className="h-screen flex flex-col overflow-hidden">
        <div className="bg-gray-500 flex flex-col md:flex-row gap-2 p-4 md:py-0 md:px-2">
            <button className="bg-blue-500 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold text-white" onClick={() => setAddRowOpen(true)}>Neuer Eintrag</button>
            <div className="bg-gray-500 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold hidden md:block text-gray-500">a</div>
        </div>
        {
            id ? <div className="p-3 overflow-y-scroll" onScroll={scroll}>
                {addRowOpen && <div className="grid grid-cols-12 p-1">
                    <div className="col-span-12">
                        Eintrag hinzufügen:
                    </div>
                    <CreateField className="col-span-3" name="Name" value={newEntry.name} onChange={(val) => setNewEntry({...newEntry, name: val})} />
                    <CreateField className="col-span-4" name="URL" value={newEntry.url} onChange={(val) => setNewEntry({...newEntry, url: val})} />
                    <CreateField className="col-span-3" name="Tags (mit Komma trennen)" array value={newEntry.tags} onChange={(val) => setNewEntry({...newEntry, tags: val})} />
                    <button className="bg-blue-500 rounded text-white ml-1 p-1" onClick={add}>
                        Hinzufügen
                    </button>
                    <button className="bg-gray-500 rounded text-white ml-1 p-1" onClick={() => setAddRowOpen(false)}>
                        Schließen
                    </button>
                </div>}
                <div className="flex items-center">
                    <div className="mr-3">Suchen: </div>
                    <input className="w-full border rounded p-1" placeholder="Hier suchen" value={search} onChange={e => setSearch(e.target.value)} />
                </div>
                <div className="grid grid-cols-12">
                    <div className="p-1 col-span-3">
                        <button className="font-semibold" onClick={(_) => history.replace({pathname: location.pathname, search: '?orderField=name&orderDirection=' + ((queryParams.orderField === 'name' && queryParams.orderDirection === 'asc') || !queryParams.orderField ? 'desc' : 'asc')})}>
                            Name
                            &nbsp;
                            {queryParams.orderField === 'name' || !queryParams.orderField ?  queryParams.orderDirection === 'desc' ? <>&uarr;</> : <>&darr;</> : null}
                        </button>
                    </div>
                    <div className="p-1 col-span-4">
                        <button className="font-semibold" onClick={(_) => history.replace({pathname: location.pathname, search: '?orderField=url&orderDirection=' + (queryParams.orderField === 'url' && queryParams.orderDirection === 'asc' ? 'desc' : 'asc')})}>
                            URL
                            &nbsp;
                            {queryParams.orderField === 'url' ?  queryParams.orderDirection === 'desc' ? <>&uarr;</> : <>&darr;</> : null}
                        </button>
                    </div>
                    <div className="p-1">
                        <button className="font-semibold" onClick={(_) => history.replace({pathname: location.pathname, search: '?orderField=tags&orderDirection=' + (queryParams.orderField === 'tags' && queryParams.orderDirection === 'asc' ? 'desc' : 'asc')})}>
                            Tags
                            &nbsp;
                            {queryParams.orderField === 'tags' ?  queryParams.orderDirection === 'desc' ? <>&uarr;</> : <>&darr;</> : null}
                        </button>
                    </div>
                    <div className="p-1">
                        <button className="font-semibold" onClick={(_) => history.replace({pathname: location.pathname, search: '?orderField=last_scraped&orderDirection=' + (queryParams.orderField === 'last_scraped' && queryParams.orderDirection === 'desc' ? 'asc' : 'desc')})}>
                            Zuletzt gescraped
                            &nbsp;
                            {queryParams.orderField === 'last_scraped' ?  queryParams.orderDirection === 'asc' ? <>&uarr;</> : <>&darr;</> : null}
                        </button>
                    </div>
                    <div className="p-1">
                        <button className="font-semibold" onClick={(_) => history.replace({pathname: location.pathname, search: '?orderField=events_successful&orderDirection=' + (queryParams.orderField === 'events_successful' && queryParams.orderDirection === 'asc' ? 'desc' : 'asc')})}>
                            Events gescraped
                            &nbsp;
                            {queryParams.orderField === 'events_successful' ?  queryParams.orderDirection === 'desc' ? <>&uarr;</> : <>&darr;</> : null}
                        </button>
                    </div>
                    <div className="font-semibold p-1">Log anzeigen</div>
                    <div className="font-semibold p-1 text-center">Löschen</div>
                    {entries?.filter(e => Object.entries({...e.data(), log: null}).some(e => e.toString().toLowerCase().includes(search.toLocaleLowerCase())))?.map(entry => <>
                        <EditField className="overflow-hidden p-1 border-t border-b col-span-3" entry={entry} field="name" />
                        <EditField className="overflow-hidden p-1 border-t border-b col-span-4" entry={entry} field="url" />
                        <EditField className="overflow-hidden p-1 border-t border-b" entry={entry} field="tags" array />
                        <div className="overflow-hidden p-1 border-t border-b">{entry.data().last_scraped?.toDate()?.toLocaleString('de-DE')??'N/A'}</div>
                        <div className="overflow-hidden p-1 border-t border-b text-center">{entry.data().events_successful??'?'}/{entry.data().events_total??'?'}</div>
                        <div className="overflow-hidden p-1 border-t border-b">
                            <button className="underline" onClick={(_) => history.push(`${id}/log/${entry.id}`)}>
                                Log anzeigen
                            </button>
                        </div>
                        <div className="overflow-hidden p-1 border-t border-b text-center">
                            <button className="underline" onClick={(_) => window.confirm(`Bist du dir sicher, dass du "${entry.data().name??entry.data().url}" löschen willst?`) ? entry.ref.delete() : null}>
                                X
                            </button>
                        </div>
                    </>)}
                </div>
            </div> : <div className="p-3">
                Bitte warten...
            </div>
        }
    </div>;
}

function EditField({ entry, field, array, ...rest }) {
    const [editMode, setEditMode] = useState(false);

    const confirmChanges = async value => {
        await entry.ref.update({
            [field]: array ? value.split(',').map(e => e.trim()) : value,
        });
        setEditMode(false);
    };

    return <div {...rest}>
        {
            editMode
            ? <input className="w-full h-full" autoFocus defaultValue={entry.data()[field]} onKeyDown={e => e.key === 'Enter' ? confirmChanges(e.target.value) : null} onBlur={e => confirmChanges(e.target.value)} />
            : <div className="w-full h-full" onClick={() => setEditMode(true)}>
                {array ? entry.data()[field]?.join(', ') : entry.data()[field]}
            </div>
        }
    </div>;
}

function CreateField({ name, value, onChange, array, ...rest }) {

    return <div {...rest}>
        <input className="w-full h-full border" placeholder={name} value={value} onChange={e => onChange(array ? e.target.value.split(',').map(e => e.trim()) : e.target.value)} />
    </div>;
}