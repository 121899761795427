import {
    Switch,
    Route,
    useHistory,
    useRouteMatch,
  } from "react-router-dom";
import Artist from "./Artist";
import { firestore } from "./firebase";
import Sidebar from "./Sidebar";
import Fusion from "./Fusion";
import EditSuggestion from "./EditSuggestion";

export default function Artists() {
    const history = useHistory();

    const newArtist = async () => {
        const name = prompt('Bitte Künstlernamen eingeben.');
        if(!name) return;
        const reference = await firestore.collection('artists').add({ name });
        let first = true;
        await new Promise(r => reference.onSnapshot({ next: () => first ? first = false : r() }));
        history.push(`/artists/${reference.id}`);
    };

    let match = useRouteMatch();
    
    return <div className="h-full flex w-auto">
        <Sidebar newItem={newArtist} newItemText="Neuer Artist" collection="artists" recordType="artist" itemTitle={(item) => item.name} itemLink={(item) => `/artists/${item.objectID.split('-')[1]}`} />
        <div className="flex-grow overflow-auto">
            <Switch>
                <Route path={`${match.path}/:id1/edit-suggestion/:id2`}><EditSuggestion collection="artists" recordType="artist" /></Route>
                <Route path={`${match.path}/:id1/fusion/:id2`}><Fusion collection="artists" recordType="artist" /></Route>
                <Route path={`${match.path}/:id1/fusion`}><Fusion collection="artists" recordType="artist" /></Route>
                <Route path={`${match.path}/:id`} component={Artist}/>
                <Route path={match.path}>
                    <p className="p-3">
                        Bitte selektiere einen Artist.
                    </p>
                </Route>
            </Switch>
        </div>
    </div>;
}