import { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import {
    useHistory,
    useLocation,
} from "react-router-dom";
import { firestore, storage } from "./firebase";
import Sidebar from "./Sidebar";
import { toast } from "react-toastify";
import { CategoriesContext } from "./providers/CategoriesProvider";


export default function Updates() {
    const [cookies, setCookie] = useCookies(['viewed_updates']);
    const [viewedUpdates, setViewedUpdates] = useState([]);

    const [events, setEvents] = useState([]);
    const [organizers, setOrganizers] = useState([]);

    useEffect(() => setViewedUpdates(cookies.viewed_updates??[]), []);
    useEffect(() => setCookie('viewed_updates', (Array.from(new Set([...((cookies.viewed_updates??'').split(',')), ...events.map(doc => doc.id), ...organizers.map(doc => doc.id)]))).join(','), { path: '/' }), [events, organizers]);

    const { categories, cities } = useContext(CategoriesContext);
    const [selectedCategory, setSelectedCategory] = useState('all');
    const [selectedCity, setSelectedCity] = useState('all');

    useEffect(() => {
        let query = firestore.collection('events');
        if(selectedCategory !== 'all') query = query.where('type', 'array-contains', selectedCategory);
        if(selectedCity !== 'all') query = query.where('city', '==', selectedCity);
        query = query.orderBy('created_timestamp', 'desc').limit(100);
        query.onSnapshot(snapshot => setEvents(snapshot.docs));
    }, [selectedCategory, selectedCity]);
    useEffect(() => {
        let query = firestore.collection('organizers');
        if(selectedCategory !== 'all') query = query.where('types', 'array-contains', selectedCategory);
        if(selectedCity !== 'all') query = query.where('city', '==', selectedCity);
        query = query.orderBy('created_timestamp', 'desc').limit(100);
        query.onSnapshot(snapshot => setOrganizers(snapshot.docs));
    }, [selectedCategory, selectedCity]);

    return <div className="h-full flex w-auto">
        <Sidebar defaultOpen={false} />
        <div className="flex-grow overflow-auto">
            <div className="h-screen flex flex-col overflow-hidden">
                <div className="bg-gray-600 flex items-center text-white gap-2 p-3 justify-between">
                    <div className="text-xl font-semibold">Neue Events</div>
                    <div className="flex gap-2">
                        <div className="flex p-2 bg-gray-300 text-black rounded-md">
                            Typ:
                            <select className="bg-gray-300 font-semibold ml-2 w-full" value={selectedCategory} onChange={e => setSelectedCategory(e.target.value)}>
                                <option value="all">Alle</option>
                                {categories?.map(doc => <option key={doc.id} value={doc.id}>{doc.data().de}</option>)}
                            </select>
                        </div>
                        {/* <div className="flex p-2 bg-gray-300 text-black rounded-md">
                            Zeitspanne:
                            <select className="bg-gray-300 font-semibold ml-2 w-full">
                                <option>Letzte 24h</option>
                                <option>Letzte Woche</option>
                                <option>Letzten Monat</option>
                            </select>
                        </div> */}
                        <div className="flex p-2 bg-gray-300 text-black rounded-md">
                            Stadt:
                            <select className="bg-gray-300 font-semibold ml-2 w-full" value={selectedCity} onChange={e => setSelectedCity(e.target.value)}>
                                <option value="all">Alle</option>
                                {cities?.map(doc => <option key={doc.id} value={doc.id}>{doc.data().name}</option>)}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="p-3 overflow-y-scroll">
                    {[...events, ...organizers].map(entity => <UpdateEntity key={entity.id} object={entity} unseen={!viewedUpdates.includes(entity.id)} />)}
                </div>
            </div>
        </div>
    </div>;
}

function UpdateEntity({ object, unseen }) {
    const history = useHistory();
    const location = useLocation();

    const collection = object.ref.parent.id;

    // const remove = async () => {
    //     if(!window.confirm('Bist du dir sicher, dass du diesen Eintrag (' + object.data().name + ') löschen willst?')) return;
    //     await firestore.collection(collection).doc(object.id).delete();
    //     toast.success("Löschen erfolgreich", {theme: "dark"})
    // };

    const block = async () => {
        if(!window.confirm('Bist du dir sicher, dass du diesen Eintrag (' + object.data().name + ') blockieren willst?')) return;
        await firestore.collection(collection).doc(object.id).update({
            visibility: 'blocked',
            pending_review: false,
        });
        toast.success("Blockieren erfolgreich", {theme: "dark"})
    };

    const verify = async () => {
        if(!window.confirm('Bist du dir sicher, dass du diesen Eintrag (' + object.data().name + ') freigeben willst?')) return;
        await firestore.collection(collection).doc(object.id).update({
            visibility: 'public',
            pending_review: false,
        });
        toast.success("Freigabe erfolgreich", {theme: "dark"})
    };

    return <div className="mb-3 flex">
        <ReferenceImage reference={object.data().images?.length > 0 && object.data().images[0]} />
        <div className="p-3 bg-gray-300 flex flex-1" style={{borderRadius: "0px 5px 5px 0px"}}>
            <div className="flex-1 flex flex-col justify-between">
                <div>
                    <div className="text-xl font-semibold">{object.data().name}</div>
                    <div className="">Location, {object.data().city}</div>
                    <div className="">{object.data().start?.toDate()?.toString()}</div>
                </div>
                <div className="">
                    <div className="text-sm">Quelle: {object.data().agent === 'scraper' ? 'Scraper (' + getScrapers(object) + ')' : object.data().agent === 'event-form' ? 'Event Formular' : object.data().agent}</div>
                    <div className="text-sm mb-1">Erstellt am: {object.data().created_timestamp?.toDate()?.toString()}</div>
                    <div className="flex gap-3">
                        <button className="bg-white p-2 rounded-sm" onClick={() => history.push(`/${collection}/${object.id}?return=${encodeURIComponent(location.pathname + location.search)}`)}>Anzeigen</button>
                        <button className="bg-red-400 p-2 rounded-sm" onClick={block}>Blockieren</button>
                        {object.data().pending_review && <button className="bg-blue-400 p-2 rounded-sm" onClick={verify}>Freigeben</button>}
                    </div>
                </div>
            </div>
            {unseen && <div className="bg-blue-400 p-2 self-start rounded">NEU</div>}
        </div>
    </div>;
}

function ReferenceImage({reference}) {
    const [url, setUrl] = useState('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');

    useEffect(() => reference ? storage.ref(reference).getDownloadURL().then(url => setUrl(url)).catch(_ => setUrl('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==')) : null, [reference]);

    return <img className="w-72 h-56 object-cover" style={{borderRadius: "5px 0px 0px 5px"}} src={url} alt="" />;
}

function getScrapers(object) {
    if(!object?.data()?.external_identifiers || object.data().external_identifiers.length === 0) return 'unknown';

    return Object.keys(object.data().external_identifiers).join(', ');
}