import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import Field from "./Field";
import { firestore, functions, storage } from "./firebase";
import { index } from "./algolia";
import { NavLink, useHistory } from "react-router-dom";
import queryString from "query-string";
import { toast } from "react-toastify";


export default function Fusion({ collection, recordType }) {
    const ignoreKeys = ['identifier', 'identifiers', 'start', 'end', '_timestamp', 'location', 'agent', 'similar', 'external_interested_counts', 'external_identifers', '_count', '_by', '_data', 'base'];
    const highPriorityKeys = ['name', 'address', 'images'];
    const lowPriorityKeys = ['description'];

    const history = useHistory();
    const location = useLocation();

    const [queryParams, setQueryParams] = useState({});
    useEffect(() => setQueryParams(queryString.parse(location.search)), [location.search]);

    const { id1, id2 } = useParams();

    const [data1, setData1] = useState(null);
    const [data2, setData2] = useState(null);

    useEffect(() => firestore.collection(collection).doc(id1).get().then(document => setData1(Object.keys(document.data()).reduce((map, key) => {
        map[key] = typeof document.data()[key]?.toDate === 'function' ? document.data()[key].toDate() : document.data()[key];
        return map;
    }, {}))), [id1]);
    
    useEffect(() => firestore.collection(collection).doc(id2).get().then(document => setData2(Object.keys(document.data()??{}).reduce((map, key) => {
        map[key] = typeof document.data()[key]?.toDate === 'function' ? document.data()[key].toDate() : document.data()[key];
        return map;
    }, {}))), [id2]);

    const [search, setSearch] = useState('');
    const [hits, setHits] = useState([]);

    useEffect(() => index.search(search, { filters: `recordType:"${recordType}"` }).then(({ hits }) => setHits(hits)), [search]);

    const [keys, setKeys] = useState([]);
    useEffect(() => {
        if(!data1 || !data2) return;
        const temp = [...new Set(Object.keys(data1).concat(Object.keys(data2)))].filter(e => !ignoreKeys.some(x => e.startsWith(x) || e.endsWith(x)));
        temp.sort((a, b) => {
            if(highPriorityKeys.includes(a) && !highPriorityKeys.includes(b)) return -1;
            if(highPriorityKeys.includes(b) && !highPriorityKeys.includes(a)) return 1;
            if(highPriorityKeys.includes(a) && highPriorityKeys.includes(b)) return highPriorityKeys.indexOf(a) - highPriorityKeys.indexOf(b);
            if(lowPriorityKeys.includes(a) && !lowPriorityKeys.includes(b)) return 1;
            if(lowPriorityKeys.includes(b) && !lowPriorityKeys.includes(a)) return -1;
            if(lowPriorityKeys.includes(a) && lowPriorityKeys.includes(b)) return lowPriorityKeys.indexOf(b) - lowPriorityKeys.indexOf(a);
            return 0;
        });
        setKeys(temp);
    }, [data1, data2]);

    const [data, setData] = useState({});
    useEffect(() => (keys?.length??0) > 0 && data1 && data2 ? setData(keys.reduce((map, key) => {
        if(Array.isArray(data1[key]) && Array.isArray(data2[key]))
            map[key] = [...new Set([...data1[key], ...data2[key]])];
        else if(data1[key])
            map[key] = data1[key];
        else if(data2[key])
            map[key] = data2[key];
        return map;
    }, {})) : null, [data1, data2, keys]);

    const back = () => {
        if(!queryParams.return) return false;
        history.push(queryParams.return);
        return true;
    }

    const confirm = async () => {
        const fusion = functions.httpsCallable('service-fusion');
        const promise = fusion({
            collection: collection,
            master: id1,
            slave: id2,
            data: data,
        }).catch(e => alert(`Es ist bei der Vereinigung ein Fehler aufgetreten: ${e.message}`));
        if(!back()) {
            await promise;
            toast.success("Vereingung erfolgreich", {theme: "dark"})
            history.push(`/${collection}/${id1}`);
        }
    };

    const cancel = async () => {
        if(!back())
            history.push(`/${collection}/${id1}`);
    };

    const isEmpty = (e) => {
        if(Array.isArray(e))
            return e.length === 0;
        return !e;
    }

    const areEqual = (a, b) => {
        if(Array.isArray(a) && Array.isArray(b))
            return a.every((e) => b.some((f) => areEqual(e, f))) && b.every((e) => a.some((f) => areEqual(e, f)));
        return a === b;
    };

    return <div>
        <div className="bg-gray-500 flex flex-col md:flex-row gap-2 p-4 md:py-0 md:px-2">
            {id2 && <button className="bg-blue-500 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold text-white" onClick={confirm}>Bestätigen</button>}
            <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={cancel}>Abbruch</button>
            <div className="bg-gray-500 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold hidden md:block text-gray-500">a</div>
        </div>
        {
            id1 && id2 && data1 && data2 ? <div className="p-3">
                <div className="grid grid-cols-3">
                    <div className="font-semibold p-1 border-t border-b">{id1}</div>
                    <div className="font-semibold py-1 px-5 flex justify-between w-100 border-t border-b">
                        <div><input type="radio" disabled checked /></div>
                        <div>ID</div>
                        <div><input type="radio" disabled /></div>
                    </div>
                    <div className="font-semibold p-1 border-t border-b">{id2}</div>
                    {(data1['start'] || data2['start']) && <>
                        <div className="font-semibold p-1  border-t border-b">{data1['start']?.toLocaleString('de-DE')}</div>
                        <div className="font-semibold py-1 px-5 flex justify-between w-100 border-t border-b">
                            <div><input type="radio" disabled checked /></div>
                            <div>start</div>
                            <div><input type="radio" disabled /></div>
                        </div>
                        <div className="font-semibold p-1 border-t border-b">{data2['start']?.toLocaleString('de-DE')}</div>
                    </>}
                    {keys?.map(key => <>
                        {
                            key === 'images'
                                ? <div className="font-semibold p-1 border-t border-b">{(data1[key]??[]).map((e) => <ReferenceImage reference={e} />)}</div>
                                : <div className="overflow-hidden p-1 border-t border-b">{data1[key]?.toLocaleString('de-DE')??data1[key]?.toString()}</div>
                        }
                        <div className="py-1 px-5 w-100 border-t border-b">
                            <div className="flex justify-between">
                                <div><input type="radio" name={key} disabled={isEmpty(data1[key])} checked={areEqual(data[key], data1[key])} onChange={(e) => e.target.checked && setData({...data, [key]: data1[key]})} /></div>
                                <div className="font-semibold">{key}</div>
                                <div><input type="radio" name={key} disabled={isEmpty(data1[key]) || areEqual(data1[key], data2[key])} checked={areEqual(data[key], data2[key]) && !areEqual(data1[key], data2[key])} onChange={(e) => e.target.checked && setData({...data, [key]: data2[key]})} /></div>
                            </div>
                            {Array.isArray(data1[key]) && Array.isArray(data2[key]) && <div className="mt-3 flex justify-center">
                                <div><input type="radio" name={key} disabled={isEmpty(data1[key]) || isEmpty(data2[key]) || areEqual(data1[key], data2[key])} checked={areEqual(data[key], [...(data1[key]??[]), ...(data2[key]??[])]) && !(isEmpty(data1[key]) || isEmpty(data2[key]) || areEqual(data1[key], data2[key]))} onChange={(e) => e.target.checked && setData({...data, [key]: [...new Set([...data1[key], ...data2[key]])]})} /></div>
                                <div className="mx-3">Kombinieren</div>
                                <div className="invisible"><input type="radio"/></div>
                            </div>}
                        </div>
                        {
                            key === 'images'
                                ? <div className="font-semibold p-1 border-t border-b">{(data2[key]??[]).map((e) => <ReferenceImage reference={e} />)}</div>
                                : <div className="overflow-hidden p-1 border-t border-b">{data2[key]?.toLocaleString('de-DE')??data1[key]?.toString()}</div>
                        }
                    </>)}
                </div>
            </div> : id1 && !id2 && data1 ? <div className="p-3">
                <Field name="Suche" onChange={(val) => setSearch(val)} />
                <div className="font-semibold">Bitte Element zum vereinigen auswählen:</div>
                {hits.filter((e) => e.objectID.split('-')[1] !== id1).map((e) => <div><NavLink to={`fusion/${e.objectID.split('-')[1]}`}>{e.name + ' (' + (e.start ? new Date((e.start._seconds??0) * 1000).toLocaleString('de-DE') : e.city??'Stadt unbekannt') + ')'}</NavLink></div>)}
            </div> : <div className="p-3">
                Bitte warten...
            </div>
        }
    </div>;
}

function ReferenceImage({reference}) {
    const [url, setUrl] = useState('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');

    useEffect(() => reference ? storage.ref(reference).getDownloadURL().then(url => setUrl(url)).catch(_ => setUrl('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==')) : null, [reference]);

    return <img className="h-48 w-auto" src={url} alt="" />;
}