import {
    useRouteMatch,
    NavLink,
    useHistory,
    useLocation,
    Link,
} from "react-router-dom";
import Sidebar from "./Sidebar";
import Section from "./Section";
import { useEffect,  useState } from "react";
import { firestore, storage } from "./firebase";
import Field from "./Field";
import { toast } from "react-toastify";
import { SortableContext, sortableKeyboardCoordinates, useSortable } from "@dnd-kit/sortable";
import { DndContext, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";

export default function AutoPosts() {
    const history = useHistory();

    const match = useRouteMatch('/auto-posts/:id');
    const id = match?.params.id;

    //Post list

    const [posts, setPosts] = useState(null);
    const loadPosts = () => {
        firestore.collection('social_media_posts')
        .where('status', 'in', ['scheduled', 'awaiting_approval'])
        .where('admin', '==', true).get().then(snap => setPosts(snap.docs));
    }
    useEffect(loadPosts, []);

    //Current post
    const [currentPost, setCurrentPost] = useState(null);
    useEffect(() => { id && id !== 'new' ? firestore.collection('social_media_posts').doc(id).onSnapshot(snap => setCurrentPost(snap)) : setCurrentPost(null) }, [id]);

    //Post settings

    const [data, setData] = useState(null);
    useEffect(() => { setData(id === 'new' ? {} : posts?.find(post => post.id === id)?.data()) }, [id, posts]);

    const save = async () => {
        const newData = {
            ...data,
            admin: true,
            range_start: data.type.startsWith('top_weekend') ? new Date(data.post_at.toDate().getTime() + (4 - data.post_at.toDate().getDay()) * 24 * 60 * 60 * 1000 + 24 * 60 * 60 * 1000)
                : data.type === 'top_newly_announced' ? new Date(data.post_at.toDate().getTime() - 7 * 24 * 60 * 60 * 1000)
                : null,
            video: null,
            status: data.status ?? 'awaiting_approval',
        }
        try {
            if (currentPost) {
                await currentPost.ref.update(newData);
                loadPosts();
                toast.success('Änderungen gespeichert');
            } else {
                const ref = await firestore.collection('social_media_posts').add(newData);
                loadPosts();
                toast.success('Post erstellt');
                history.push('/auto-posts/' + ref.id);
            }
        } catch (e) {
            toast.error(e.message);
        }
    }

    //Event ranking

    const [events, setEvents] = useState(null);
    const loadEvents = () => {
        currentPost?.data()?.events ? Promise.all(currentPost.data().events.map(e => firestore.collection('events').doc(e).get())).then(e => setEvents(e.filter(e => e.exists).reverse())) : setEvents(null);
    }
    useEffect(loadEvents, [currentPost]);
    const saveEvents = async () => {
        try {
            await currentPost.ref.update({
                events: events.map(e => e.id).reverse(),
                status: 'scheduled',
                video: null,
            });
            loadPosts();
            toast.success('Ranking gespeichert');
        } catch (e) {
            toast.error(e.message);
        }
    }
    const resetEvents = async () => {
        setEvents(null);
        try {
            await currentPost.ref.update({
                events: null,
                status: 'awaiting_approval',
                video: null,
            });
            loadPosts();
            toast.success('Ranking freigegeben');
        } catch (e) {
            toast.error(e.message);
        }
    }

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates }),
    );
    const handleDragEnd = (event) => {
        const {active, over} = event;
        if (active.id !== over.id) {
            const newEvents = [...events];
            const activeIndex = events.findIndex(e => e.id === active.id);
            const overIndex = events.findIndex(e => e.id === over.id);
            newEvents.splice(activeIndex, 1);
            newEvents.splice(overIndex, 0, events[activeIndex]);
            setEvents(newEvents);
        }
    };
    
    return <div className="h-full flex w-auto">
        <Sidebar />
        <div className="flex-grow overflow-auto">
            <div className="p-3">
                {posts ? <div className="flex flex-wrap gap-3">
                    {posts.map(post => <NavLink to={'/auto-posts/' + post.id} className="border rounded p-3 w-full md:w-auto text-gray-400 hover:text-black cursor-pointer" activeClassName="border-blue-400">
                        <div>{post.data().type}{post.data().type === 'top_weekend_genre' && ` (${post.data().genre})`}</div>
                        <div>{post.data().city}</div>
                        <div className="text-xs">{post.data().post_at?.toDate().toLocaleString('de-DE')}</div>
                        <div className={`text-xs ${post.data().status === 'scheduled' ? 'text-green-400' : post.data().status === 'awaiting_approval' ? 'text-red-400' : ''}`}>{post.data().status}</div>
                    </NavLink>)}
                    <NavLink to="/auto-posts/new" className="border rounded p-3 w-full md:w-auto text-gray-400 hover:text-black cursor-pointer" activeClassName="border-blue-400">
                        Neuen Auto-Post erstellen
                    </NavLink>
                </div> : <div>Bitte warten...</div>}
            </div>
            <div className="p-3">
                {data ? <>
                    {currentPost?.data() && <Section title="Vorschau">
                        <div>Es kann nach Änderungen ein paar Minuten dauern bis sich das Video aktualisiert.</div>
                        <ReferenceVideo reference={currentPost.data().video} />
                    </Section>}
                    <Section title="Einstellungen" openDefault={id === 'new'}>
                        <Field name="Posttyp" value={data?.type} type="dropdown" options={['', 'top_weekend', 'top_weekend_genre', 'top_newly_announced']} onChange={(val) => setData({...data, ...{type: val}})} />
                        {data?.type === 'top_weekend_genre' && <Field name="Genre" value={data?.genre} type="genre" onChange={(val) => setData({...data, ...{genre: val}})} />}
                        <Field name="Postzeitpunkt" value={data?.post_at} type="timestamp" onChange={(val) => setData({...data, ...{post_at: val}})} />
                        <Field name="Wiederholung" value={data?.repeat} type="dropdown" options={['', 'daily', 'weekly', 'monthly']} onChange={(val) => setData({...data, ...{repeat: val}})} />
                        <Field name="Stadt" value={data?.city} type="city" onChange={(val) => setData({...data, ...{city: val}})} />
                        <Field name="Hintergrundvideo" value={data?.background_video} type="video" fileDirectory="temp" onChange={(val) => setData({...data, ...{background_video: val}})} />
                        <Field name="Caption" value={data?.caption} type="multiline" onChange={(val) => setData({...data, ...{caption: val}})} />
                        <Field name="Instagram Story" value={data?.instagram_story} type="checkbox" onChange={(val) => setData({...data, ...{instagram_story: val}})} />
                        <Field name="Instagram Post" value={data?.instagram_post} type="checkbox" onChange={(val) => setData({...data, ...{instagram_post: val}})} />
                        <Field name="TikTok Post" value={data?.tiktok_post} type="checkbox" onChange={(val) => setData({...data, ...{tiktok_post: val}})} />
                        {currentPost?.data() && data && Object.keys(data).some(e => data[e]?.toString() !== currentPost.data()[e]?.toString()) && <button onClick={save} className="bg-blue-500 text-white rounded px-2 py-3 font-semibold">Änderungen speichern</button>}
                        {!currentPost && data.type && data.post_at && data.repeat && <button onClick={save} className="bg-blue-500 text-white rounded px-2 py-3 font-semibold">Erstellen</button>}
                    </Section>
                    {currentPost?.data() && <Section title="Events" openDefault={true}>
                        {events ? <>
                            <div className="pb-3">
                                <div>Du kannst das Ranking ändern, indem du die Reihenfolge der Events per Drag-and-Drop änderst. Dieses wird jedoch erst dann gespeichert, wenn du auf <i>Ranking akzeptieren</i> drückst.</div>
                                <div>Du kannst jegliche lokalen Änderungen mit <i>Ranking zurücksetzen</i> auf den vorherigen Stand zurücksetzen.</div>
                                {currentPost.data().status === 'awaiting_approval'
                                    ? <div>Sobald du das Ranking akzeptierst, werden keine neuen Events mehr automatisch in das Ranking hinzugefügt.</div>
                                    : <div><b>Du hast ein Ranking bereits akzeptiert</b>, sodass keine neuen Events mehr automatisch in das Ranking hinzugefügt werden. Dies kannst du rückgängig machen, indem du auf <i>Ranking wieder freigeben</i> drückst.</div>}
                                <div className="flex gap-3">
                                    <button onClick={saveEvents} className="bg-blue-500 text-white rounded px-2 py-3 font-semibold">Ranking akzeptieren</button>
                                    {currentPost.data().events?.reverse().toString() !== events.map(e => e.id).toString() && <button onClick={loadEvents} className="bg-gray-400 text-white rounded px-2 py-3 font-semibold">Ranking zurücksetzen</button>}
                                    {currentPost.data().status === 'scheduled' && <button onClick={resetEvents} className="bg-gray-400 text-white rounded px-2 py-3 font-semibold">Ranking wieder freigeben</button>}
                                </div>
                            </div>
                            
                            <div className="flex flex-col gap-3">
                                <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                                    <SortableContext items={events}>
                                        {events.map((event, index) => <>
                                            <SortableEvent key={event.id} id={event.id} event={event} index={index} />
                                            {index === 4 && <div className="border rounded p-3 bg-gray-200">Folgende Events haben es nicht in die Top 5 geschafft</div>}
                                        </>)}
                                    </SortableContext>
                                </DndContext>
                            </div>
                        </> : <div>Events werden generiert... Falls nach 5 Minuten immernoch keine Events zu sehen sind, ist eventuell der Video-Generator nicht am laufen, oder es ist ein Fehler aufgetreten.</div>}
                    </Section>}
                </> : <div>Bitte wähle einen Post aus</div>}
            </div>
        </div>
    </div>;
}

function SortableEvent(props) {
    const location = useLocation();

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({id: props.id});

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: isDragging ? 1 : 0,
    };

    const event = props.event;
    const index = props.index;

    const [eventLocation, setEventLocation] = useState(null);
    useEffect(() => { (async () => {
        let res = null;
        if(event.data().venue) {
            await firestore.collection('organizers').doc(event.data().venue).get().then(venue => res = venue?.data()?.name);
        }
        if(!res) {
            res = event.data().address;
        }
        setEventLocation(res);
    })() }, [event]);

    return <div className="flex flex-col md:flex-row border rounded p-3 gap-3 bg-white" ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <div>{index + 1}</div>
        <ReferenceImage reference={event.data().images?.length > 0 && event.data().images[0]} />
        <div>
            <div className="font-semibold">{event.data().name}</div>
            <div>am {event.data().start.toDate().toLocaleString('de-DE')}</div>
            <div>angekündigt am {event.data().created_timestamp.toDate().toLocaleString('de-DE')}</div>
            <div>{eventLocation}</div>
            <div className="flex gap-3">
                <div>{event.data().view_count??0} Views</div>
                <div>{event.data().bookmark_count??0} Merkungen</div>
                <div>{event.data().share_count??0} Teilungen</div>
                <div>{event.data().attendee_count??0} Teilnehmer</div>
            </div>
        </div>
        <div className="ml-auto mt-3">
            <Link to={`/events/${event.id}?return=${encodeURIComponent(location.pathname + location.search)}`} className="bg-blue-500 text-white rounded px-2 py-3 font-semibold">Anzeigen</Link>
        </div>
    </div>
}

function ReferenceImage({reference}) {
    const [url, setUrl] = useState('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');

    useEffect(() => reference ? storage.ref(reference).getDownloadURL().then(url => setUrl(url)).catch(_ => setUrl('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==')) : null, [reference]);

    return <img className="w-32 h-24 object-cover" src={url} alt="" />;
}

function ReferenceVideo({reference}) {
    const [url, setUrl] = useState(null);

    useEffect(() => reference ? storage.ref(reference).getDownloadURL().then(url => setUrl(url)).catch(_ => setUrl(null)) : null, [reference]);

    return url ? <video src={url} className="h-auto w-full md:w-1/4" controls /> : <div>Video wird erstellt... Falls nach 5 Minuten immernoch kein Video zu sehen ist, ist eventuell der Video-Generator nicht am laufen, oder es ist ein Fehler aufgetreten.</div>;
}