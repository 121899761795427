import {
    Switch,
    Route,
    useRouteMatch,
  } from "react-router-dom";
import Ticket from "./Ticket";
import Sidebar from "./Sidebar";

export default function Tickets() {
    let match = useRouteMatch();
    
    return <div className="h-full flex w-auto">
        <Sidebar collection="tickets" itemTitle={(item) => `${item.first_name} ${item.last_name??''}`} itemSubtitle={(item) => `${item.status} - ${item.created_timestamp.toDate().toLocaleString('de-DE')}`} itemLink={(item) => `/tickets/${item.objectID.split('-')[1]}`} orders={[
            {
                id: 'creation',
                label: 'Erstellungsdatum',
                field: 'created_timestamp',
                direction: 'desc',
            },
        ]} defaultOrder="creation" filters={[
            {
                id: 'event',
                label: 'Event',
                field: 'event',
                type: 'equals',
            },
            {
                id: 'type',
                label: 'Tickettyp',
                field: 'type',
                type: 'equals',
            },
            {
                id: 'status',
                label: 'Status',
                field: 'status',
                type: 'equals',
            },
        ]} />
        <div className="flex-grow overflow-auto">
            <Switch>
                <Route path={`${match.path}/:id`} component={Ticket} />
                <Route path={match.path}>
                    <p className="p-3">
                        Bitte selektiere ein Ticket.
                    </p>
                </Route>
            </Switch>
        </div>
    </div>;
}