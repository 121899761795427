import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";
import { connectFunctionsEmulator } from "firebase/functions";
const firebaseConfig = {
    apiKey: "AIzaSyCXVibXLkjpycRSVilz2MFpdptPJUKGyKc",
    authDomain: "elgio-invited.firebaseapp.com",
    databaseURL: "https://elgio-invited.firebaseio.com",
    projectId: "elgio-invited",
    storageBucket: "elgio-invited.appspot.com",
    messagingSenderId: "915261911218",
    appId: "1:915261911218:web:22ed7747a11fac60df59ab",
    measurementId: "G-7J8XMT7Y9N",
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const FieldValue = firebase.firestore.FieldValue;
export const functions = firebase.functions();
export const GeoPoint = firebase.firestore.GeoPoint;
export const Timestamp = firebase.firestore.Timestamp;
export const storage = firebase.storage();

if(window.location.hostname === "localhost"){
    // auth.useEmulator("http://localhost:9099");
    // connectFunctionsEmulator(functions, "localhost","5001");
    // firestore.useEmulator("localhost", 8080);
    // storage.useEmulator("localhost", 9199);
}