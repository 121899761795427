import { useHistory } from "react-router-dom";
import {
    Switch,
    Route,
    useRouteMatch,
  } from "react-router-dom";
import Genre from "./Genre";
import Sidebar from "./Sidebar";

export default function Genres() {
    const history = useHistory();

    const newGenre = () => {
        const id = prompt('Bitte ID für neue Musikrichtung eingeben. Normalerweise englischer Name in Kleinbuchstaben und zusammengeschriben (z.B. "hiphop" oder "kpop").');
        if(id) history.push(`/genres/${id}`);
    };

    let match = useRouteMatch();
    
    return <div className="h-full flex w-auto">
        <Sidebar newItem={newGenre} newItemText="Neue Musikrichtung" collection="genres" itemTitle={(item) => item.de} itemLink={(item) => `/genres/${item.objectID.split('-')[1]}`} />
        <div className="flex-grow overflow-auto">
            <Switch>
                <Route path={`${match.path}/:id`} component={Genre}/>
                <Route path={match.path}>
                    <p className="p-3">
                        Bitte selektiere eine Musikrichtung.
                    </p>
                </Route>
            </Switch>
        </div>
    </div>;
}