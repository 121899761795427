import React, { Component, createContext } from "react";
import { firestore } from "../firebase";

export const CategoriesContext = createContext({
  loaded: false,
  cities: null,
  gernes: null,
  types: null,
});

export default class CategoriesProvider extends Component {
  state = {
    loaded: false,
    cities: null,
    gernes: null,
    types: null,
    locations: null,
    categories: null,
    themes: null,
  };

  componentDidMount = async () => {
    const citiesPromise = firestore.collection('cities').get();
    const genresPromise = firestore.collection('genres').get();
    const typesPromise = firestore.collection('types').get();

    const [ cities, genres, types ] = await Promise.all([citiesPromise, genresPromise, typesPromise]);

    this.setState({
      loaded: true,
      cities: cities.docs,
      genres: genres.docs,
      types: types.docs,
      locations: types.docs.filter(e => e.data().type === 'location'),
      categories: types.docs.filter(e => e.data().type === 'category'),
      themes: types.docs.filter(e => e.data().type === 'theme'),
    });
  };

  render() {
    return (
      <CategoriesContext.Provider value={this.state}>
        {this.props.children}
      </CategoriesContext.Provider>
    );
  }
}