import {
    Switch,
    Route,
    useHistory,
    useRouteMatch,
  } from "react-router-dom";
import Event from "./Event";
import EventShare from "./EventShare";
import { firestore } from "./firebase";
import Fusion from "./Fusion";
import Sidebar from "./Sidebar";

export default function Events() {
    const history = useHistory();

    const newEvent = () => {
        const reference = firestore.collection('events').doc();
        history.push(`/events/${reference.id}`);
    };

    let match = useRouteMatch();
    
    return <div className="h-full flex w-auto">
        <Sidebar newItem={newEvent} newItemText="Neues Event" collection="events" recordType="event" itemTitle={(item) => item.name} itemSubtitle={(item) => new Date((item.start?._seconds??item.start?.seconds??0) * 1000).toLocaleString('de-DE')} itemLink={(item) => `/events/${item.objectID.split('-')[1]}`} orders={[
            {
                id: 'upcoming',
                label: 'Kommende Events',
                field: 'start',
                direction: 'asc',
                fromNow: true,
            },
            {
                id: 'past',
                label: 'Vergangene Events',
                field: 'start',
                direction: 'desc',
                fromNow: true,
            },
            {
                id: 'new',
                label: 'Neue Events',
                field: 'created_timestamp',
                direction: 'desc',
            },
        ]} defaultOrder="upcoming" filters={[
            {
                id: 'city',
                label: 'Stadt',
                field: 'city',
                type: 'equals',
            },
            {
                id: 'organizer',
                label: 'Organizer',
                field: 'organizer',
                type: 'array-contains',
            },
            {
                id: 'artist',
                label: 'Künstler',
                field: 'lineup',
                type: 'array-contains',
            },
            {
                id: 'recurring-event',
                label: 'Eventreihe',
                field: 'recurring_event',
                type: 'equals',
            },
        ]} />
        <div className="flex-grow overflow-auto">
            <Switch>
                <Route path={`${match.path}/:id/share`} component={EventShare} />
                <Route path={`${match.path}/:id1/fusion/:id2`}><Fusion collection="events" recordType="event" /></Route>
                <Route path={`${match.path}/:id1/fusion`}><Fusion collection="events" recordType="event" /></Route>
                <Route path={`${match.path}/:id`} component={Event} />
                <Route path={match.path}>
                    <p className="p-3">
                        Bitte selektiere ein Event.
                    </p>
                </Route>
            </Switch>
        </div>
    </div>;
}