import { useEffect, useState } from "react";
import IconTint from "react-icon-tint";
import { storage } from "../firebase";

import logo from "./logoNew.png";
import gradient from "./gradient.png";
import kitColorInverted from "./kit_color_inverted.png";

export default class KoelnIstTechnoDesign {
    constructor({ color, secondaryColor, backgroundColor, logo, colorEditable, backgroundColorEditable }) {
        this.color = color;
        this.secondaryColor = secondaryColor;
        this.backgroundColor = backgroundColor;
        this.logo = logo;
        this.colorEditable = colorEditable;
        this.backgroundColorEditable = backgroundColorEditable;
    }

    copyWith({ color, secondaryColor, backgroundColor, logo }) {
        return new KoelnIstTechnoDesign({
            color: color??this.color,
            secondaryColor: secondaryColor??this.secondaryColor,
            backgroundColor: backgroundColor??this.backgroundColor,
            logo: logo??this.logo,
            colorEditable: this.colorEditable,
            backgroundColorEditable: this.backgroundColorEditable,
        });
    }

    element({ data, location }) {

        //const color = design === 'koelnisttechno' ? '#fbe627' : '#000000';
        //const backgroundColor = design === 'koelnisttechno' ? '#000000' : design === 'koelnisttechnoRed' ? '#c80a0a' : '#fbe627';

        return <div className="text-white text-4xl" style={{ height: 1920, width: 1080, fontFamily: 'GlacialIndifference', backgroundColor: this.backgroundColor }}>
            <div className="w-full h-full flex flex-col">
                <div className="relative overflow-hidden h-1/3 bg-white">
                    <ReferenceImage className="absolute top-0 left-0 right-0 bottom-0" reference={(data?.images?.length??0) > 0 ? data?.images[0] : null} />
                    {/*<div className="absolute bg-gradient-to-t from-white to-transparent h-10 bottom-0 left-0 right-0" />*/}
                    <div key={this.backgroundColor} className="absolute bottom-0 left-0 right-0"><IconTint src={gradient} color={this.backgroundColor} /></div>
                    <div key={this.color} className="absolute bottom-0 left-0 right-0"><IconTint src={kitColorInverted} color={this.color} /></div>
                    <p className="absolute bottom-12 left-0 right-0 text-black text-4xl font-bold text-center" style={{color: this.backgroundColor}}>
                        {(data?.start?.toDate()?.toLocaleDateString('de-DE', { weekday: 'long', month: 'long', day: 'numeric' })?.toUpperCase()??'-') + ' ab ' + (data?.start?.toDate()?.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })??'-') + ' Uhr'}
                    </p>
                </div>
                <div className="h-2/3 px-10 pb-10 flex flex-col items-center">
                    <div className="text-center text-6xl font-bold h-36" style={{color: this.color}}>{data?.name?.toUpperCase()??'-'}</div>
                    <div className="flex w-full mt-10 h-36">
                        <div className="w-1/3 flex flex-col items-center text-center py-5">
                            <div className="text-4xl mb-2" style={{color: this.secondaryColor}}>Location</div>
                            <div className="text-5xl" style={{color: this.color}}>{location.toUpperCase()??data?.address??'N/A'}</div>
                        </div>
                        {/*<div className="border-l border-white ml-5 pr-5" />*/}
                        <div className="ml-5 pr-5" />
                        <div className="w-1/3 flex flex-col items-center text-center py-5">
                            <div className="text-4xl mb-2" style={{color: this.secondaryColor}}>Veranstalter</div>
                            <div className="text-5xl" style={{color: this.color}}>{data?.organizer?.toUpperCase()??'N/A'}</div>
                        </div>
                        {/*<div className="border-l border-white ml-5 pr-5" />*/}
                        <div className="ml-5 pr-5" />
                        <div className="w-1/3 flex flex-col items-center text-center py-5">
                            <div className="text-4xl mb-2" style={{color: this.secondaryColor}}>Preis</div>
                            <div className="text-5xl" style={{color: this.color}}>{data?.price??'N/A'}</div>
                        </div>
                    </div>
                    <div className="mt-10 px-16 w-full">
                        {data?.lineup?.length > 0 && <div className="text-4xl mb-2" style={{color: this.secondaryColor}}>Lineup</div>}
                        <div className="grid grid-flow-col" style={{gridTemplateRows: 'repeat(14, minmax(0, 1fr))'}}>
                            {data?.lineup?.map(e => <div className="text-5xl" style={{color: this.color}}>{e}</div>)}
                        </div>
                    </div>
                    <div className="mt-auto w-full flex justify-between items-center p-3">
                        <div>
                            <img className="h-24 w-24" src={this.logo} alt=""/>
                        </div>
                        <div>
                            <img className="h-40" style={{marginBottom: "-20px"}}src={logo} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

function ReferenceImage({className, reference, ...rest}) {
    const [url, setUrl] = useState('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');

    useEffect(() => reference ? storage.ref(reference).getDownloadURL().then(url => setUrl(url)).catch(_ => setUrl('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==')) : null, [reference]);

    return <div className={className??'w-full h-full'} style={{
        backgroundImage: `url('${url}')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    }}/>;
}