import {
    Switch,
    Route,
    useHistory,
    useRouteMatch,
  } from "react-router-dom";
import Organizer from "./Organizer";
import { firestore } from "./firebase";
import Fusion from "./Fusion";
import Sidebar from "./Sidebar";

export default function Organizers() {
    const history = useHistory();

    const newOrganizer = () => {
        const reference = firestore.collection('organizers').doc();
        history.push(`/organizers/${reference.id}`);
    };

    let match = useRouteMatch();
    
    return <div className="h-full flex w-auto">
        <Sidebar newItem={newOrganizer} newItemText="Neuer Organizer" recordType="organizer" itemTitle={(item) => item.name} itemLink={(item) => `/organizers/${item.objectID.split('-')[1]}`} />
        <div className="flex-grow overflow-auto">
            <Switch>
                <Route path={`${match.path}/:id1/fusion/:id2`}><Fusion collection="organizers" recordType="organizer" /></Route>
                <Route path={`${match.path}/:id1/fusion`}><Fusion collection="organizers" recordType="organizer" /></Route>
                <Route path={`${match.path}/:id`} component={Organizer}/>
                <Route path={match.path}>
                    <p className="p-3">
                        Bitte selektiere einen Organizer.
                    </p>
                </Route>
            </Switch>
        </div>
    </div>;
}