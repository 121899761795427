import { useContext, useEffect, useState } from "react";
import { Prompt } from "react-router-dom";
import {
    useHistory,
    useParams,
    useLocation,
} from "react-router-dom";
import Field from "./Field";
import { firestore } from "./firebase";
import { UserContext } from "./providers/UserProvider";
import { toast } from "react-toastify";


export default function Artist() {
    const history = useHistory();
    const location = useLocation();

    const { role, user } = useContext(UserContext);

    const { id } = useParams();

    const [data, setData] = useState({});
    const [artist, setArtist] = useState(null);

    const [unsavedChanges, setUnsavedChanges] = useState(false);

    const [activities24Hours, setActivities24Hours] = useState([]);
    const [activities7Days, setActivities7Days] = useState([]);

    const [scrapers, setScrapers] = useState([]);

    useEffect(() => JSON.stringify(data) !== JSON.stringify(artist?.data()) ? setUnsavedChanges(true) : setUnsavedChanges(false), [data, artist]);

    useEffect(() => firestore.collection('artists').doc(id).get().then(document => {
        setData(document.data());
        setArtist(document);
    }), [id]);

    useEffect(() => firestore.collection('activities').where('timestamp', '>', new Date(Date.now() - 24 * 60 * 60 * 1000)).where('artist', '==', id).onSnapshot(snap => setActivities24Hours(snap.docs)), [id]);
    useEffect(() => firestore.collection('activities').where('timestamp', '>', new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).where('artist', '==', id).onSnapshot(snap => setActivities7Days(snap.docs)), [id]);    

    useEffect(() => {
        const result = [];
        for(const key of Object.keys(artist?.data()??{})) {
            if(key.endsWith('_identifier')) {
                result.push(key.split('_')[0]);
            }
        }
        setScrapers(result);
    }, [artist]);

    const save = async () => {
        data.agent = user.uid;
        await firestore.collection('artists').doc(id).set(data, { merge: true });
        setUnsavedChanges(false);
        toast.success("Speichern erfolgreich", {theme: "dark"})
    };

    const fusion = () => history.push(`/artists/${id}/fusion`);

    const remove = async () => {
        await firestore.collection('artists').doc(id).delete();
        toast.success("Löschen erfolgreich", {theme: "dark"})
        history.push('/artists');
    }

    const writePermission = role === 'admin' || artist?.data()?.agent === user.uid || !artist?.exists;

    return <div>
        <Prompt message="Einige Änderungen wurden noch nicht gespeichert. Trotzdem fortfahren?" when={unsavedChanges} />
        <div className="bg-gray-500 flex flex-col md:flex-row gap-2 p-4 md:py-0 md:px-2">
            {writePermission && <button className="bg-blue-500 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold text-white" onClick={save}>Änderungen speichern</button>}
            <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={() => history.push(`/events?artist=${id}&return=${encodeURIComponent(location.pathname + location.search)}`)}>Events anzeigen</button>
            {role === 'admin' && <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={fusion}>Mit anderem Künstler vereinigen</button>}
            {role === 'admin' && 
            <a href={"https://console.firebase.google.com/u/1/project/elgio-invited/firestore/databases/-default-/data/~2Fartist~2F" + id} 
                target="_blank" rel="noreferrer"
                className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold flex items-center text-center"> 
                    In der Datenbank ansehen
            </a>}
            

            <div className="bg-gray-500 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold hidden md:block text-gray-500">a</div>
        </div>
        {
            id && artist ? <div className="p-3" key={artist.id}>
                <div className="font-bold">Metadaten</div>
                <div className="flex-col md:flex-row flex my-1">
                    <div className="w-64">ID:</div>
                    <div className="w-64">{id}</div>
                </div>
                <div className="flex-col md:flex-row flex my-1">
                    <div className="w-64">Erstellt:</div>
                    <div className="w-64">{artist?.data()?.created_timestamp?.toDate()?.toLocaleString('de-DE')}</div>
                </div>
                <div className="flex-col md:flex-row flex my-1">
                    <div className="w-64">Zuletzt bearbeitet von:</div>
                    <div className="w-64">{artist?.data()?.agent}</div>
                </div>
                {scrapers.length > 0 && <div className="flex-col md:flex-row flex my-1">
                    <div className="w-64">Scraper:</div>
                    <div className="w-64">{scrapers.join(', ')}</div>
                </div>}
                {artist?.data()?.partner && <div className="flex-col md:flex-row flex my-1">
                    <div className="w-64">Erstellt mit Partner-Formular:</div>
                    <div className="w-64">{artist?.data()?.partner}</div>
                </div>}
                <div className="font-bold mt-3 pt-3 border-t-2">Statistiken</div>
                <div className="flex-col md:flex-row flex my-1">
                    <div className="w-64 font-semibold">In den letzten 24 Stunden:</div>
                    <div className="w-32">{activities24Hours.filter(e => e.data().type === 'artist_view').length??0} Views</div>
                    <div className="w-32">{activities24Hours.filter(e => e.data().type === 'artist_subscription').length??0} Folgende</div>
                    <div className="w-32">{activities24Hours.filter(e => e.data().type === 'artist_share').length??0} Teilungen</div>
                </div>
                <div className="flex-col md:flex-row flex my-1">
                    <div className="w-64 font-semibold">In den letzten 7 Tagen:</div>
                    <div className="w-32">{activities7Days.filter(e => e.data().type === 'artist_view').length??0} Views</div>
                    <div className="w-32">{activities7Days.filter(e => e.data().type === 'artist_subscription').length??0} Folgende</div>
                    <div className="w-32">{activities7Days.filter(e => e.data().type === 'artist_share').length??0} Teilungen</div>
                </div>
                <div className="flex-col md:flex-row flex my-1">
                    <div className="w-64 font-semibold">Insgesamt:</div>
                    <div className="w-32">{artist?.data()?.view_count??0} Views</div>
                    <div className="w-32">{artist?.data()?.subscription_count??0} Folgende</div>
                    <div className="w-32">{artist?.data()?.share_count??0} Teilungen</div>
                </div>
                <div className="font-bold mt-3 pt-3 border-t-2">Daten</div>
                {role === 'admin' && <Field name="Sichtbarkeit" value={artist.data()?.visibility??'public'} onChange={(val) => setData({...data, ...{visibility: val}})} />}
                <Field name="Name" value={artist.data()?.name} onChange={(val) => setData({...data, ...{name: val}})} />
                <Field name="Label" value={data?.labels} type="array-organizer" onChange={(val) => setData({...data, ...{labels: val}})} />
                <Field name="Spotify ID" value={data?.spotify_identifier} onChange={(val) => setData({...data, ...{spotify_identifier: val}})} />
                <Field name="Soundcloud ID" value={data?.soundcloud_identifier} onChange={(val) => setData({...data, ...{soundcloud_identifier: val}})} />
                <Field name="Top-Track Name" value={data?.top_track_name} onChange={(val) => setData({...data, ...{top_track_name: val}})} />
                <Field name="Top-Track URL" value={data?.top_track_url} type="link" onChange={(val) => setData({...data, ...{top_track_url: val}})} />
                <Field name="Musikrichtungen" value={artist.data()?.genre} type="array-genre" onChange={(val) => setData({...data, ...{genre: val}})} />
                <Field name="Bild" value={data?.images ? data.images[0] : null} type="image" fileDirectory={`artist_images/${id}`} artistId={id} onChange={(val) => setData({...data, ...{images: [val]}})} />
                <button className="bg-blue-500 text-white rounded px-2 md:px-10 md:py-3 font-semibold" onClick={writePermission && save}>{writePermission ? 'Änderungen speichern' : 'Du hast hier keine Schreibberechtigung'}</button>
            </div> : <div className="p-3">
                Bitte warten...
            </div>
        }
    </div>;
}