import { useContext, useEffect, useRef, useState } from "react";
import { Prompt } from "react-router-dom";
import {
    useHistory,
    useParams,
    useLocation
} from "react-router-dom";
import Field from "./Field";
import { firestore } from "./firebase";
import { UserContext } from "./providers/UserProvider";
import queryString from "query-string";
import { toast } from "react-toastify";


export default function Organizer() {
    const ref = useRef();

    const history = useHistory();
    const location = useLocation();

    const [queryParams, setQueryParams] = useState({});
    useEffect(() => setQueryParams(queryString.parse(location.search)), [location.search]);

    const { role, user } = useContext(UserContext);

    const { id } = useParams();

    const [data, setData] = useState({});
    const [organizer, setOrganizer] = useState(null);

    const [unsavedChanges, setUnsavedChanges] = useState(false);
    
    const [address, setAddress] = useState(null);
    const [postalCode, setPostalCode] = useState(null);
    const [city, setCity] = useState(null);

    const [activities24Hours, setActivities24Hours] = useState([]);
    const [activities7Days, setActivities7Days] = useState([]);

    const [scrapers, setScrapers] = useState([]);

    
    useEffect(() => JSON.stringify(data) !== JSON.stringify(organizer?.data()) ? setUnsavedChanges(true) : setUnsavedChanges(false), [data, organizer]);
    useEffect(() => firestore.collection('organizers').doc(id).get().then(document => {
        setData(document.data());
        setOrganizer(document);
        ref.current.scrollTop = 0;
    }), [id]);

    useEffect(() => {
        const splitAddress = organizer?.data()?.address?.split(', ');
        setAddress(splitAddress?.length > 0 ? splitAddress[0] : '');
        setPostalCode(splitAddress?.length > 0 ? splitAddress[1] : '');
        setCity(organizer?.data()?.city??(splitAddress?.length > 0 ? splitAddress[2] : ''));
    }, [organizer]);

    useEffect(() => firestore.collection('activities').where('timestamp', '>', new Date(Date.now() - 24 * 60 * 60 * 1000)).where('organizer', '==', id).onSnapshot(snap => setActivities24Hours(snap.docs)), [id]);
    useEffect(() => firestore.collection('activities').where('timestamp', '>', new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).where('organizer', '==', id).onSnapshot(snap => setActivities7Days(snap.docs)), [id]);    

    useEffect(() => {
        const result = [];
        for(const key of Object.keys(organizer?.data()??{})) {
            if(key.endsWith('_identifier')) {
                result.push(key.split('_')[0]);
            }
        }
        setScrapers(result);
    }, [organizer]);

    const back = () => {
        if(!queryParams.return) return false;
        history.push(queryParams.return);
        return true;
    }

    const save = async () => {
        if(address && postalCode && city)
            data.address = address + ', ' + postalCode + ', ' + city.charAt(0).toUpperCase() + city.slice(1);
        else if(address)
            data.address = address;
        if(city)
            data.city = city;
        data.agent = user.uid;
        await firestore.collection('organizers').doc(id).set(data, { merge: true });
        setUnsavedChanges(false);
        toast.success("Speichern erfolgreich", {theme: "dark"})

    };

    const fusion = () => history.push(`/organizers/${id}/fusion`);

    const remove = async () => {
        if(!window.confirm('Bist du dir sicher, dass du diesen Eintrag löschen willst?')) return;
        await firestore.collection('organizers').doc(id).delete();
        toast.success("Löschen erfolgreich", {theme: "dark"})
        history.push('/organizers');
    };

    //const writePermission = role === 'admin' || organizer?.data()?.agent === user.uid || !organizer?.exists;
    const writePermission = true;

    return <div className="h-full flex flex-col">
        <Prompt message="Einige Änderungen wurden noch nicht gespeichert. Trotzdem fortfahren?" when={unsavedChanges} />
        <div className="bg-gray-500 flex flex-col md:flex-row gap-2 p-4 md:py-0 md:px-2">
            {queryParams.return && <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={back}>Zurück</button>}
            {writePermission && <button className="bg-blue-500 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold text-white" onClick={save}>Änderungen speichern</button>}
            <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={() => history.push(`/events?organizer=${id}&return=${encodeURIComponent(location.pathname + location.search)}`)}>Events anzeigen</button>
            {/*<button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={() => alert('Diese Funktion wurde noch nicht implementiert.')}>Daten von Facebook importieren</button>*/}
            <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={fusion}>Mit anderem Organizer vereinigen</button>
            {writePermission && <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={remove}>Organizer löschen</button>}
            {role === 'admin' && 
            <a href={"https://console.firebase.google.com/u/1/project/elgio-invited/firestore/databases/-default-/data/~2Forganizers~2F" + id} 
                target="_blank" rel="noreferrer"
                className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold flex items-center"> 
                    In der Datenbank ansehen
            </a>}
            <div className="bg-gray-500 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold hidden md:block text-gray-500">a</div>

        </div>
        {
            id && organizer ? <div className="p-3 overflow-scroll" key={organizer.id} ref={ref}>
                <div className="font-bold">Metadaten</div>
                <div className="flex-col md:flex-row flex my-1">
                    <div className="w-64">ID:</div>
                    <div className="w-64">{id}</div>
                </div>
                <div className="flex-col md:flex-row flex my-1">
                    <div className="w-64">Erstellt:</div>
                    <div className="w-64">{organizer?.data()?.created_timestamp?.toDate()?.toLocaleString('de-DE')}</div>
                </div>
                <div className="flex-col md:flex-row flex my-1">
                    <div className="w-64">Zuletzt bearbeitet von:</div>
                    <div className="w-64">{organizer?.data()?.agent}</div>
                </div>
                {scrapers.length > 0 && <div className="flex-col md:flex-row flex my-1">
                    <div className="w-64">Scraper:</div>
                    <div className="w-64">{scrapers.join(', ')}</div>
                </div>}
                {organizer?.data()?.partner && <div className="flex-col md:flex-row flex my-1">
                    <div className="w-64">Erstellt mit Partner-Formular:</div>
                    <div className="w-64">{organizer?.data()?.partner}</div>
                </div>}
                <div className="font-bold mt-3 pt-3 border-t-2">Statistiken</div>
                <div className="flex-col md:flex-row flex my-1">
                    <div className="w-64 font-semibold">In den letzten 24 Stunden:</div>
                    <div className="w-32">{activities24Hours.filter(e => e.data().type === 'organizer_view').length??0} Views</div>
                    <div className="w-32">{activities24Hours.filter(e => e.data().type === 'organizer_subscription').length??0} Folgende</div>
                    <div className="w-32">{activities24Hours.filter(e => e.data().type === 'organizer_share').length??0} Teilungen</div>
                </div>
                <div className="flex-col md:flex-row flex my-1">
                    <div className="w-64 font-semibold">In den letzten 7 Tagen:</div>
                    <div className="w-32">{activities7Days.filter(e => e.data().type === 'organizer_view').length??0} Views</div>
                    <div className="w-32">{activities7Days.filter(e => e.data().type === 'organizer_subscription').length??0} Folgende</div>
                    <div className="w-32">{activities7Days.filter(e => e.data().type === 'organizer_share').length??0} Teilungen</div>
                </div>
                <div className="flex-col md:flex-row flex my-1">
                    <div className="w-64 font-semibold">Insgesamt:</div>
                    <div className="w-32">{organizer?.data()?.view_count??0} Views</div>
                    <div className="w-32">{organizer?.data()?.subscription_count??0} Folgende</div>
                    <div className="w-32">{organizer?.data()?.share_count??0} Teilungen</div>
                </div>
                <div className="font-bold mt-3 pt-3 border-t-2">Daten</div>
                {role === 'admin' && <Field name="Sichtbarkeit" value={data?.visibility??'public'} type="dropdown" options={['public', 'private']} onChange={(val) => setData({...data, ...{visibility: val}})} />}
                {role === 'admin' && <Field name="Verlinkter User" value={data?.user} type="user" onChange={(val) => setData({...data, ...{user: val}})} />}
                <Field name="Genereller Typ" value={data?.type??'standard'} type="dropdown" options={['standard','venue']} onChange={(val) => setData({...data, ...{type: val}})} />
                <Field name="Name" value={data?.name} onChange={(val) => setData({...data, ...{name: val}})} />
                <Field name="Kategorien" value={data?.types} type="array-type" onChange={(val) => setData({...data, ...{types: val}})} />
                <Field name="Resident Artists" value={data?.residents} type="array-artist" onChange={(val) => setData({...data, ...{residents: val}})} />
                <Field name="Beschreibung" value={data?.description} type="multiline" onChange={(val) => setData({...data, ...{description: val}})} isEssential={true}/>
                {role === 'admin' && <Field name="Notizen" value={data?.note} type="multiline" onChange={(val) => setData({...data, ...{note: val}})} />}
                <Field name="Straße + Hausnummer" value={address} onChange={(val) => setAddress(val)} isEssential={true}/>
                <Field name="Postleitzahl" value={postalCode} onChange={(val) => setPostalCode(val)} isEssential={true} />
                <Field name="Stadt" value={city} type="city" allowOther={true} onChange={(val) => setCity(val)} isEssential={true} />
                <Field name="Musikrichtungen" value={data?.genre} type="array-genre" onChange={(val) => setData({...data, ...{genre: val}})} isEssential={true} />
                <Field name="E-Mail-Adresse" value={data?.email} onChange={(val) => setData({...data, ...{email: val}})} />
                <Field name="Telefonnummer" value={data?.phone} onChange={(val) => setData({...data, ...{phone: val}})} />
                <Field name="Webseite" value={data?.website} type="link" onChange={(val) => setData({...data, ...{website: val}})} />
                <Field name="Instagram" value={data?.instagram} onChange={(val) => setData({...data, ...{instagram: val}})} isEssential={true}/>
                <Field name="YouTube" value={data?.youtube} onChange={(val) => setData({...data, ...{youtube: val}})} />
                <Field name="TikTok" value={data?.tiktok} onChange={(val) => setData({...data, ...{tiktok: val}})} />
                <Field name="Facebook" value={data?.facebook} onChange={(val) => setData({...data, ...{facebook: val}})} />
                <Field name="Bild" value={data?.images ? data.images[0] : null} type="image" fileDirectory={`organizer_images/${id}`} organizerId={id} onChange={(val) => setData({...data, ...{images: [val]}})} />
                <Field name="Icon" value={data?.icon} type="image" fileDirectory={`organizer_images/${id}`} organizerId={id} onChange={(val) => setData({...data, ...{icon: val}})} />
                <button className="bg-blue-500 text-white rounded px-2 md:px-10 md:py-3 font-semibold" onClick={writePermission && save}>{writePermission ? 'Änderungen speichern' : 'Du hast hier keine Schreibberechtigung'}</button>
            </div> : <div className="p-3">
                Bitte warten...
            </div>
        }
    </div>;
}