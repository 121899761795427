import { useEffect, useState } from "react";
import { firestore } from "./firebase";

export default function EventShareBox({ data, design }) {
    const [location, setLocation] = useState('');
    useEffect(() => data && firestore.collection('cities').doc(data.city || 'a').get().then(e => e.data()?.name??data.city).then(e => setLocation(data.venue ? data.venue : 'N/A')), [data?.city, data?.venue, design]);

    const [city, setCity] = useState('');
    useEffect(() => data?.city && firestore.collection('cities').doc(data.city).get().then(e => setCity(e.data()?.name??data.city)), [data?.city]);
    //useEffect(() => data && data.organizer?.length > 0 && firestore.collection('organizers').doc(data.organizer[0]).get().then(e => setOrganizer(e.data().name)), [data?.organizer]);
    //useEffect(() => data?.lineup ? Promise.all(data.lineup.map(e =>  e ? firestore.collection('artists').doc(e).get() : null)).then(e => setLineup(e.map(e => e?.data()?.name))) : setLineup([]), [data?.lineup]);

    return design.element({ data, location, city });
}