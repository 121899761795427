import { useContext, useEffect, useState } from "react";
import { Prompt } from "react-router-dom";
import {
    useHistory,
    useParams
} from "react-router-dom";
import Field from "./Field";
import { firestore } from "./firebase";
import { UserContext } from "./providers/UserProvider";
import { toast } from "react-toastify";
import Section from "./Section";
import crypto from "crypto";

export default function Subsite() {
    const history = useHistory();

    const { role, user } = useContext(UserContext);

    const { id } = useParams();

    const [data, setData] = useState({});
    const [subsite, setSubsite] = useState(null);

    const [unsavedChanges, setUnsavedChanges] = useState(false);

    useEffect(() => JSON.stringify(data) !== JSON.stringify(subsite?.data()) ? setUnsavedChanges(true) : setUnsavedChanges(false), [subsite, data]);

    useEffect(() => firestore.collection('subsites').doc(id).get().then(document => {
        setData(document.data());
        setSubsite(document);
    }), [id]);

    const save = async () => {
        data.agent = user.uid;
        if(!data.visibility) data.visibility = 'public';
        await firestore.collection('subsites').doc(id).set(data, { merge: true });
        setUnsavedChanges(false);
        toast.success("Speichern erfolgreich", {theme: "dark"})
    };

    const remove = async () => {
        if(!window.confirm('Bist du dir sicher, dass du diese Website löschen willst?')) return;
        await firestore.collection('subsites').doc(id).delete();
        toast.success("Löschen erfolgreich", {theme: "dark"})
        history.push('/subsites');
    }

    return <div>
        {/* <Prompt message="Einige Änderungen wurden noch nicht gespeichert. Trotzdem fortfahren?" when={unsavedChanges} /> */}
        <div className="bg-gray-500 flex flex-col md:flex-row gap-2 p-4 md:py-0 md:px-2">
            <button className="bg-blue-500 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold text-white" onClick={save}>Änderungen speichern</button>
            <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold">
                <a href={"https://" + subsite?.id + ".elgio.app" + (data?.visibility !== 'public' ? '?key=' + crypto.createCipheriv('aes-256-ctr', crypto.createHash('sha256').update('elgio').digest('base64').slice(0, 32), Buffer.alloc(16)).update(id, 'utf-8', 'hex') : '')} target="_blank" rel="noreferrer">Website anschauen</a>
            </button>
            <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={remove}>Website löschen</button>
            {role === 'admin' && 
            <a href={"https://console.firebase.google.com/u/1/project/elgio-invited/firestore/databases/-default-/data/~2Fsubsites~2F" + id} 
                target="_blank" rel="noreferrer"
                className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold flex items-center text-center"> 
                    In der Datenbank ansehen
            </a>}
            <div className="bg-gray-500 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold hidden md:block text-gray-500">a</div>
        </div>
        <div className="p-3 overflow-scroll">
            <Section title="Metadaten">
                <div className="flex-col md:flex-row flex my-1">
                    <div className="w-64">ID:</div>
                    <div className="w-64">{subsite?.id}</div>
                </div>
                <div className="flex-col md:flex-row flex my-1">
                    <div className="w-64">Agent:</div>
                    <div className="w-64">{subsite?.data()?.agent}</div>
                </div>
            </Section>
            {
                id && subsite ? <div key={subsite.id}>
                    <Field name="Sichtbarkeit" value={data?.visibility??'public'} type="dropdown" options={['public', 'private', 'blocked']} onChange={(val) => setData({...data, ...{visibility: val}})} />
                    <Field name="Veranstalter" value={data?.organizer} type="organizer" onChange={(val) => setData({...data, organizer: val})} />
                    <Field name="Hintergrundfarbe" value={data?.style?.background_color} onChange={(val) => setData({...data, style: {...(data.style??{}), background_color: val}})} />
                    <Field name="Textfarbe" value={data?.style?.text_color} onChange={(val) => setData({...data, style: {...(data.style??{}), text_color: val}})} />
                    <Field name="Hauptfarbe" value={data?.style?.main_color} onChange={(val) => setData({...data, style: {...(data.style??{}), main_color: val}})} />
                    <Field name="Font Family" value={data?.style?.font_family} onChange={(val) => setData({...data, style: {...(data.style??{}), font_family: val}})} />
                    <Field name="Module" value={data?.modules} type="array-modules" onChange={(val) => setData({...data, modules: val})} />
                </div> : <div className="p-3">
                    Bitte warten...
                </div>
            }
        </div>
    </div>;
}