import {
    Switch,
    Route,
    useRouteMatch,
  } from "react-router-dom";
import Scraper from "./Scraper";
import ScraperLog from "./ScraperLog";
import Sidebar from "./Sidebar";

export default function Scrapers() {
    let match = useRouteMatch();
    
    return <div className="h-full flex w-auto">
        <Sidebar collection="scraper" itemTitle={(item) => item.objectID.split('-')[1]} itemLink={(item) => `/scraper/${item.objectID.split('-')[1]}`} />
        <div className="flex-grow overflow-auto">
            <Switch>
                <Route path={`${match.path}/:id/log/:entry`} component={ScraperLog} />
                <Route path={`${match.path}/:id`} component={Scraper}/>
                <Route path={match.path}>
                    <p className="p-3">
                        Bitte selektiere einen Scraper.
                    </p>
                </Route>
            </Switch>
        </div>
    </div>;
}