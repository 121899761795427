import { useContext, useEffect, useState } from "react";
import { Prompt } from "react-router-dom";
import {
    useHistory,
    useParams
} from "react-router-dom";
import Field from "./Field";
import { firestore } from "./firebase";
import { UserContext } from "./providers/UserProvider";
import { toast } from "react-toastify";


export default function City() {
    const history = useHistory();

    const { role, user } = useContext(UserContext);

    const { id } = useParams();

    const [data, setData] = useState({});
    const [city, setCity] = useState(null);

    const [unsavedChanges, setUnsavedChanges] = useState(false);

    useEffect(() => JSON.stringify(data) !== JSON.stringify(city?.data()) ? setUnsavedChanges(true) : setUnsavedChanges(false), [city, data]);

    useEffect(() => firestore.collection('cities').doc(id).get().then(document => {
        setData(document.data());
        setCity(document);
    }), [id]);

    const save = async () => {
        data.agent = user.uid;
        await firestore.collection('cities').doc(id).set(data, { merge: true });
        setUnsavedChanges(false);
        toast.success("Speichern erfolgreich", {theme: "dark"})
    };

    const remove = async () => {
        if(!window.confirm('Bist du dir sicher, dass du diesen Eintrag löschen willst?')) return;
        await firestore.collection('cities').doc(id).delete();
        toast.success("Löschen erfolgreich", {theme: "dark"})
        history.push('/cities');
    }

    return <div>
        <Prompt message="Einige Änderungen wurden noch nicht gespeichert. Trotzdem fortfahren?" when={unsavedChanges} />
        <div className="bg-gray-500 flex flex-col md:flex-row gap-2 p-4 md:py-0 md:px-2">
            <button className="bg-blue-500 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold text-white" onClick={save}>Änderungen speichern</button>
            <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={remove}>Stadt löschen</button>
            {role === 'admin' && 
            <a href={"https://console.firebase.google.com/u/1/project/elgio-invited/firestore/databases/-default-/data/~2Fcities~2F" + id} 
                target="_blank" rel="noreferrer"
                className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold flex items-center text-center"> 
                    In der Datenbank ansehen
            </a>}
            <div className="bg-gray-500 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold hidden md:block text-gray-500">a</div>
            {role === 'admin' && <div className="ml-auto p-1 grid grid-rows-2 grid-flow-col grid-cols-auto">
                <div>ID:&nbsp;</div>
                <div>Agent:&nbsp;</div>
                <div>{city?.id}</div>
                <div>{city?.data()?.agent}</div>
            </div>}
        </div>
        {
            id && city ? <div className="p-3" key={city.id}>
                <Field name="Name" value={data?.name} onChange={(val) => setData({...data, ...{name: val}})} />
                <Field name="Location" value={data?.location} type="geopoint" onChange={(val) => setData({...data, ...{location: val}})} />
                <Field name="Land (Code)" value={data?.country} onChange={(val) => setData({...data, ...{country: val}})} />
                <Field name="Versteckt?" value={data?.hidden} type="checkbox" onChange={(val) => setData({...data, hidden: val})} />
                <Field name="Coming soon?" value={data?.coming_soon} type="checkbox" onChange={(val) => setData({...data, coming_soon: val})} />
                <Field name="Populäre Venues" value={data?.popular_venues} type="array-organizer" onChange={(val) => setData({...data, popular_venues: val})} />
                <button className="bg-blue-500 text-white rounded px-2 md:px-10 md:py-3 font-semibold" onClick={save}>Änderungen speichern</button>
            </div> : <div className="p-3">
                Bitte warten...
            </div>
        }
    </div>;
}