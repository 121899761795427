import { useContext, useEffect, useMemo, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { firestore, functions, storage } from "./firebase";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { toast } from "react-toastify";
import { UserContext } from "./providers/UserProvider";


export default function EditSuggestion({ collection, recordType }) {
    const ignoreKeys = ['identifier', 'identifiers', 'start', 'end', '_timestamp', 'location', 'agent', 'similar', 'external_interested_counts', 'external_identifers', '_count', '_by', '_data', 'base'];
    const highPriorityKeys = ['name', 'address', 'images'];
    const lowPriorityKeys = ['description'];

    const history = useHistory();
    const location = useLocation();

    const { user } = useContext(UserContext);

    const [queryParams, setQueryParams] = useState({});
    useEffect(() => setQueryParams(queryString.parse(location.search)), [location.search]);

    const { id1, id2 } = useParams();

    const [data1, setData1] = useState(null);
    const [data2, setData2] = useState(null);

    useEffect(() => firestore.collection(collection).doc(id1).get().then(document => setData1(Object.keys(document.data()).reduce((map, key) => {
        map[key] = typeof document.data()[key]?.toDate === 'function' ? document.data()[key].toDate() : document.data()[key];
        return map;
    }, {}))), [id1]);
    
    useEffect(() => firestore.collection(collection).doc(id2).get().then(document => setData2(Object.keys(document.data()??{}).reduce((map, key) => {
        map[key] = typeof document.data()[key]?.toDate === 'function' ? document.data()[key].toDate() : document.data()[key];
        return map;
    }, {}))), [id2]);

    const keys = useMemo(() => {
        if(!data1 || !data2) return null;
        const keys = Object.keys(data2).reduce((arr, key) => {
            if(ignoreKeys.some(e => key.startsWith(e) || key.endsWith(e))) return arr;
            if(data1[key] !== data2[key])
                arr.push(key);
            return arr;
        }, []);
        console.log(keys)
        keys.sort((a, b) => {
            if(highPriorityKeys.includes(a) && !highPriorityKeys.includes(b)) return -1;
            if(highPriorityKeys.includes(b) && !highPriorityKeys.includes(a)) return 1;
            if(highPriorityKeys.includes(a) && highPriorityKeys.includes(b)) return highPriorityKeys.indexOf(a) - highPriorityKeys.indexOf(b);
            if(lowPriorityKeys.includes(a) && !lowPriorityKeys.includes(b)) return 1;
            if(lowPriorityKeys.includes(b) && !lowPriorityKeys.includes(a)) return -1;
            if(lowPriorityKeys.includes(a) && lowPriorityKeys.includes(b)) return lowPriorityKeys.indexOf(b) - lowPriorityKeys.indexOf(a);
            return 0;
        });
        return keys;
    }, [data1, data2]);

    const back = () => {
        if(!queryParams.return) return false;
        history.push(queryParams.return);
        return true;
    }

    const acceptChanges = async () => {
        const fusion = functions.httpsCallable('service-fusion');
        const promise = fusion({
            collection: collection,
            master: id1,
            slave: id2,
            data: Object.fromEntries(keys.map(key => [key, data2[key]])),
        }).catch(e => alert(`Es ist bei der Vereinigung ein Fehler aufgetreten: ${e.message}`));
        if(!back()) {
            await promise;
            toast.success("Vereingung erfolgreich", {theme: "dark"});
            history.push(`/${collection}/${id1}`);
        }
    };

    const saveNew = async () => {
        const promise = firestore.collection(collection).doc(id2).update({
            agent: user.uid,
            visibility: 'public',
            base: null,
        });
        const tasks = await firestore.collection('backlog').where('object', 'array-contains-any', [id1, id2]).where('type', '==', ['check_edited_event', 'check_edited_organizer', 'check_edited_artist']).get();
        await Promise.all(tasks.docs.map(e => e.ref.delete()));
        if(!back()) {
            await promise;
            toast.success("Änderungen erfolgreich übernommen", {theme: "dark"});
            history.push(`/${collection}/${id2}`);
        }
    };

    const advancedFusion = () => {
        if(queryParams.return) {
            history.push(`/${collection}/${id1}/fusion/${id2}?return=${encodeURIComponent(queryParams.return)}`);
        } else {
            history.push(`/${collection}/${id1}/fusion/${id2}`);
        }
    }

    const cancel = async () => {
        if(!back())
            history.push(`/${collection}/${id1}`);
    };

    const areEqual = (a, b) => {
        if(Array.isArray(a) && Array.isArray(b))
            return a.every((e) => b.some((f) => areEqual(e, f))) && b.every((e) => a.some((f) => areEqual(e, f)));
        return a === b;
    };

    return <div>
        <div className="bg-gray-500 flex flex-col md:flex-row gap-2 p-4 md:py-0 md:px-2">
            {id2 && <button className="bg-blue-500 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold text-white" onClick={acceptChanges}>Änderungen annehmen</button>}
            {id2 && <button className="bg-blue-500 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold text-white" onClick={saveNew}>Als neuen Künstler speichern</button>}
            {id2 && <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={advancedFusion}>Erweiterte Fusionierung</button>}
            <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={cancel}>Abbruch</button>
            <div className="bg-gray-500 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold hidden md:block text-gray-500">a</div>
        </div>
        {
            id1 && id2 && data1 && data2 ? <div className="p-3">
                <div className="grid grid-cols-3 mb-3 bg-gray-400">
                    <div>
                        <div className="font-semibold">BASIS</div>
                        <div>{data1.name}</div>
                        {data1.images?.length && <ReferenceImage reference={data1.images[0]} />}
                    </div>
                    <div />
                    <div>
                        <div className="font-semibold">ÄNDERUNGSVORSCHLAG</div>
                        <div>{data2.name}</div>
                        {data2.images?.length && <ReferenceImage reference={data2.images[0]} />}
                    </div>
                </div>
                <div className="font-semibold">Folgendes wurde bearbeitet:</div>
                <div className="grid grid-cols-3">
                    {keys?.map(key => <Row key={key} dataKey={key} data1={data1} data2={data2} />)}
                </div>
            </div> : id1 && !id2 && data1 ? <div className="p-3">
                Du dürftest hier nicht sein.
            </div> : <div className="p-3">
                Bitte warten...
            </div>
        }
    </div>;
}

function ReferenceImage({reference}) {
    const [url, setUrl] = useState('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');

    useEffect(() => reference ? storage.ref(reference).getDownloadURL().then(url => setUrl(url)).catch(_ => setUrl('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==')) : null, [reference]);

    return <img className="h-48 w-auto" src={url} alt="" />;
}

function Row({ dataKey, data1, data2 }) {
    if(
        (typeof data1[dataKey] === 'object' && data1[dataKey] !== null && typeof data1[dataKey].toDate !== 'function' && Object.keys(data1[dataKey]).some(e => isNaN(parseInt(e))))
        || (typeof data2[dataKey] === 'object' && data2[dataKey] !== null && typeof data2[dataKey].toDate !== 'function' && Object.keys(data2[dataKey]).some(e => isNaN(parseInt(e))))
    ) {
        // data is an object with string keys
        const keys = [...new Set([...Object.keys(data1[dataKey]??{}), ...Object.keys(data2[dataKey]??{})])];
        return <>
            {keys.filter(key => data1[key] !== data2[key] && (data1[key] || data2[key])).map(key => {
                const k = `${dataKey}.${key}`;
                return <Row key={k} dataKey={k} data1={data1[dataKey]??{}} data2={data2[dataKey]??{}} />;
            })}
        </>;
    }

    return <>
        {
            dataKey === 'images'
                ? <div className="font-semibold p-1 border-t border-b">{(data1[dataKey]??[]).map((e) => <ReferenceImage reference={e} />)}</div>
                : <div className="overflow-hidden p-1 border-t border-b">{data1[dataKey]?.toLocaleString('de-DE')??data1[dataKey]?.toString()}</div>
        }
        <div className="py-1 px-5 w-100 border-t border-b font-semibold">
            {dataKey}
        </div>
        {
            dataKey === 'images'
                ? <div className="font-semibold p-1 border-t border-b">{(data2[dataKey]??[]).map((e) => <ReferenceImage reference={e} />)}</div>
                : <div className="overflow-hidden p-1 border-t border-b">{data2[dataKey]?.toLocaleString('de-DE')??data1[dataKey]?.toString()}</div>
        }
    </>;
}