import { useContext, useEffect, useState } from "react";
import { Prompt } from "react-router-dom";
import {
    useHistory,
    useParams,
    useLocation,
} from "react-router-dom";
import Field from "./Field";
import { firestore, functions } from "./firebase";
import { UserContext } from "./providers/UserProvider";
import queryString from "query-string";
import { toast } from "react-toastify";


export default function User() {
    const history = useHistory();
    const location = useLocation();

    const [queryParams, setQueryParams] = useState({});
    useEffect(() => setQueryParams(queryString.parse(location.search)), [location.search]);

    const { role, sessionUser } = useContext(UserContext);

    const { id } = useParams();

    const [data, setData] = useState({});
    const [user, setUser] = useState(null);
    const [image, setImage] = useState(null);

    const [unsavedChanges, setUnsavedChanges] = useState(false);

    useEffect(() => JSON.stringify(data) !== JSON.stringify(user?.data()) ? setUnsavedChanges(true) : setUnsavedChanges(false), [data, user]);

    useEffect(() => firestore.collection('users').doc(id).get().then(document => {
        setData(document.data());
        setUser(document);
        setImage(`/profile_images/${document.id}`);
    }), [id]);

    const back = () => {
        if(!queryParams.return) return false;
        history.push(queryParams.return);
        return true;
    }

    const save = async () => {
        data.agent = sessionUser.uid;
        await firestore.collection('users').doc(id).set(data, { merge: true });
        setUnsavedChanges(false);
        toast.success("Speichern erfolgreich", {theme: "dark"})
    };

    const sendVerificationEmail = async () => {
        await functions.httpsCallable('users-sendVerificationEmail')({
            user: id,
        });

        toast.success("E-mail erfolgreich gesendet", {theme: "dark"});

    }

    return <div>
        <Prompt message="Einige Änderungen wurden noch nicht gespeichert. Trotzdem fortfahren?" when={unsavedChanges} />
        <div className="bg-gray-500 flex flex-col md:flex-row gap-2 p-4 md:py-0 md:px-2">
            {queryParams.return && <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={back}>Zurück</button>}
            <button className="bg-blue-500 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold text-white" onClick={save}>Änderungen speichern</button>
            <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={sendVerificationEmail}>Bestätiguns-Email erneut senden</button>
            <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={() => alert('Diese Funktion wurde noch nicht implementiert.')}>User löschen</button>
            {role === 'admin' && 
            <a href={"https://console.firebase.google.com/u/1/project/elgio-invited/firestore/databases/-default-/data/~2Fusers~2F" + id} 
                target="_blank" rel="noreferrer"
                className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold flex items-center text-center"> 
                    In der Datenbank ansehen
            </a>}
            <div className="bg-gray-500 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold hidden md:block text-gray-500">a</div>
            {role === 'admin' && <div className="ml-auto p-1 grid grid-rows-2 grid-flow-col grid-cols-auto">
                <div>ID:&nbsp;</div>
                <div>Agent:&nbsp;</div>
                <div>{user?.id}</div>
                <div>{user?.data()?.agent}</div>
            </div>}
        </div>
        {
            id && user ? <div className="p-3" key={user.id}>
                <Field name="Role (standard, organizer or admin)" value={user.data().role} onChange={(val) => setData({...data, ...{role: val}})} />
                <Field name="Name" value={user.data()?.firstname} onChange={(val) => setData({...data, ...{firstName: val}})} />
                <Field name="Username" value={user.data()?.username} onChange={(val) => setData({...data, ...{username: val}})} />
                <Field name="E-Mail" value={user.data()?.email} type="constant" />
                <Field name="Geburtsdatum" value={user.data()?.date_of_birth} onChange={(val) => setData({...data, ...{date_of_birth: val}})} />
                <Field name="Geschlecht" value={user.data()?.gender} onChange={(val) => setData({...data, ...{gender: val}})} />
                <Field name="Biografie" value={user.data()?.biography} onChange={(val) => setData({...data, ...{biography: val}})} type="multiline" />
                <Field name="Profilbild" value={image} type="image" fileDirectory="/profile_images" fileName={user.id} onChange={(val) => setImage(val)} />
                <button className="bg-blue-500 text-white rounded px-2 md:px-10 md:py-3 font-semibold" onClick={save}>Änderungen speichern</button>
            </div> : <div className="p-3">
                Bitte warten...
            </div>
        }
    </div>;
}