import React, { Component, createContext } from "react";
import { auth, firestore } from "../firebase";

export const UserContext = createContext({
  loaded: false,
  user: null,
  role: null,
});

class UserProvider extends Component {
  state = {
    loaded: false,
    user: null,
    role: null,
  };

  componentDidMount = async () => {
    auth.onAuthStateChanged(async user => {
      if(user) {
        const userDoc = await firestore.collection('users').doc(user.uid).get();
        this.setState({
          loaded: true,
          user: user,
          role: userDoc.data()?.role,
        });
      } else {
        this.setState({
          loaded: true,
          user: null,
          role: null,
        });
      }
    });
  };

  render() {
    return (
      <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
export default UserProvider;