import { useContext, useEffect, useState } from "react";
import { Prompt } from "react-router-dom";
import {
    useHistory,
    useParams
} from "react-router-dom";
import Field from "./Field";
import { firestore } from "./firebase";
import { UserContext } from "./providers/UserProvider";
import { toast } from "react-toastify";


export default function Type() {
    const history = useHistory();

    const { role, user } = useContext(UserContext);

    const { id } = useParams();

    const [data, setData] = useState({});
    const [type, setType] = useState(null);

    const [unsavedChanges, setUnsavedChanges] = useState(false);

    useEffect(() => JSON.stringify(data) !== JSON.stringify(type?.data()) ? setUnsavedChanges(true) : setUnsavedChanges(false), [type, data]);

    useEffect(() => firestore.collection('types').doc(id).get().then(document => {
        setData(document.data());
        setType(document);
    }), [id]);

    const save = async () => {
        data.agent = user.uid;
        await firestore.collection('types').doc(id).set(data, { merge: true });
        setUnsavedChanges(false);
        toast.success("Speichern erfolgreich", {theme: "dark"})

    };

    const remove = async () => {
        if(!window.confirm('Bist du dir sicher, dass du diesen Eintrag löschen willst?')) return;
        await firestore.collection('types').doc(id).delete();
        toast.success("Löschen erfolgreich", {theme: "dark"})
        history.push('/types');
    }

    return <div>
        <Prompt message="Einige Änderungen wurden noch nicht gespeichert. Trotzdem fortfahren?" when={unsavedChanges} />
        <div className="bg-gray-500 flex flex-col md:flex-row gap-2 p-4 md:py-0 md:px-2">
            <button className="bg-blue-500 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold text-white" onClick={save}>Änderungen speichern</button>
            <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={remove}>Type löschen</button>
            {role === 'admin' && 
            <a href={"https://console.firebase.google.com/u/1/project/elgio-invited/firestore/databases/-default-/data/~2Ftypes~2F" + id} 
                target="_blank" rel="noreferrer"
                className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold flex items-center text-center"> 
                    In der Datenbank ansehen
            </a>}
            <div className="bg-gray-500 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold hidden md:block text-gray-500">a</div>
            {role === 'admin' && <div className="ml-auto p-1 grid grid-rows-2 grid-flow-col grid-cols-auto">
                <div>ID:&nbsp;</div>
                <div>Agent:&nbsp;</div>
                <div>{type?.id}</div>
                <div>{type?.data()?.agent}</div>
            </div>}
        </div>
        {
            id && type ? <div className="p-3" key={type.id}>
                <Field name="Deutscher Name" value={type.data()?.de} onChange={(val) => setData({...data, ...{de: val}})} />
                <Field name="Englischer Name" value={type.data()?.en} onChange={(val) => setData({...data, ...{en: val}})} />
                <Field name="Tags (NUR Kleinbuchstaben)" value={type.data()?.tags} type="array-text" onChange={(val) => setData({...data, ...{tags: val}})} />
                <Field name="Bild" value={data?.image} type="image" fileDirectory={'type_images'} onChange={(val) => setData({...data, ...{image: val}})} />
                <button className="bg-blue-500 text-white rounded px-2 md:px-10 md:py-3 font-semibold" onClick={save}>Änderungen speichern</button>
            </div> : <div className="p-3">
                Bitte warten...
            </div>
        }
    </div>;
}