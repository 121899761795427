import { useState } from "react";
import "./statistics_widgets.css";

export default function FurtherInfo({explanation}){
    const [isVisible, setIsVisible] = useState(false);

    return ( 
        <div className="tooltipHidden">
            {isVisible && (
                <div className="tooltipStatistics">
                    <div className="tooltipContainer">{explanation}</div>
                </div>
            )}
            <div
                className="InfoIcon"
                onMouseLeave={() => setIsVisible(false)}
                onMouseEnter={() => setIsVisible(true)}
            >
                i
            </div>
        </div>
    );
}