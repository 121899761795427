import {
    Switch,
    Route,
    useHistory,
    useRouteMatch,
  } from "react-router-dom";
import RecurringEvent from "./RecurringEvent";
import { firestore } from "./firebase";
import Fusion from "./Fusion";
import Sidebar from "./Sidebar";

export default function RecurringEvents() {
    const history = useHistory();

    const newRecurringEvent = () => {
        const reference = firestore.collection('recurring_events').doc();
        history.push(`/recurring-events/${reference.id}`);
    };

    let match = useRouteMatch();

    const weekdays = [
        'Sonntag',
        'Montag',
        'Dienstag',
        'Mittwoch',
        'Donnerstag',
        'Freitag',
        'Samstag',
    ];

    const resolveRepeat = repeat => {
        if(!repeat) return '';
        const parts = [];
        for(const x of Object.keys(repeat)) {
            let part = 'jeden ';
            switch(x) {
                case 'first': part += 'ersten '; break;
                case 'second': part += 'zweiten '; break;
                case 'third': part += 'dritten '; break;
                case 'fourth': part += 'vierten '; break;
                case 'fifth': part += 'fünften '; break;
                case 'last': part += 'letzten '; break;
                default: break;
            }
            part += repeat[x].weekdays.map(weekday => weekdays[weekday]).join(', ')??'';
            parts.push(part);
        }
        return parts.join(', ');
    };
    
    return <div className="h-full flex w-auto">
        <Sidebar newItem={newRecurringEvent} newItemText="Neue Eventreihe" collection="recurring_events" recordType="recurring_event" itemTitle={(item) => item.name} itemSubtitle={(item) => resolveRepeat(item.repeat)} itemLink={(item) => `/recurring-events/${item.objectID.split('-')[1]}`} />
        <div className="flex-grow overflow-auto">
            <Switch>
                <Route path={`${match.path}/:id1/fusion/:id2`}><Fusion collection="recurring_events" recordType="recurring_event" /></Route>
                <Route path={`${match.path}/:id1/fusion`}><Fusion collection="recurring_events" recordType="recurring_event" /></Route>
                <Route path={`${match.path}/:id`} component={RecurringEvent} />
                <Route path={match.path}>
                    <p className="p-3">
                        Bitte selektiere eine Eventreihe.
                    </p>
                </Route>
            </Switch>
        </div>
    </div>;
}