import { useContext, useState } from "react";
import {
    Link,
    Switch,
    Route,
    useRouteMatch,
    useLocation,
    useHistory,
} from "react-router-dom";
import { firestore } from "./firebase";
import Sidebar from "./Sidebar";
import { useEffect } from "react";
import Field from "./Field";
import { index } from "./algolia";
import SalesOrganizer from "./SalesOrganizer";
import { UserContext } from "./providers/UserProvider";

export default function Sales() {
    const { user } = useContext(UserContext);

    const location = useLocation();
    const match = useRouteMatch();
    const history = useHistory();

    const getOrganizerData = async id => {
        const organizerDocRef = firestore.collection('organizers').doc(id);
        const [
            organizerDoc,
            salesDataDoc,
            lastUpdateDoc,
        ] = await Promise.all([
            organizerDocRef.get(),
            organizerDocRef.collection('sales').doc('data').get(),
            organizerDocRef.collection('sales').orderBy('created_timestamp', 'desc').limit(1).get().then(e => e.empty ? null : e.docs[0]),
        ]);
        return {
            id,
            organizer: organizerDoc.data(),
            sales: salesDataDoc.data(),
            lastUpdate: lastUpdateDoc?.data(),
        };
    };

    const resolveStatus = status => {
        switch(status) {
            case 'contacted': return 'Kontaktiert';
            case 'in_communication': return 'In Kontakt';
            case 'communication_ended_success': return 'Positive Resolution';
            case 'communication_ended_failure': return 'Negative Resolution';
            default: return 'Noch keinen Kontakt';
        }
    };

    const createOrganizer = async () => {
        const reference = await firestore.collection('organizers').add({
            agent: user.uid,
            name: search,
        });
        console.log(reference);
        history.push(`/sales/${reference.id}?return=${encodeURIComponent(location.pathname + location.search)}`);
    };

    const [search, setSearch] = useState('');
    const [results, setResults] = useState([]);
    useEffect(() => index.search(search, { filters: `recordType:"organizer"` }).then(({ hits }) => Promise.all(hits.map(hit => getOrganizerData(hit.objectID.split('-')[1])))).then(setResults), [search]);

    const [updates, setUpdates] = useState([]);
    useEffect(() => firestore.collectionGroup('sales').orderBy('created_timestamp', 'desc').get().then(e => Promise.all([...new Set(e.docs.map(e => e.ref.parent.parent.id))].map(e => getOrganizerData(e)))).then(setUpdates), []);

    const [cities, setCities] = useState([]);
    useEffect(() => firestore.collection('cities').get().then(collection => setCities(collection.docs)), []);

    return <div className="h-full flex w-auto">
        <Sidebar defaultOpen={false} />
        <div className="flex-grow overflow-auto">
            <Switch>
                <Route path={`${match.path}/:id`} component={SalesOrganizer}/>
                <Route path={match.path}>
                    <div className="h-screen flex flex-col overflow-hidden">
                        <div className="bg-gray-500 flex flex-col md:flex-row gap-2 p-4 md:py-0 md:px-2">
                            <div className="bg-gray-500 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold hidden md:block text-gray-500">a</div>
                        </div>
                        <div className="p-3 overflow-y-scroll">
                            <div className="flex flex-col md:flex-row h-full auto-cols-auto gap-3">
                                <div className="flex flex-col md:h-full md:w-5/12">
                                    <Field name="Suche eingeben" onChange={(val) => setSearch(val)} />
                                    <div className="flex flex-col gap-1 h-full overflow-y-scroll">
                                        {results.map((e) => e.organizer && <Link 
                                        className={`flex gap-3 rounded-md p-2 ${
                                            e.lastUpdate?.status === 'in_communication' ? 'bg-amber-300' :
                                            e.lastUpdate?.status === 'contacted' ? 'bg-blue-400' :
                                            e.lastUpdate?.status === 'communication_ended_success' ? 'bg-lime-500' :
                                            e.lastUpdate?.status === 'communication_ended_failure' ? 'bg-rose-600' : 'bg-slate-200'
                                          }`}
                                        to={`sales/${e.id}?return=${encodeURIComponent(location.pathname + location.search)}`}>
                                            <div className="w-5/12">{e.organizer.name}</div>
                                            <div className="w-2/12">{e.organizer.city && (cities.find(city => city.id === e.organizer.city)?.data().name??e.organizer.city)}</div>
                                            <div className="w-5/12">{resolveStatus(e.lastUpdate?.status)}</div>
                                        </Link>)}
                                        {search && results.length === 0 && (
                                            <div onClick={createOrganizer} className="bg-green-500 text-white p-2 rounded-md mt-2 text-center cursor-pointer">
                                                {`" ${search}" als neuen Veranstalter hinzufügen`}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="flex flex-col md:h-full md:w-5/12">
                                    <div className="font-semibold">Zuletzt geändert:</div>
                                    <div className="flex flex-col gap-1 h-full overflow-y-scroll">
                                        {updates.map((e) => e.organizer && <Link
                                        className={`flex gap-3 rounded-md p-2 ${
                                            e.lastUpdate?.status === 'in_communication' ? 'bg-amber-300' :
                                            e.lastUpdate?.status === 'contacted' ? 'bg-blue-400' :
                                            e.lastUpdate?.status === 'communication_ended_success' ? 'bg-lime-500' :
                                            e.lastUpdate?.status === 'communication_ended_failure' ? 'bg-rose-600' : 'bg-slate-200'
                                          }`}
                                        to={`sales/${e.id}?return=${encodeURIComponent(location.pathname + location.search)}`}>
                                            <div className="w-5/12">{e.organizer.name}</div>
                                            <div className="w-2/12">{e.organizer.city && (cities.find(city => city.id === e.organizer.city)?.data().name??e.organizer.city)}</div>
                                            <div className="w-5/12">{resolveStatus(e.lastUpdate?.status)}</div>
                                        </Link>)}
                                    </div>
                                </div>
                                <div className="flex flex-col md:h-full md:w-2/12 p-2 border-2 border-slate-500 rounded-md">
                                    <div className="font-semibold">Städte:</div>
                                    <div className="flex flex-col gap-1 h-full overflow-y-scroll">
                                        {cities.map(city => <div>
                                            <Link to={`/sales-city/${city.id}`}>{city.data().name}</Link>
                                        </div>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Route>
            </Switch>
        </div>
    </div>;
}