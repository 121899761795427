import { useState } from "react";
import Field from "./Field";
import { functions } from "./firebase";
import Sidebar from "./Sidebar";

export default function PushNotifications() {
    const [selectRecipientsBy, setSelectRecipientsBy] = useState('all');
    const [cities, setCities] = useState([]);
    const [users, setUsers] = useState([]);

    const [title, setTitle] = useState('');
    const [text, setText] = useState('');

    const [linkTo, setLinkTo] = useState(null);
    const [linkToId, setLinkToId] = useState(null);


    const send = async () => {
        if(selectRecipientsBy === 'city' && cities.length === 0)
            return alert('Bitte wähle mindestens eine Stadt aus');

        if(selectRecipientsBy === 'user' && users.length === 0)
            return alert('Bitte wähle mindestens einen User aus');

        if(!title)
            return alert('Bitte gib einen Titel an');

        if(!text)
            return alert('Bitte gib einen Text an');

        if(linkTo && !linkToId)
            return alert('Bitte gib ein Objekt zum Verlinken an');

        const sendPushNotification = functions.httpsCallable('service-sendPushNotification');
        await sendPushNotification({
            selectRecipientsBy: selectRecipientsBy,
            cities: cities,
            users: users,
            title: title,
            body: text,
            data: {
                ...((linkTo && linkTo !== 'none' && linkToId) ? { [linkTo]: linkToId } : {}),
            },
        });

        alert('Push-Benachrichtigung erfolgreich gesendet');
    };

    return <div className="h-full flex w-auto">
        <Sidebar defaultOpen={false} />
        <div className="flex-grow overflow-auto">
            <div className="h-screen flex flex-col overflow-hidden">
                <div className="bg-gray-500 flex flex-col md:flex-row gap-2 p-4 md:py-0 md:px-2">
                <button className="bg-blue-500 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold text-white" onClick={send}>Push-Benachrichtigung senden</button>
                    <div className="bg-gray-500 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold hidden md:block text-gray-500">a</div>
                </div>
                <div className="p-3">
                    <Field name="Senden an" value={selectRecipientsBy} type="dropdown" options={['all', 'city', 'user']} onChange={setSelectRecipientsBy} />
                    {selectRecipientsBy === 'city' && <Field name="Städte auswählen" value={cities} type="array-city" onChange={setCities} />}
                    {selectRecipientsBy === 'user' && <Field name="User auswählen" value={users} type="array-user" onChange={setUsers} />}
                    <Field name="Titel" value={title} onChange={setTitle} />
                    <Field name="Text" value={text} onChange={setText} />
                    <Field name="Verlinken zu" value={linkTo} type="dropdown" options={['none', 'event', 'organizer', 'user']} onChange={(e) => setLinkTo(e) || setLinkToId(null)} />
                    {linkTo === 'event' && <Field name="Event auswählen" value={linkToId} type="event" onChange={setLinkToId} />}
                    {linkTo === 'organizer' && <Field name="Organizer auswählen" value={linkToId} type="organizer" onChange={setLinkToId} />}
                    {linkTo === 'user' && <Field name="User auswählen" value={linkToId} type="user" onChange={setLinkToId} />}
                </div>
            </div>
        </div>
    </div>;
}