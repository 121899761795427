import 'chart.js/auto';
import { Bar, Doughnut } from "react-chartjs-2";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { firestore } from "./firebase";
import Sidebar from "./Sidebar";

export default function Statistics() {
    const { statistic } = useParams();

    const [documents, setDocuments] = useState([]);
    useEffect(() => firestore.collection(statistic).get().then(collection => setDocuments(collection.docs)), [statistic]);

    const [cities, setCities] = useState([]);
    useEffect(() => firestore.collection('cities').get().then(collection => setCities(collection.docs.map(doc => doc.id))), []);

    const now = new Date();

    const config = {
        events: [
            {
                field: 'city',
                type: 'doughnut',
                name: 'Stadt',
                labels: cities,
            },
        ],
        users: [
            {
                field: 'role',
                type: 'doughnut',
                name: 'Kontotyp',
                labels: ['standard', 'organizer', 'reservation', 'admin', 'partner'],
                colors: [
                    'rgb(54, 162, 235)',
                    'rgb(255, 99, 132)',
                    'rgb(255, 205, 86)',
                    'rgb(162, 205, 99)',
                    'rgb(162, 54, 162)',
                ],
            },
            {   
                field: 'gender',
                type: 'doughnut',
                name: 'Geschlecht',
                labels: ['male', 'female', 'non-binary'],
                colors: [
                    'rgb(54, 162, 235)',
                    'rgb(255, 99, 132)',
                    'rgb(255, 205, 86)',
                ],
            },
            {   
                field: 'city',
                type: 'doughnut',
                name: 'Stadt',
                labels: cities,
            },
            {   
                field: 'date_of_birth',
                type: 'histogram',
                name: 'Alter',
                labels: Array.from({length: 10}, (_, i) => i * 10).map(e => `${e} - ${e+9}`),
                colors: [
                    'rgb(54, 162, 235)',
                ],
                categorize: e => {
                    if(!e) return;
                    const floor = Math.floor((now - new Date(e.split('-')[0], e.split('-')[1], e.split('-')[2]))/315360000000) * 10;
                    return `${floor} - ${floor + 9}`;
                },
            },
        ],
    };
    
    return <div className="h-full flex w-auto">
        <Sidebar defaultOpen={false} />
        <div className="flex-grow overflow-auto">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 m-4">
                {config[statistic].map(e => <GraphBox config={e} documents={documents} />)}
            </div>
        </div>
    </div>;
}

function GraphBox({ config, documents }) {
    return <div className={'border border-black rounded p-2' + (config.type==='histogram' ? ' md:col-span-2' : '')}>
        <div>{config.name}</div>
        <div>{
            config.type === 'doughnut'
            ? <Doughnut data={{labels: [...config.labels, 'unknown'], datasets: [{backgroundColor: [...(config.colors??config.labels.map(label => `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)})`)), 'rgb(0, 0, 0)'], data: [...config.labels.map(label => documents.filter(doc => doc.data()[config.field] === label).length), documents.filter(doc => !config.labels.includes(doc.data()[config.field])).length]}]}} />
            : config.type === 'histogram'
            ? <Bar data={{labels: config.labels, datasets: [{backgroundColor: config.colors, data: config.labels.map(label => documents.filter(doc => config.categorize(doc.data()[config.field]) === label).length)}]}} />
            : <>ERROR: Type not given</>
        }</div>
    </div>;
}