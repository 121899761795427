import {
    Switch,
    Route,
    useHistory,
    useRouteMatch,
  } from "react-router-dom";
import Subsite from "./Subsite";
import Sidebar from "./Sidebar";

export default function Subsites() {
    const history = useHistory();

    const newSubsite = () => {
        const id = prompt('Bitte ID für neue Stadt eingeben. Normalerweise englischer Name in Kleinbuchstaben und zusammengeschriben (z.B. "vienna" oder "cologne").');
        if(id) history.push(`/subsites/${id}`);
    };

    let match = useRouteMatch();
    
    return <div className="h-full flex w-auto">
        <Sidebar newItem={newSubsite} newItemText="Neue Website" collection="subsites" itemTitle={(item) => item.objectID.split('-')[1]} itemLink={(item) => `/subsites/${item.objectID.split('-')[1]}`} />
        <div className="flex-grow overflow-auto">
            <Switch>
                <Route path={`${match.path}/:id`} component={Subsite}/>
                <Route path={match.path}>
                    <p className="p-3">
                        Bitte wähle eine Veranstalter-Website.
                    </p>
                </Route>
            </Switch>
        </div>
    </div>;
}