import { useContext, useEffect, useState, useRef } from "react";
import {
    useHistory,
    useParams,
} from "react-router-dom";
import html2canvas from "html2canvas";
import EventShareBox from "./EventShareBox";
import { firestore } from "./firebase";
import Field from "./Field";
import { UserContext } from "./providers/UserProvider";
import Designs from "./designs/designs";

export default function EventShare() {
    const { role } = useContext(UserContext);

    const history = useHistory();

    const { id } = useParams();

    const shareBoxRef = useRef();

    const [data, setData] = useState(null);

    const [event, setEvent] = useState({});

    const [genres, setGenres] = useState([]);

    useEffect(() => firestore.collection('events').doc(id).get().then(async e => {
        if(!data) {
            setEvent(e);
            let promises = [];
            promises.push(e.data().organizer?.length > 0 ? firestore.collection('organizers').doc(e.data().organizer[0]).get().then(e => e.data().name) : null);
            promises.push(e.data().venue ? firestore.collection('organizers').doc(e.data().venue).get().then(e => e.data().name) : null);
            promises.push(e.data().lineup?.length > 0 ? Promise.all(e.data().lineup.map(e => firestore.collection('artists').doc(e).get().then(e => e.data()?.name))) : null);
            promises.push(fetchGenreNames(e.data().genre));
            const [organizer, venue, lineup, genres] = await Promise.all(promises);
            const price = e.data().price ? 'ab ' + e.data().price.toLocaleString('de-DE', {minimumFractionDigits: 2}) + ' €' : e.data().price === 0 ? 'Kostenlos' : null;
            setData({...e.data(), organizer, venue, lineup, genres, price});
        }
    }, [id]));


    const fetchGenreNames = async (genreIDs) => {
        try {
            const genrePromises = genreIDs.map(async (id) => {
                const doc = await firestore.collection("genres").doc(id).get();
                return doc.data().de;
            });
    
            const genresArr = await Promise.all(genrePromises);
            return genresArr;
        } catch (error) {
            console.error(error);
        }
    }



    const designs = {
        ...(role === 'admin' ? {'ELGIO': Designs.Elgio} : {}),
        'KölnIstTechno': Designs.KoelnIstTechno,
        'KölnIstTechno Invertiert': Designs.KoelnIstTechnoInverted,
    };
    const [design, setDesign] = useState(role === 'admin' ? Designs.Elgio : Designs.KoelnIstTechno);

    const shareImage = async () => {
        // navigator.clipboard.writeText(`https://app.elgio.de/event/${id}`);
        try {
            const element = shareBoxRef.current;
            const canvas = await html2canvas(element, {
                backgroundColor: null,
                height: 1920,
                width: 1080,
                scale: 1,
                onclone: (clonedDoc) => clonedDoc.getElementById('shareBox').style.display = 'block',
                useCORS: true,
            });
            canvas.toBlob((blob) => {
                try {
                    navigator.share({
                        files: [new File([blob], 'event.png', {type: blob.type})],
                    });
                } catch (e) {
                    alert('Fehler: ' + e);
                }
            });
        } catch (e) {
            alert('Fehler: ' + e);
        }
    };

    const saveImage = async () => {
        // navigator.clipboard.writeText(`https://app.elgio.de/event/${id}`);
        try {
            const element = shareBoxRef.current;
            const canvas = await html2canvas(element, {
                backgroundColor: null,
                height: 1920,
                width: 1080,
                scale: 1,
                onclone: (clonedDoc) => clonedDoc.getElementById('shareBox').style.display = 'block',
                useCORS: true,
            });
            canvas.toBlob((blob) => {
                let eventDate = event.data().start.toDate();
                let url = window.URL.createObjectURL(blob);
                let tempLink = document.createElement('a');
                tempLink.href = url;
                tempLink.setAttribute('download', eventDate.getFullYear().toString() + eventDate.getMonth().toString().padStart(2, '0') + eventDate.getDate().toString().padStart(2, '0') + event.data().name.replace(/[^A-Z0-9]+/ig, '').toLowerCase() + '.png');
                tempLink.click();
            });
        } catch (e) {
            alert('Fehler: ' + e);
        }
    };

    return <div className="h-screen flex flex-col">
        <div className="bg-gray-500 flex flex-col md:flex-row gap-2 p-4 md:py-0 md:px-2">
            <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={() => history.goBack()}>Zurück</button>
            <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={shareImage}>Eventgrafik teilen</button>
            <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={saveImage}>Eventgrafik speichern</button>
            <div className="bg-gray-500 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold hidden md:block text-gray-500">a</div>
        </div>
        {
            id && event ? <div className="p-3" key={event.id}>
                <div className="w-full flex flex-col md:flex-row gap-6">
                    <div>
                        <div className="font-bold">Vorschau</div>
                        <div className="event-share-preview">
                            <EventShareBox key={design} data={data} genres={genres} design={design} />
                        </div>
                        <div id="shareBox" ref={shareBoxRef} style={{display: 'none'}}>
                            <EventShareBox key={design} data={data} genres={genres} design={design} />
                        </div>
                    </div>
                    <div>
                        <div className="font-bold">Design</div>
                        <select className="border" onChange={e => setDesign(designs[e.target.value])}>
                            {Object.keys(designs).map(key => <option value={key}>{key}</option>)}
                        </select>
                        {design.colorEditable && <Field name="Farbe" value={design.color} onChange={(color) => setDesign(design.copyWith({ color }))} />}
                        {design.backgroundColorEditable && <Field name="Hintergrundfarbe" value={design.backgroundColor} onChange={(backgroundColor) => setDesign(design.copyWith({ backgroundColor }))} />}
                        <div className="font-bold">Anpassen</div>
                        <Field name="Name" value={data?.name} onChange={(val) => setData({...data, ...{name: val}})} />
                        <Field name="Veranstalter" value={data?.organizer?.length > 0 && data.organizer} onChange={(val) => setData({...data, ...{organizer: val}})} />
                        <Field name="Startzeitpunkt" value={data?.start} type="timestamp" onChange={(val) => setData({...data, start: val})} />
                        <Field name="Venue" value={data?.venue && data.venue} onChange={(val) => setData({...data, ...{venue: val}})} />
                        <Field name="Stadt" value={data?.city} type="city" allowOther={true} onChange={(val) => setData({...data, ...{city: val}})} />
                        <Field name="Preis" value={data?.price} onChange={(val) => setData({...data, ...{price: val}})}/>
                        <Field name="Musikrichtungen" value={data?.genres} type="array-text" onChange={(val) => setData({...data, ...{genres: val}})} />
                        <Field name="Lineup" value={data?.lineup} type="array-text" onChange={(val) => setData({...data, ...{lineup: val}})} />
                        <Field name="Bild" value={data?.images ? data.images[0] : null} type="image" fileDirectory={`event_images/${id}`} onChange={(val) => setData({...data, ...{images: [val]}})} />
                    </div>
                </div>
            </div>  : <div className="p-3">
                Bitte warten...
            </div>
        }
    </div>;
}