import {
    Switch,
    Route,
    useHistory,
    useRouteMatch,
  } from "react-router-dom";
import City from "./City";
import Sidebar from "./Sidebar";

export default function Cities() {
    const history = useHistory();

    const newCity = () => {
        const id = prompt('Bitte ID für neue Stadt eingeben. Normalerweise englischer Name in Kleinbuchstaben und zusammengeschriben (z.B. "vienna" oder "cologne").');
        if(id) history.push(`/cities/${id}`);
    };

    let match = useRouteMatch();
    
    return <div className="h-full flex w-auto">
        <Sidebar newItem={newCity} newItemText="Neue Stadt" collection="cities" itemTitle={(item) => item.name} itemLink={(item) => `/cities/${item.objectID.split('-')[1]}`} />
        <div className="flex-grow overflow-auto">
            <Switch>
                <Route path={`${match.path}/:id`} component={City}/>
                <Route path={match.path}>
                    <p className="p-3">
                        Bitte selektiere eine Stadt.
                    </p>
                </Route>
            </Switch>
        </div>
    </div>;
}