import ElgioDesign from "./elgio";
import KoelnIstTechnoDesign from "./koelnisttechno";

//KölnIstTechno
import kitLogo from "./kit_logo.png";
import kitLogoInverted from "./kit_logo_inverted.png";

export default class Designs {
    static Elgio = new ElgioDesign();
    static KoelnIstTechno = new KoelnIstTechnoDesign({ color: '#fbe627', secondaryColor: 'rgba(156, 163, 175, 255)', backgroundColor: '#000000', logo: kitLogo, colorEditable: true });
    static KoelnIstTechnoInverted = new KoelnIstTechnoDesign({ color: '#000000', secondaryColor: 'black', backgroundColor: '#fbe627', logo: kitLogoInverted, backgroundColorEditable: true });
}