import queryString from "query-string";
import { useContext, useEffect } from "react";
import { useState } from "react";
import {
    useHistory,
    useParams,
    useLocation,
} from "react-router-dom";
import { FieldValue, firestore } from "./firebase";
import Section from "./Section";
import { UserContext } from "./providers/UserProvider";
import Field from "./Field";
import { toast } from "react-toastify";


export default function SalesOrganizer() {
    const history = useHistory();
    const location = useLocation();

    const [queryParams, setQueryParams] = useState({});
    useEffect(() => setQueryParams(queryString.parse(location.search)), [location.search]);

    const { user } = useContext(UserContext);

    const { id } = useParams();

    const [organizerData, setOrganizerData] = useState(null);
    const [organizer, setOrganizer] = useState(null);
    useEffect(() => firestore.collection('organizers').doc(id).get().then(document => {
        setOrganizerData(document.data());
        setOrganizer(document);
    }), [id]);

    const [salesDataData, setSalesDataData] = useState(null);
    const [salesData, setSalesData] = useState(null);
    useEffect(() => firestore.collection('organizers').doc(id).collection('sales').doc('data').get().then(document => {
        setSalesDataData(document.data());
        setSalesData(document);
    }), [id]);

    const [city, setCity] = useState(null);
    useEffect(() => organizer?.data().city && firestore.collection('cities').doc(organizer.data().city).get().then(e => setCity(e.data()?.name??organizer.data().city)), [organizer]);

    const [newUpdate, setNewUpdate] = useState({ status: 'contacted' });

    const [updates, setUpdates] = useState([]);
    useEffect(() => firestore.collection('organizers').doc(id).collection('sales').orderBy('created_timestamp', 'desc').onSnapshot(snap => setUpdates(snap.docs)), [id])

    const back = () => {
        if(!queryParams.return) return false;
        history.push(queryParams.return);
        return true;
    }

    // const saveOrganizer = async () => {
    //     organizerData.agent = user.uid;
    //     await organizer.ref.set(organizerData, { merge: true });
    //     setOrganizer(await organizer.ref.get());
    //     toast.success("Speichern erfolgreich", {theme: "dark"})

    // };

    const saveSalesData = async () => {
        salesDataData.agent = user.uid;
        await salesData.ref.set(salesDataData, { merge: true });
        setSalesData(await salesData.ref.get());
        toast.success("Speichern erfolgreich", {theme: "dark"})

    };

    const addNewUpdate = async () => {
        newUpdate.agent = user.uid;
        newUpdate.created_timestamp = FieldValue.serverTimestamp();
        await organizer.ref.collection('sales').add(newUpdate);
        setNewUpdate({ status: 'contacted' });
        back();
        toast.success("Update erfolgreich hinzugefügt", {theme: "dark"})

    };

    return <div>
        <div className="bg-gray-500 flex flex-col md:flex-row gap-2 p-4 md:py-0 md:px-2">
            {queryParams.return && <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={back}>Zurück</button>}
            <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={() => history.push(`/organizers/${id}?return=${encodeURIComponent(location.pathname + location.search)}`)}>Datenbank-Eintrag anzeigen</button>
            <div className="bg-gray-500 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold hidden md:block text-gray-500">a</div>
        </div>
        {
            id && organizer && salesData ? <div className="p-3 overflow-y-scroll">
                <Section title="Daten" openDefault>
                    <div className="flex-col md:flex-row flex my-1">
                        <div className="w-64">Name:</div>
                        <div className="w-64">{organizer.data().name}</div>
                    </div>
                    <div className="flex-col md:flex-row flex my-1">
                        <div className="w-64">Typ:</div>
                        <div className="w-64">{organizer.data().type === 'venue' ? 'Location' : 'Veranstalter'}</div>
                    </div>
                    <div className="flex-col md:flex-row flex my-1">
                        <div className="w-64">Stadt:</div>
                        <div className="w-64">{city}</div>
                    </div>
                    <div className="flex-col md:flex-row flex my-1">
                        <div className="w-64">Status:</div>
                        <div className="w-64">{updates.length > 0 ? updates[0].data().status : 'Noch kein Kontakt'}</div>
                    </div>
                </Section>
                <Section title="Kontaktdaten">
                    <div className="mb-3">Hinweis: Änderungen werden nicht im öffentlichen Eintrag angepasst.</div>
                    <Field name="E-Mail-Adresse" value={salesDataData?.email??organizerData?.email} onChange={(val) => setSalesDataData({...salesDataData, ...{email: val}})} />
                    <Field name="Telefonnummer" value={salesDataData?.phone??organizerData?.phone} onChange={(val) => setSalesDataData({...salesDataData, ...{phone: val}})} />
                    {(
                        (salesDataData?.email??organizerData?.email) !== (salesData.data()?.email??organizer.data().email)
                        || (salesDataData?.phone??organizerData?.phone) !== (salesData.data()?.phone??organizer.data().phone)
                    ) && <button onClick={saveSalesData} className="bg-blue-500 text-white rounded px-2 py-3 font-semibold">Änderungen speichern</button>}
                </Section>
                <Section title="Update hinzufügen" openDefault>
                    <div className="font-semibold mb-3">Update hinzufügen:</div>
                    <Field name="Neuer Status" value={newUpdate.status} type="dropdown" options={['contacted', 'in_communication', 'communication_ended_success', 'communication_ended_failure']} onChange={val => setNewUpdate({...newUpdate, status: val})} />
                    <Field name="Notizen" value={newUpdate.comment??''} type="multiline" onChange={val => setNewUpdate({...newUpdate, comment: val})} />
                    {newUpdate.status && <button onClick={addNewUpdate} className="bg-blue-500 text-white rounded px-2 py-3 font-semibold">Update hinzufügen</button>}
                </Section>
                <Section title="Updates" openDefault>
                    <div className="flex flex-col gap-3">
                        {updates.map(update => <div className="border rounded p-3">
                            <div className="flex-col md:flex-row flex my-1">
                                <div className="w-64">Zeitpunkt:</div>
                                <div className="w-64">{update.data().created_timestamp?.toDate()?.toLocaleString('de-DE')}</div>
                            </div>
                            <div className="flex-col md:flex-row flex my-1">
                                <div className="w-64">Neuer Status:</div>
                                <div className="w-64">{update.data().status}</div>
                            </div>
                            <div className="flex-col md:flex-row flex my-1">
                                <div className="w-64">Notizen:</div>
                                <div className="w-64">{update.data().comment??'-'}</div>
                            </div>
                        </div>)}
                    </div>
                </Section>
            </div> : <div className="p-3">
                Bitte warten...
            </div>
        }
    </div>;
}