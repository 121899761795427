import React, { useEffect, useState } from 'react'
import "./statistics.css";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Title,
  Filler,
  ArcElement,
  BarElement
} from 'chart.js';
import { useContext } from 'react'
import { StatsContext } from '../providers/StatsProvider.jsx'
import PieChart from '../widgets/statistics_widgets/pieChart.jsx';
import BarChart from '../widgets/statistics_widgets/barChart.jsx';
import LineGraph from '../widgets/statistics_widgets/lineGraph.jsx';
import LineChart from '../widgets/statistics_widgets/lineChart.jsx';
import FurtherInfo from "../widgets/statistics_widgets/tooltip.jsx";


export default function StatsGeneral(){
  const [statisticsState,setStatisticState] = useState(2)
  const [activeStatisticsFirstRow,setActiveStatisticsFirstRow] = useState({})
  const [activeStatisticsSecondRow,setActiveStatisticsSecondRow] = useState({})
  const [activeStatisticsThirdRow,setActiveStatisticsThirdRow] = useState({})
  const [dataToday,setDataToday] = useState([])
  const [dataWeek,setDataWeek] = useState([])
  const [dataMonth,setDataMonth] = useState([])
  const [dataYesterday,setDataYesterday] = useState([])

  useEffect(() => {
      if(statisticsState === 0){
        setTimeframe('today');
        setActiveStatisticsFirstRow(dataToday[0])
        setActiveStatisticsSecondRow(dataToday[1])
        setActiveStatisticsThirdRow(dataToday[2])
      }
      else if(statisticsState === 1){
        setTimeframe('yesterday')
        setActiveStatisticsFirstRow(dataYesterday[0])
        setActiveStatisticsSecondRow(dataYesterday[1])
        setActiveStatisticsThirdRow(dataYesterday[2])
      }
      else if(statisticsState === 2){
        setTimeframe('week');        
        setActiveStatisticsFirstRow(dataWeek[0])
        setActiveStatisticsSecondRow(dataWeek[1])
        setActiveStatisticsThirdRow(dataWeek[2])
      }
      else{
        setTimeframe('last_30_days');
        setActiveStatisticsFirstRow(dataMonth[0])
        setActiveStatisticsSecondRow(dataMonth[1])
        setActiveStatisticsThirdRow(dataMonth[2])
      }
  },[statisticsState])
    
    const {
        getNewUser,
        getUniqueTraffic,
        getTimeSpent,
        getRetentionRate,
        getCrashes,
        getDownloads,
        getEventViews,
        getSaves,
        getShares
    } = useContext(StatsContext);

    //Use setTimeframe to set timeframe for all statistics
    const [timeframe, setTimeframe] = useState('today');

    // //Usage and Performance
    // const [newUser, newUserSum, newUserLabels] = getNewUser({ timeframe });
    // const [uniqueTraffic, uniqueTrafficSum, uniqueTrafficLabels] = getUniqueTraffic({ timeframe });
    // const [timeSpent, timeSpentSum, timeSpentLabels] = getTimeSpent({ timeframe });
    // const [retentionRate, retentionRateSum, retentionRateLabels] = getRetentionRate({ timeframe });
    // const [crashes, crashesSum, crashesLabels] = getCrashes({ timeframe });
    // const [downloads, downloadsSum, downloadsLabels] = getDownloads({ timeframe });
    
    // //Interactions
    // const [eventViews, eventViewsSum, eventViewsLabels] = getEventViews({ timeframe });
    // const [saves, savesSum, savesLabels] = getSaves({ timeframe });
    // const [shares, sharesSum, sharesLabels] = getShares({ timeframe });
    

    ChartJS.register(
        LineElement,
        CategoryScale,
        LinearScale,
        PointElement,
        Tooltip,
        Title,
        ArcElement,
        BarElement,
        Filler
    )

    const TestData5 = [
        54, 35, 25, 28, 98,
    ]

    const TestData1 = [
        54
    ]

    const TestData3 = [
        54
    ]

    return(
        <div className="content">
            <div className="PageHeaderBox">
                <div>
                <div className="PageHeaderBoxTitle">General</div>
                </div> 
                <div className="DatePickerContainer">
                <div className="pointer"
                onClick={() => {
                    if(statisticsState === 0)
                    {setStatisticState(3)}
                    else{
                    setStatisticState(statisticsState - 1 )}
                }}>left</div>
                <div className="DatePicker">{statisticsState === 0 ? "Heute" : statisticsState === 1 ? "Gestern" : statisticsState === 2 ? "Letzte 7 Tage" :  "Letzte 30 Tage"}</div> 
                <div className="pointer"
                    onClick={() => {
                    if(statisticsState === 3)
                        {setStatisticState(0)}
                    else{
                        setStatisticState(statisticsState + 1 )}
                }}>right</div>
                </div>
            </div>
            <div>
                <div className='statsSectionTitle'>Metrics</div>
                <div className='statsRow'>
                    <div className="StatisticItemBox">
                        <div className='StatisticItemBoxHeader'>
                            <div className='newBox'>
                                <div className="StatisticHeader">New Registered Users</div>
                                <div className="StatisticNumber">{TestData1}</div>
                            </div>
                            <FurtherInfo 
                                explanation="Wie viele neue Registrierte User auf der App"
                            />
                        </div>
                        <LineGraph data={TestData5} labels={TestData3} colors={["#0d6efd", "#0d6efd", "#0d6efd"]}/>
                    </div>
                    <div className="StatisticItemBox">
                        <div className='StatisticItemBoxHeader'>
                            <div className='newBox'>
                                <div className="StatisticHeader">Active Users on App + Webapp</div>
                                <div className="StatisticNumber">{TestData1}</div>
                            </div>
                            <FurtherInfo 
                                explanation="Wie viele Leute waren auf App + Webapp"
                            />
                        </div>
                        <LineGraph data={TestData5} labels={TestData3} colors={["#0d6efd", "#0d6efd", "#0d6efd"]}/>
                    </div>
                    <div className="StatisticItemBox">
                        <div className='StatisticItemBoxHeader'>
                            <div className='newBox'>
                                <div className="StatisticHeader">Downloads (iOS + Android)</div>
                                <div className="StatisticNumber">{TestData1}</div>
                            </div>
                            <FurtherInfo 
                                explanation="Wie viele Leute haben sich die App runtergeladen"
                            />
                        </div>
                        <LineGraph data={TestData5} labels={TestData3} colors={["#0d6efd", "#0d6efd", "#0d6efd"]}/>
                    </div>
                    <div className="StatisticItemBox">
                        <div className='StatisticItemBoxHeader'>
                            <div className='newBox'>
                                <div className="StatisticHeader">Time Spent per Session</div>
                                <div className="StatisticNumber">{TestData1}</div>
                            </div>
                            <FurtherInfo 
                                explanation="Wie lange war im Durchschnitt eine Session"
                            />
                        </div>
                        <LineGraph data={TestData5} labels={TestData3} colors={["#0d6efd", "#0d6efd", "#0d6efd"]}/>
                    </div>
                    <div className="StatisticItemBox">
                        <div className='StatisticItemBoxHeader'>
                            <div className='newBox'>
                                <div className="StatisticHeader">User Retention</div>
                                <div className="StatisticNumber">{TestData1}</div>
                            </div>
                            <FurtherInfo 
                                explanation="Können wir Kunden halten? Verlieren wir sie wieder?"
                            />
                        </div>
                        <BarChart data={TestData5} labels={TestData3} colors={["#0d6efd", "#0d6efd", "#0d6efd"]}/>
                    </div>
                    <div className="StatisticItemBox">
                        <div className='StatisticItemBoxHeader'>
                            <div className='newBox'>
                                <div className="StatisticHeader">Total Crashes</div>
                                <div className="StatisticNumber">{TestData1}</div>
                            </div>
                            <FurtherInfo 
                                explanation="Wie viele Crashes haben wir gehabt"
                            />
                        </div>
                        <BarChart data={TestData5} labels={TestData3} colors={["#0d6efd", "#0d6efd", "#0d6efd"]}/>
                    </div>
                </div>
            </div>
            <div>
                <div className='statsSectionTitle'>Interactions</div>
                <div className='statsRow'>
                    <div className="StatisticItemBox">
                        <div className='StatisticItemBoxHeader'>
                            <div className='newBox'>
                                <div className="StatisticHeader">Event Views</div>
                                <div className="StatisticNumber">{TestData1}</div>
                            </div>
                            <FurtherInfo 
                                explanation="Wie viele Total Event Views hatten wir"
                            />
                        </div>
                        <LineGraph data={TestData5} labels={TestData3} colors={["#0d6efd", "#0d6efd", "#0d6efd"]}/>
                    </div>
                    <div className="StatisticItemBox">
                        <div className='StatisticItemBoxHeader'>
                            <div className='newBox'>
                                <div className="StatisticHeader">Saves</div>
                                <div className="StatisticNumber">{TestData1}</div>
                            </div>
                            <FurtherInfo 
                                explanation="wie viele total Merkungen hatten wir"
                            />
                        </div>
                        <LineGraph data={TestData5} labels={TestData3} colors={["#0d6efd", "#0d6efd", "#0d6efd"]}/>
                    </div>
                    <div className="StatisticItemBox">
                        <div className='StatisticItemBoxHeader'>
                            <div className='newBox'>
                                <div className="StatisticHeader">Shares</div>
                                <div className="StatisticNumber">{TestData1}</div>
                            </div>
                            <FurtherInfo 
                                explanation="Wie viele Leute haben sich die App runtergeladen"
                            />
                        </div>
                        <LineGraph data={TestData5} labels={TestData3} colors={["#0d6efd", "#0d6efd", "#0d6efd"]}/>
                    </div>
                </div>
            </div>
        </div>
    )
}