import React, { createContext, useContext } from "react";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { firestore } from "../firebase";
import { UserContext } from "./UserProvider";
import { useEffect } from "react";
import { useState } from "react";


function generateIntervalParams(timeframe) {
    const startOfDay = new Date();
    startOfDay.setHours(0, 0, 0, 0);
    const now = Date.now();

    let interval, width, start, end, pointCount, labels;

    switch (timeframe) {
        case 'today':
            interval = 'hour';
            width = 60 * 60 * 1000;
            start = startOfDay.getTime();
            end = Date.now();
            pointCount = (new Date()).getHours() + 1;
            labels = Array.from(Array(24).keys()).map(e => e.toString().padStart(2, '0') + ':00');
            break;
        case 'yesterday':
            interval = 'hour';
            width = 60 * 60 * 1000;
            start = startOfDay - 24 * 60 * 60 * 1000;
            end = startOfDay;
            pointCount = 24;
            labels = Array.from(Array(24).keys()).map(e => e.toString().padStart(2, '0') + ':00');
            break;
        case 'week':
            interval = 'day';
            width = 24 * 60 * 60 * 1000;
            start = startOfDay - 6 * 24 * 60 * 60 * 1000;
            end = Date.now();
            pointCount = 7;
            labels = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
            for(let i = 0; i < new Date(start).getDay(); i++) {
                let [removed, ...rest] = labels;
                labels = [...rest, removed];
            }
            break;
        case 'last_30_days':
            interval = 'day';
            width = 24 * 60 * 60 * 1000;
            start = startOfDay - 29 * 24 * 60 * 60 * 1000;
            end = Date.now();
            pointCount = 30;
            labels = Array.from(Array(30).keys()).map(e => {
                const date = new Date(start + e * 24 * 60 * 60 * 1000);
                return `${date.getDate()}.${date.getMonth()+1}.`;
            });
            break;
        default:
            interval = 'day';
            width = 24 * 60 * 60 * 1000;
            start = new Date(0);
            end = Date.now();
            pointCount = 30;
            labels = Array.from(Array(30).keys()).map(e => {
                const date = new Date(start + e * 24 * 60 * 60 * 1000);
                return `${date.getDate()}.${date.getMonth()+1}.`;
            });
            break;
    }

    return { interval, width, start, end, pointCount, labels };
}

export const StatsContext = createContext({
    loaded: false,
    getTicketSales: null,
    getProfileViews: null,
    getEventViews: null,
    getEventShares: null,
    getEventBookmarks: null,
    getCustomerStatuses: null,
    getCustomerAges: null,
    getCustomerGenders: null,
    getCustomerClubs: null,
    getCustomerOrigin: null,
    getCustomerGenres: null,
});

export default function StatsProvider(props) {
    const { organizerId } = useContext(UserContext);

    const [tickets, setTickets] = useState(null);
    const [genres, setGenres] = useState(null);
    const [eventActivities, setEventActivities] = useState(null);
    const [profileActivities, setProfileActivities] = useState(null);

    useEffect(() => {
        if (organizerId) {
            const unsubscribeTickets = onSnapshot(
                query(
                    collection(firestore, 'tickets'),
                    where('confirmed_timestamp', '>', new Date(Date.now() - 365 * 24 * 60 * 60 * 1000)),
                    where('seller', '==', organizerId),
                ),
                (snap) => setTickets(snap.docs),
            );

            const unsubscribeGenres = onSnapshot(
                query(
                    collection(firestore, 'genres'),
                ),
                (snap) => setGenres(snap.docs),
            );

            const unsubscribeEventActivities = onSnapshot(
                query(
                    collection(firestore, 'aggregated_activities'),
                    where('timestamp', '>', new Date(Date.now() - 365 * 24 * 60 * 60 * 1000)),
                    where('organizer', 'array-contains', organizerId),
                    where('type', 'in', ['event_view', 'event_share', 'bookmark', 'attending']),
                ),
                (snap) => setEventActivities(snap.docs),
            );

            const unsubscribeProfileActivities = onSnapshot(
                query(
                    collection(firestore, 'aggregated_activities'),
                    where('timestamp', '>', new Date(Date.now() - 365 * 24 * 60 * 60 * 1000)),
                    where('organizer', '==', organizerId),
                    where('type', 'in', ['organizer_view', 'organizer_share']),
                ),
                (snap) => setProfileActivities(snap.docs),
            );

            return () => {
                unsubscribeTickets();
                unsubscribeGenres();
                unsubscribeEventActivities();
                unsubscribeProfileActivities();
            };
        }
    }, [organizerId]);

    function getTicketSales({ timeframe, event }) {
        if(!loaded) return [[], [], 0, []];
        const { start, end, pointCount, width } = generateIntervalParams(timeframe);
        
        var sum = 0;
        var counts = Array(pointCount).fill(0);
        var sums = Array(pointCount).fill(0);
        tickets
            .filter(e => !event || e.data().event === event)
            .filter(e => e.data().confirmed_timestamp.toMillis() >= start)
            .filter(e => e.data().confirmed_timestamp.toMillis() < end)
            .forEach(e => {
                sum += e.data().price / 100;
                let index = Math.floor((e.data().confirmed_timestamp.toMillis() - start) / width);
                counts[index]++;
                sums[index] += e.data().price / 100;
            });

        return [counts, sums, sum];
    }

    //Event activities

    function getEventActivities({ action, timeframe, event }) {
        if(!loaded) return [[], 0, []];
        const { start, end, pointCount, interval, width, labels } = generateIntervalParams(timeframe);

        var sum = 0;
        var sums = Array(pointCount).fill(0);
        eventActivities
            .filter(e => e.data().interval === interval)
            .filter(e => e.data().type === action)
            .filter(e => !event || e.data().event === event)
            .filter(e => e.data().timestamp.toMillis() >= start)
            .filter(e => e.data().timestamp.toMillis() < end)
            .forEach(e => {
                sum += e.data().count;
                let index = Math.floor((e.data().timestamp.toMillis() - start) / width);
                sums[index] += e.data().count;
            });

        return [sums, sum, labels];
    }

    //Profile activities

    function getProfileActivities({ action, timeframe }) {
        if(!loaded) return [[], 0, []];
        const { start, end, pointCount, interval, width, labels } = generateIntervalParams(timeframe);

        var sum = 0;
        var sums = Array(pointCount).fill(0);
        profileActivities
            .filter(e => e.data().interval === interval)
            .filter(e => e.data().type === action)
            .filter(e => e.data().timestamp.toMillis() >= start)
            .filter(e => e.data().timestamp.toMillis() < end)
            .forEach(e => {
                sum += e.data().count;
                let index = Math.floor((e.data().timestamp.toMillis() - start) / width);
                sums[index] += e.data().count;
            });

        return [sums, sum, labels];
    }

    //Customer base
    function getCustomerStatuses({ timeframe, event }) {
        if(!loaded) return [[], []];
        const { start, end } = generateIntervalParams(timeframe);

        var ranges = [0, 1];
        var labels = ['Neukunde', 'Bestandskunde'];

        var statuses = Array(ranges.length).fill(0);
        [...eventActivities, ...profileActivities]
            .filter(e => e.data().interval === 'day')
            .filter(e => ['attending'].includes(e.data().type))
            .filter(e => !event || e.data().event === event)
            .filter(e => e.data().timestamp.toMillis() >= start)
            .filter(e => e.data().timestamp.toMillis() < end)
            .forEach(e => {
                Object.entries((e.data().previous_activity_count[organizerId]??{})).forEach(e => {
                    let index = ranges.findIndex((range, index) => range <= e[0] && (index + 1 === ranges.length || ranges[index + 1] > e[0]));
                    statuses[index] += e[1];
                });
            });

        return [statuses, labels];
    }

    function getCustomerAges({ timeframe, event }) {
        if(!loaded) return [[], []];
        const { start, end } = generateIntervalParams(timeframe);


        var ranges = [16, 18, 22, 26, 30, 36, 41];
        var labels = ranges.map((range, index) => range.toString() + (index + 1 === ranges.length ? '+' : '-' + ranges[index + 1].toString()));

        var ages = Array(ranges.length).fill(0);
        [...eventActivities, ...profileActivities]
            .filter(e => e.data().interval === 'day')
            .filter(e => ['attending', 'bookmark', 'event_share', 'organizer_share'].includes(e.data().type))
            .filter(e => !event || e.data().event === event)
            .filter(e => e.data().timestamp.toMillis() >= start)
            .filter(e => e.data().timestamp.toMillis() < end)
            .forEach(e => {
                Object.entries((e.data().age??{})).forEach(e => {
                    let index = ranges.findIndex((range, index) => range <= e[0] && (index + 1 === ranges.length || ranges[index + 1] > e[0]));
                    ages[index] += e[1];
                });
            });

        return [ages, labels];
    }

    function getCustomerGenders({ timeframe, event }) {
        if(!loaded) return [[], []];
        const { start, end } = generateIntervalParams(timeframe);

        var categories = ['male', 'female', 'non-binary'];
        var labels = ['Männlich', 'Weiblich', 'Divers'];

        var genders = Array(categories.length).fill(0);
        [...eventActivities, ...profileActivities]
            .filter(e => e.data().interval === 'day')
            .filter(e => ['attending', 'bookmark', 'event_share', 'organizer_share'].includes(e.data().type))
            .filter(e => !event || e.data().event === event)
            .filter(e => e.data().timestamp.toMillis() >= start)
            .filter(e => e.data().timestamp.toMillis() < end)
            .forEach(e => {
                Object.entries((e.data().gender??{})).forEach(e => {
                    let index = categories.findIndex(c => c === e[0]);
                    genders[index] += e[1];
                });
            });

        return [genders, labels];
    }
    
    function getCustomerGenres({ timeframe, event }) {
        if(!loaded) return [[], []];
        const { start, end } = generateIntervalParams(timeframe);

        var categories = genres.map(e => e.id);
        var labels = genres.map(e => e.data().de);

        var g = Array(categories.length).fill(0);
        [...eventActivities, ...profileActivities]
            .filter(e => e.data().interval === 'day')
            .filter(e => ['attending', 'bookmark', 'event_share', 'organizer_share'].includes(e.data().type))
            .filter(e => !event || e.data().event === event)
            .filter(e => e.data().timestamp.toMillis() >= start)
            .filter(e => e.data().timestamp.toMillis() < end)
            .forEach(e => {
                Object.entries((e.data().genres_interested_in??{})).forEach(e => {
                    let index = categories.findIndex(c => c === e[0]);
                    g[index] += e[1];
                });
            });

        labels.sort((a, b) => g[labels.indexOf(b)] - g[labels.indexOf(a)]);
        labels = labels.slice(0, 5);
        g.sort((a, b) => b - a);
        g = g.slice(0, 5);

        return [g, labels];
    }

    function getCustomerClubs({ timeframe, event }) {
        if(!loaded) return [[], []];
        const { start, end, pointCount } = generateIntervalParams(timeframe);

        var categories = ['schrotty', 'bootshaus', 'elektroküche'];
        var labels = ['Schrotty', 'Bootshaus', 'Elektroküche'];

        var clubs = Array(categories.length).fill(0);
        [...eventActivities, ...profileActivities]
            .filter(e => e.data().interval === 'day')
            .filter(e => ['attending', 'bookmark', 'event_share', 'organizer_share'].includes(e.data().type))
            .filter(e => !event || e.data().event === event)
            .filter(e => e.data().timestamp.toMillis() >= start)
            .filter(e => e.data().timestamp.toMillis() < end)
            .forEach(e => {
                Object.entries((e.data().club??{})).forEach(e => {
                    let index = categories.findIndex(c => c === e[0]);
                    clubs[index] += e[1];
                });
            });

        return [clubs, labels];
    }

    function getCustomerOrigin({ timeframe, event }) {
        if(!loaded) return [[], []];
        const { start, end, pointCount } = generateIntervalParams(timeframe);

        
        var categories = ['cologne', 'bonn', 'dusseldorf', 'aachen'];
        var labels = ['Köln', 'Bonn', 'Düsseldorf'];

        var origins = Array(categories.length).fill(0);
        [...eventActivities, ...profileActivities]
            .filter(e => e.data().interval === 'day')
            .filter(e => ['attending', 'bookmark', 'event_share', 'organizer_share'].includes(e.data().type))
            .filter(e => !event || e.data().event === event)
            .filter(e => e.data().timestamp.toMillis() >= start)
            .filter(e => e.data().timestamp.toMillis() < end)
            .forEach(e => {
                Object.entries((e.data().origin??{})).forEach(e => {
                    let index = categories.findIndex(c => c === e[0]);
                    origins[index] += e[1];
                });
            });

        return [origins, labels];
    }

    //Loaded
    const [loaded, setLoaded] = useState(false);
    useEffect(() => { setLoaded(tickets !== null && eventActivities !== null && profileActivities !== null && genres !== null) }, [tickets, eventActivities, profileActivities, genres]);

    const getEventViews = ({ timeframe, event }) => getEventActivities({ action: 'event_view', timeframe, event });
    const getEventShares = ({ timeframe, event }) => getEventActivities({ action: 'event_share', timeframe, event });
    const getEventBookmarks = ({ timeframe, event }) => getEventActivities({ action: 'bookmark', timeframe, event });
    const getProfileViews = ({ timeframe }) => getProfileActivities({ action: 'organizer_view', timeframe });
    const getProfileShares = ({ timeframe }) => getProfileActivities({ action: 'organizer_share', timeframe });

    return (
        <StatsContext.Provider 
            value={{
                loaded,
                getTicketSales,
                getEventViews,
                getEventShares,
                getEventBookmarks,
                getProfileViews,
                getProfileShares,
                getCustomerStatuses,
                getCustomerAges,
                getCustomerGenders,
                getCustomerClubs,
                getCustomerOrigin,
                getCustomerGenres,
            }}
        >
            {props.children}
        </StatsContext.Provider>
    );
}