import { useContext, useEffect, useRef, useState } from "react";
import {
    useHistory,
    useParams,
    useLocation
} from "react-router-dom";
import Field from "./Field";
import { firestore } from "./firebase";
import { Prompt } from "react-router-dom";
import { UserContext } from "./providers/UserProvider";
import Section from "./Section";
import queryString from "query-string";
import blocklist from "./blocklist/words.json";

export default function RecurringEvent() {
    const ref = useRef();

    const history = useHistory();
    const location = useLocation();

    const { role, user } = useContext(UserContext);

    const [queryParams, setQueryParams] = useState({});
    useEffect(() => setQueryParams(queryString.parse(location.search)), [location.search]);

    const { id } = useParams();

    const [data, setData] = useState({});
    const [recurringEvent, setRecurringEvent] = useState(null);

    const [unsavedChanges, setUnsavedChanges] = useState(false);

    const [openEnd, setOpenEnd] = useState(true);

    const [address, setAddress] = useState(null);
    const [postalCode, setPostalCode] = useState(null);
    const [city, setCity] = useState(null);

    const [activities24Hours, setActivities24Hours] = useState([]);
    const [activities7Days, setActivities7Days] = useState([]);

    useEffect(() => firestore.collection('recurring_events').doc(id).get().then(document => {
        if(document.exists) {
            setData({...document.data(), organizer: document.data().organizer?.filter(e => e !== document.data().venue)??[]});
        } else {
            setData({});
        }
        setRecurringEvent(document);
        ref.current.scrollTop = 0;
    }), [id]);

    useEffect(() => {
        setOpenEnd(recurringEvent?.data()?.start?.seconds === recurringEvent?.data()?.end?.seconds);
        const splitAddress = recurringEvent?.data()?.address?.split(', ');
        setAddress(splitAddress?.length > 0 ? splitAddress[0] : '');
        setPostalCode(splitAddress?.length > 0 ? splitAddress[1] : '');
        setCity(recurringEvent?.data()?.city??(splitAddress?.length > 0 ? splitAddress[2] : ''));
    }, [recurringEvent]);

    //TODO: fix this mess
    //useEffect(() => JSON.stringify(Object.assign({}, data, {address: undefined, venue: undefined})) !== JSON.stringify(Object.assign({}, event?.data(), {address: undefined, venue: undefined})) ? setUnsavedChanges(true) : setUnsavedChanges(false), [data, event]);

    useEffect(() => recurringEvent && data?.venue && recurringEvent?.data()?.venue !== data?.venue ? firestore.collection('organizers').doc(data.venue).get().then(document => {
        if(document.data()?.address) {
            setAddress(document.data().address?.split(', ')[0]);
            setPostalCode(document.data().address?.split(', ')[1]);
        }
        if(document.data()?.city) {
            setCity(document.data().city);
        }
    }) : null, [data?.venue, recurringEvent]);

    useEffect(() => firestore.collection('activities').where('timestamp', '>', new Date(Date.now() - 24 * 60 * 60 * 1000)).where('recurring_event', '==', id).onSnapshot(snap => setActivities24Hours(snap.docs)), [id]);
    useEffect(() => firestore.collection('activities').where('timestamp', '>', new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).where('recurring_event', '==', id).onSnapshot(snap => setActivities7Days(snap.docs)), [id]);

    // function similarity(str1, str2) {
    //     if(!str1 || !str2) return 0;
        
    //     const replaceMap = {
    //         'straße': 'str',
    //         'strasse': 'str',
    //         'festival': '',
    //         'konzert': '',
    //         'concert': '',
    //         'halloween': '',
    //         'karneval': '',
    //         'silvester': '',
    //         'neujahr': '',
    //         'weihnacht': '',
    //         [new Date().getFullYear().toString()]: '',
    //         [(new Date().getFullYear().year + 1).toString()]: '',
    //     };
    
    //     let str1Prepared = str1.toLowerCase().replace(/[^a-z0-9]/g, '');
    //     let str2Prepared = str2.toLowerCase().replace(/[^a-z0-9]/g, '');
    
    //     for(const key of Object.keys(replaceMap)) {
    //         str1Prepared = str1Prepared.replace(new RegExp(key, 'g'), replaceMap[key]);
    //         str2Prepared = str2Prepared.replace(new RegExp(key, 'g'), replaceMap[key]);
    //     }
    
    //     const len1 = str1Prepared.length;
    //     const len2 = str2Prepared.length;
    //     const matrix = [];
        
    //     for (let i = 0; i <= len1; i++) {
    //         matrix[i] = [i];
    //     }
        
    //     for (let j = 0; j <= len2; j++) {
    //         matrix[0][j] = j;
    //     }
        
    //     for (let i = 1; i <= len1; i++) {
    //         for (let j = 1; j <= len2; j++) {
    //             const cost = str1Prepared[i - 1] === str2Prepared[j - 1] ? 0 : 1;
    //             matrix[i][j] = Math.min(
    //                 matrix[i - 1][j] + 1, // deletion
    //                 matrix[i][j - 1] + 1, // insertion
    //                 matrix[i - 1][j - 1] + cost // substitution
    //             );
    //         }
    //     }
        
    //     const distance = matrix[len1][len2];
    //     const maxLength = Math.max(len1, len2);
    //     const score = 1 - distance / maxLength;
        
    //     return score;
    // }

    const [blocklistedWords, setBlocklistedWords] = useState([]);
    useEffect(() => {
        if(!blocklist || !recurringEvent?.exists) {
            setBlocklistedWords([]);
            return;
        }
        const nameLower = ' ' + (recurringEvent.data().name?.toLowerCase()??'') + ' ';
        const descriptionLower = ' ' + (recurringEvent.data().description?.toLowerCase()??'') + ' ';
        setBlocklistedWords(blocklist.filter(e => nameLower.includes(` ${e} `) || descriptionLower.toLowerCase().includes(` ${e} `)));
    }, [blocklist, recurringEvent]);

    const back = () => {
        if(!queryParams.return) return false;
        history.push(queryParams.return);
        return true;
    }

    const save = async () => {
        data.organizer && (data.organizer = await Promise.all(data.organizer.filter(e => e).map(async organizer => organizer.startsWith('$') ? await firestore.collection('organizers').add({
            name: organizer.substring(1),
        }).then(e => e.id) : organizer)));
        data.lineup && (data.lineup = await Promise.all(data.lineup.filter(e => e).map(async artist => artist.startsWith('$') ? await firestore.collection('artists').add({
            name: artist.substring(1),
        }).then(e => e.id) : artist)));
        if(address && postalCode && city)
            data.address = address + ', ' + postalCode + ', ' + city.charAt(0).toUpperCase() + city.slice(1);
        else if(address)
            data.address = address;
        if(city)
            data.city = city;
        if(data.venue)
            data.organizer = [...(data.organizer?.filter((e) => e !== recurringEvent.data()?.venue && e !== data.venue)??[]), data.venue];
        if(openEnd)
            data.end = data.start;
        if(!data.visibility)
            data.visibility = 'public';
        if(data.visibility === 'public')
            data.pending_review = false;
        data.agent = user.uid;
        await firestore.collection('recurring_events').doc(id).set(data, { merge: true });
        setUnsavedChanges(false);
        alert('Speichern erfolgreich');
        back();
    };

    const fusion = () => history.push(`/recurring-events/${id}/fusion?return=${encodeURIComponent(location.pathname + location.search)}`);

    const remove = async () => {
        if(!window.confirm('Bist du dir sicher, dass du diesen Eintrag löschen willst?')) return;
        await firestore.collection('recurring_events').doc(id).delete();
        alert('Löschen erfolgreich');
        if(!back())
            history.push('/recurring-events' + location.search);
    };

    const verify = async () => {
        if(!window.confirm('Bist du dir sicher, dass du die Eventreihe "' + recurringEvent?.data()?.name + '" freigeben willst?')) return;
        await firestore.collection('recurring_events').doc(id).update({
            visibility: 'public',
            pending_review: false,
        });
        alert('Freigabe erfolgreich');
        back();
        firestore.collection('recurring_events').doc(id).get().then(document => {
            if(document.exists) {
                setData({...document.data(), organizer: document.data().organizer?.filter(e => e !== document.data().venue)??[]});
            } else {
                setData({});
            }
            setRecurringEvent(document);
        });
    };

    const block = async () => {
        if(!window.confirm('Bist du dir sicher, dass du die Eventreihe "' + recurringEvent?.data()?.name + '" blockieren willst?')) return;
        await firestore.collection('recurring_events').doc(id).update({
            visibility: 'blocked',
            pending_review: false,
        });
        alert('Blockieren erfolgreich');
        back();
        firestore.collection('recurring_events').doc(id).get().then(document => {
            if(document.exists) {
                setData({...document.data(), organizer: document.data().organizer?.filter(e => e !== document.data().venue)??[]});
            } else {
                setData({});
            }
            setRecurringEvent(document);
        });
    };

    //const writePermission = role === 'admin' || event?.data()?.agent === user.uid || !event?.exists;
    const writePermission = true;

    return <div className="h-full flex flex-col">
        <Prompt message="Einige Änderungen wurden noch nicht gespeichert. Trotzdem fortfahren?" when={unsavedChanges} />
        <div className="bg-gray-500 flex flex-col md:flex-row gap-2 p-4 md:py-0 md:px-2">
            {queryParams.return && <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={back}>Zurück</button>}
            {writePermission && <button className="bg-blue-500 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold text-white" onClick={save}>Änderungen speichern</button>}
            <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={() => history.push(`/events?recurring-event=${id}&return=${encodeURIComponent(location.pathname + location.search)}`)}>Events anzeigen</button>
            {role === 'admin' && <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={fusion}>Mit anderer Eventreihe vereinigen</button>}
            {role === 'admin' && <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={block}>Eventreihe blockieren</button>}
            {role === 'admin' && <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={remove}>Eventreihe löschen</button>}
            {role === 'admin' && 
            <a href={"https://console.firebase.google.com/u/1/project/elgio-invited/firestore/databases/-default-/data/~2Frecurring_events~2F" + id} 
                target="_blank" rel="noreferrer"
                className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold flex items-center text-center"> 
                    In der Datenbank ansehen
            </a>}


            <div className="bg-gray-500 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold hidden md:block text-gray-500">a</div>
        </div>
        {
            id && recurringEvent ? <div className="p-3 overflow-scroll" key={recurringEvent.id} ref={ref}>
                {role === 'admin' && recurringEvent?.data()?.pending_review && <div className="mb-3 p-3 bg-gray-600 text-white rounded">
                    <div className="font-semibold">Diese Eventreihe erwartet zur Zeit Verifizierung.</div>
                    {
                        blocklistedWords.length > 0
                            ? <div className="text-red-500">Es wurden in der Eventreihe folgende Wörter auf der Blocklist gefunden: {blocklistedWords.join(', ')}</div>
                            : <div className="text-green-500">Es wurden in der Eventreihe keine Wörter auf der Blocklist gefunden.</div>
                    }
                    <div className="flex gap-3 mt-3">
                        <button className="bg-gray-400 p-2 bg-green-500" onClick={verify}>Freigeben</button>
                        <button className="bg-gray-400 p-2 bg-red-500" onClick={block}>Blockieren</button>
                    </div>
                </div>}
                <Section title="Metadaten">
                    <div className="flex-col md:flex-row flex my-1">
                        <div className="w-64">ID:</div>
                        <div className="w-64">{id}</div>
                    </div>
                    <div className="flex-col md:flex-row flex my-1">
                        <div className="w-64">Erstellt:</div>
                        <div className="w-64">{recurringEvent?.data()?.created_timestamp?.toDate()?.toLocaleString('de-DE')}</div>
                    </div>
                    <div className="flex-col md:flex-row flex my-1">
                        <div className="w-64">Zuletzt bearbeitet von:</div>
                        <div className="w-64">{recurringEvent?.data()?.agent}</div>
                    </div>
                    {recurringEvent?.data()?.external_identifiers && Object.keys(recurringEvent.data().external_identifiers).length > 0 && <div className="flex-col md:flex-row flex my-1">
                        <div className="w-64">Scraper:</div>
                        <div className="w-64">{Object.keys(recurringEvent.data().external_identifiers).join(', ')}</div>
                    </div>}
                    {recurringEvent?.data()?.partner && <div className="flex-col md:flex-row flex my-1">
                        <div className="w-64">Erstellt mit Partner-Formular:</div>
                        <div className="w-64">{recurringEvent?.data()?.partner}</div>
                    </div>}
                </Section>
                <Section title="Statistiken">
                    <div className="flex-col md:flex-row flex my-1">
                        <div className="w-64 font-semibold">In den letzten 24 Stunden:</div>
                        <div className="w-32">{activities24Hours.filter(e => e.data().type === 'event_view').length??0} Views</div>
                        <div className="w-32">{activities24Hours.filter(e => e.data().type === 'bookmark').length??0} Merkungen</div>
                        <div className="w-32">{activities24Hours.filter(e => e.data().type === 'event_share').length??0} Teilungen</div>
                        <div className="w-32">{activities24Hours.filter(e => e.data().type === 'attending').length??0} Teilnehmer</div>
                    </div>
                    <div className="flex-col md:flex-row flex my-1">
                        <div className="w-64 font-semibold">In den letzten 7 Tagen:</div>
                        <div className="w-32">{activities7Days.filter(e => e.data().type === 'event_view').length??0} Views</div>
                        <div className="w-32">{activities7Days.filter(e => e.data().type === 'bookmark').length??0} Merkungen</div>
                        <div className="w-32">{activities7Days.filter(e => e.data().type === 'event_share').length??0} Teilungen</div>
                        <div className="w-32">{activities7Days.filter(e => e.data().type === 'attending').length??0} Teilnehmer</div>
                    </div>
                    <div className="flex-col md:flex-row flex my-1">
                        <div className="w-64 font-semibold">Insgesamt:</div>
                        <div className="w-32">{recurringEvent?.data()?.view_count??0} Views</div>
                        <div className="w-32">{recurringEvent?.data()?.bookmark_count??0} Merkungen</div>
                        <div className="w-32">{recurringEvent?.data()?.share_count??0} Teilungen</div>
                        <div className="w-32">{recurringEvent?.data()?.attendee_count??0} Teilnehmer</div>
                    </div>
                </Section>
                <Section title="Daten" openDefault>
                    {role === 'admin' && <Field name="Sichtbarkeit" value={data?.visibility??'public'} type="dropdown" options={['public', 'private', 'draft', 'blocked']} onChange={(val) => setData({...data, ...{visibility: val}})} />}
                    <Field name="Name" value={data?.name} onChange={(val) => setData({...data, ...{name: val}})} />
                    <Field name="Veranstalter" value={data?.organizer} type="array-organizer" onChange={(val) => setData({...data, ...{organizer: val}})} />
                    <Field name="Startzeitpunkt" value={data?.start} type="timestamp" onChange={(val) => setData({...data, start: val})} />
                    <Field name="Offenes Ende?" value={openEnd} type="checkbox" onChange={(val) => setOpenEnd(val)} />
                    {!openEnd && <Field name="Endzeitpunkt" value={data?.end} type="timestamp" onChange={(val) => setData({...data, end: val})} />}
                    <Field name="Wiederholung" value={data?.repeat} type="repeat" onChange={(val) => setData({...data, repeat: val})} />
                    <Field name="Abgesagt?" value={data?.cancelled} type="checkbox" onChange={(val) => setData({...data, cancelled: val})} />
                    <Field name="Venue" value={data?.venue} type="organizer" onChange={(val) => setData({...data, ...{venue: val}})} />
                    <Field name="Straße + Hausnummer" value={address} onChange={(val) => setAddress(val)} />
                    <Field name="Postleitzahl" value={postalCode} onChange={(val) => setPostalCode(val)} />
                    <Field name="Stadt" value={city} type="city" allowOther={true} onChange={(val) => setCity(val)} />
                    <Field name="Preis (in Euro)" value={data?.price} onChange={(val) => setData({...data, ...{price: Number(val)}})}/>
                    <Field name="Ticketlink" value={data?.ticket_link} type="link" onChange={(val) => setData({...data, ...{ticket_link: val}})} />
                    <Field name="Lineup" value={data?.lineup} type="array-artist" onChange={(val) => setData({...data, ...{lineup: val}})} />
                    <Field name="Specials" value={data?.specials} type="array-special" onChange={(val) => setData({...data, ...{specials: val}})}/>
                    <Field name="Musikrichtungen" value={data?.genre} type="array-genre" onChange={(val) => setData({...data, ...{genre: val}})} />
                    <Field name="Eventkategorien" value={data?.type} type="array-type" onChange={(val) => setData({...data, ...{type: val}})} />
                    <Field name="Mindestalter" value={data?.min_age} onChange={(val) => setData({...data, ...{min_age: Number(val)}})}/>
                    <Field name="Dresscode" value={data?.dresscode} onChange={(val) => setData({...data, ...{dresscode: val}})}/>
                    <Field name="Beschreibung" value={data?.description} type="multiline" onChange={(val) => setData({...data, ...{description: val}})} />
                    <Field name="Bild" value={data?.images ? data.images[0] : null} type="image" fileDirectory={`event_images/${id}`} onChange={(val) => setData({...data, ...{images: [val]}})} />
                </Section>
            </div> : <div className="p-3">
                Bitte warten...
            </div>
        }
    </div>;
}