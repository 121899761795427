import { useEffect, useState } from "react";
import { Prompt } from "react-router-dom";
import {
    useHistory,
    useParams,
    useLocation,
} from "react-router-dom";
import Field from "./Field";
import { firestore } from "./firebase";
import queryString from "query-string";
import { toast } from "react-toastify";


export default function Report() {
    const history = useHistory();
    const location = useLocation();

    const [queryParams, setQueryParams] = useState({});
    useEffect(() => setQueryParams(queryString.parse(location.search)), [location.search]);

    const { id } = useParams();

    const [data, setData] = useState({});
    const [report, setReport] = useState(null);

    const [unsavedChanges, setUnsavedChanges] = useState(false);

    useEffect(() => JSON.stringify(data) !== JSON.stringify(report?.data()) ? setUnsavedChanges(true) : setUnsavedChanges(false), [data, report]);

    useEffect(() => firestore.collection('reports').doc(id).get().then(document => {
        setData(document.data());
        setReport(document);
    }), [id]);

    const back = () => {
        if(!queryParams.return) return false;
        history.push(queryParams.return);
        return true;
    }

    const remove = async () => {
        if(!window.confirm('Bist du dir sicher, dass du diesen Eintrag löschen willst?')) return;
        await firestore.collection('reports').doc(id).delete();
        toast.success("Löschen erfolgreich", {theme: "dark"})
        if(!back())
            history.push('/reports');
    }

    return <div>
        <Prompt message="Einige Änderungen wurden noch nicht gespeichert. Trotzdem fortfahren?" when={unsavedChanges} />
        <div className="bg-gray-500 flex flex-col md:flex-row gap-2 p-4 md:py-0 md:px-2">
            {queryParams.return && <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={back}>Zurück</button>}
            <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={() => history.push(`/${getSubject(report.data())}s/${getSubjectId(report.data())}?return=${encodeURIComponent(location.pathname + location.search)}`)}>Gemeldeten Eintrag anzeigen</button>
            <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={remove}>Meldung löschen</button>
            <div className="bg-gray-500 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold hidden md:block text-gray-500">a</div>
        </div>
        {
            id && report ? <div className="p-3" key={report.id}>
                <Field name="ID" value={report.id} type="constant" />
                <Field name="Melder" value={report.data().author} type="constant" />
                <Field name="Betreff" value={getSubject(report.data()) + ' gemeldet'} type='constant' />
                <Field name="Gemeldete Information" value={report.data().description??report.data().field} type='constant' />
            </div> : <div className="p-3">
                Bitte warten...
            </div>
        }
    </div>;
}

function getSubject(data) {
    if (data.event != null) return 'event';
    if (data.organizer != null) return 'organizer';
    if (data.user != null) return 'user';
}

function getSubjectId(data) {
    if (data.event != null) return data.event;
    if (data.organizer != null) return data.organizer;
    if (data.user != null) return data.user;
}