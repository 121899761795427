import { useState } from "react";

export default function Section({ title, children, openDefault }) {
    const [open, setOpen] = useState(openDefault??false);

    return <div className="mb-3 pb-3 border-b-2">
        <div className="font-bold cursor-pointer" onClick={() => setOpen(!open)}>
            {open ? <>▼</> : <>►</>} {title}
        </div>
        {open && <div className="mt-5">
            {children}
        </div>}
    </div>;
}