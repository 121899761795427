/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, orderBy, query, updateDoc, where } from 'firebase/firestore';
import { firestore } from './firebase';
import { Link, NavLink, useHistory, useLocation, Route, Switch  } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import "./changelog.scss"
import { UserContext } from './providers/UserProvider';
import { toast } from 'react-toastify';

function Changelogs() {
    const [project, setProject] = useState(null)
    const [changelogChildren, setChangelogChildren] = useState([])
    const location = useLocation()
    const [showLoader, setShowLoader] = useState(false)
    const history = useHistory()
    const [data,setData] = useState({})
    const [id,setId] = useState(null)
    const {user} = useContext(UserContext)
    const [hasBeenDeleted, setHasBeenDeleted] = useState(false)
    const [sortChildren,setSortChildren] = useState("asc")
    const searchParams = new URLSearchParams(location)
    const param = searchParams.get('')


    // useEffect(() => {
        // console.log(params)
        // if(document.getElementById("sidebar_closed") && ){
        //     document.getElementById("submenu").classList.add("hidden")
        // }
    // }, [params]);

    useEffect(() => {
        console.log(id)
    }, [id]);

    useEffect(() => {
        if (!showLoader) return
        const projectPath = location.pathname.split('/')[2]
        if(projectPath && document.getElementById('submenu').classList.contains('hidden')){
            document.getElementById('submenu').classList.remove('hidden') 
        }
    }, [showLoader]);


    useEffect(() => {
        const projectPath = location.pathname.split('/')[2]
        setId(location.pathname.split('/')[3])
        setProject(projectPath);
    }, [location.pathname])

    const fetchChangelogChildren = async () => {
        setShowLoader(true);
        getDocs(collection(firestore, "changelogs", project, "changes"))
        .then((snapshot) => {
            setShowLoader(false);
            setChangelogChildren(snapshot.docs);
        })
        .catch((error) => {
            setShowLoader(false);
            console.error(error);
        });
    }

    useEffect(() => {
        if (!project) return;
        fetchChangelogChildren();
        handleShowSubMenu();
    }, [project]);

    const handleShowSubMenu = () => {
        document.getElementById('submenu').classList.toggle('hidden')
    }

    const handleSave = () => {
        // if(!data) return
        if(location.pathname.split('/')[3] === "new"){            
            addDoc(collection(firestore, "changelogs", project, "changes"), {
                ...data,
                created_timestamp: new Date(),
                created_by: user.uid,
            }).then((docRef) => {
                history.push(`/changelogs/${project}/${docRef.id}`)
                fetchChangelogChildren()
                toast.success("Changelog erstellt")
            })
            .catch((error) => {
                toast.error(error.message)
            })
        }
        else if(location.pathname.split("/")[3] !== "new" && id){
            updateDoc(doc(collection(firestore,"changelogs", project, "changes"), id),data)
            .then(() => {
                toast.success("Die Änderungen wurden gespeichert!")
                fetchChangelogChildren()
            })
            .catch((error) => {
                toast.error(error.message)
            })
        }
    }

    const handleDelete = () => {
        if(!window.confirm("Soll das Changelog wirklich gelöscht werden?")) return
        deleteDoc(doc(firestore, "changelogs", project, "changes", id))
        .then(() => {
            setHasBeenDeleted(true)
            fetchChangelogChildren()
            history.push(`/changelogs/${project}`)
            toast.success("Changelog gelöscht!")
        })
        .catch((error) => {
            toast.error(error.message)
        })
    }

    const handleSortChange = () => {
        if(sortChildren === "asc"){
            setSortChildren("desc")
            changelogChildren.reverse()
        } else {
            setSortChildren("asc")
            changelogChildren.reverse()
        }
    }

    

    return (
        <div className='h-full flex w-auto'>
            <Sidebar collection="changelogs" itemTitle={(item) => item.objectID.split('-')[1]} itemLink={(item) => `/changelogs/${item.objectID.split('-')[1]}`} />

            <div className='left-60 w-full md:w-64 bg-gray-800 h-full flex-col flex absolute hidden' id='submenu'>
                <div className='flex-1 overflow-auto divide-y divide-gray-700 text-white'>
                    <div className='flex flex-row gap-2 items-center p-3 cursor-pointer' onClick={() => { handleShowSubMenu(); history.push("/changelogs"); }}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                        <div className='text-l font-bold'>Zurück</div>
                    </div>
                    <div className='flex flex-row gap-2 items-center p-3 cursor-pointer' onClick={handleSortChange}>
                        <div className='text-l font-bold'>{sortChildren === "asc" ? "Absteigend" : "Aufsteigend"}</div>
                    </div>
                    {!showLoader ?
                        changelogChildren.length > 0 ? changelogChildren.map((changelog) => {
                            return (
                                <NavLink activeClassName="bg-gray-600" to={`/changelogs/${project}/${changelog.id}`} className='block p-3' key={changelog.id}>
                                    <div className='flex flex-col gap-1 items-start'>
                                        <div className='text-sm font-semibold'>
                                            {changelog.data().name}
                                        </div>

                                        <div className='text-xs text-gray-400'>
                                            {changelog.data().created_timestamp.toDate().toLocaleString("de")}
                                        </div>

                                    </div>
                                </NavLink>
                            )
                        }) : <div className='text-white text-l p-3'> Keine Changelogs vorhanden </div>
                        :
                        <div className='flex justify-center items-center p-t-10'>
                            <div className="loader"></div>
                        </div>
                    }
                </div>
            </div>
            <div className='flex-grow overflow-auto'>
                <div className="bg-gray-500 flex flex-col md:flex-row gap-2 p-4 md:py-0 md:px-2">
                    {![`/changelogs/${project}/`, `/changelogs`, `/changelogs/${project}/new`].includes(location.pathname) && 
                        <Link className='bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold hidden md:block text-white' to={`/changelogs/${project}/new`}>Neues Changelog</Link>}
                    {(location.pathname.includes(`/changelogs/${project}/`)) &&
                        <button className='bg-blue-500 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold hidden md:block text-white' onClick={() => {handleSave()}}>Changelog Speichern</button>}
                    {id && !location.pathname.includes("new") && <button className='bg-red-500 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold hidden md:block text-white' onClick={() => {handleDelete()}}>Changelog Löschen</button>}
                </div>

                <Switch>
                    <Route path="/changelogs/:project/new" exact>
                    <ChangelogContent createNew project={project} setData={setData} />
                    </Route>
                    <Route path="/changelogs/:project/:id" exact>
                        <ChangelogContent project={project} hasBeenDeleted={hasBeenDeleted} id={location.pathname.split('/')[3]} setData={setData} />
                    </Route>
                </Switch>
            </div>
        </div>
    )

}

function ChangelogContent({ id = "", project, createNew = false, setData, hasBeenDeleted = false }) {
    const [changelogName, setChangelogName] = useState("")
    const [changelogSendEmail, setChangelogSendEmail] = useState(false)
    const [changelogValues, setChangelogValues] = useState([{ title: "", description: "",icon:"" }])
    const [onlyReadChangelogData,setOnlyReadChangelogData] = useState({})
    const [createdByName,setCreatedByName] = useState("")
    const [showLoader, setShowLoader] = useState(false)



    useEffect(() => {
        if(hasBeenDeleted){
            setChangelogName("")
            setChangelogSendEmail(false)
            setChangelogValues([{ title: "", description: "",icon:"" }])
            setOnlyReadChangelogData({})
        }
    }, [hasBeenDeleted]);

    useEffect(() => {
        if (createNew) {
            setChangelogName("")
            setChangelogSendEmail(false)
            setChangelogValues([{ title: "", description: "",icon:"" }])
            setCreatedByName("")
        }
    }, [createNew]);

    useEffect(() => {
        setData({ name: changelogName, send_email: changelogSendEmail, values: changelogValues })
    },[changelogValues, changelogSendEmail, changelogName,setData])

    useEffect(() => {
        if(!onlyReadChangelogData || !onlyReadChangelogData.created_by) return
        getDoc(doc(collection(firestore,"users"),onlyReadChangelogData.created_by)).then(doc => {
            setCreatedByName(doc.data().name)
        })
    }, [onlyReadChangelogData]);

    useEffect(() => {
        if (!id || createNew) return;
        
        const fetchChangelog = async () => {
            setShowLoader(true)
            try {
                const changelogDoc = await getDoc(doc(firestore, "changelogs", project, "changes", id));
                if (changelogDoc.exists()) {
                    const data = changelogDoc.data();
                    setChangelogName(data.name || "");
                    setChangelogSendEmail(data.send_email || false);
                    setChangelogValues(data.values || [{ title: "", description: "",icon:"" }]);
                    setOnlyReadChangelogData(data)
                } else {
                    console.warn(`No changelog found with ID: ${id}`);
                }
            } catch (error) {
                console.error("Error fetching changelog:", error);
            }
            setShowLoader(false)
        };
        
        fetchChangelog();
    }, [id, project, createNew]);

    const removeItemFromArray = (index) => {
        setChangelogValues(prevValues => prevValues.filter((_, i) => i !== index));
    }

    const handleTitleChange = (index, newValue) => {
        setChangelogValues(prevValues => {
            const updatedValues = [...prevValues];
            updatedValues[index].title = newValue;
            return updatedValues;
        });
    }

    const handleDescriptionChange = (index, newValue) => {
        setChangelogValues(prevValues => {
            const updatedValues = [...prevValues];
            updatedValues[index].description = newValue;
            return updatedValues;
        });
    }

    const handleIconChange = (index,newValue) => {
        setChangelogValues(prevValues => {
            const updatedValues = [...prevValues];
            updatedValues[index].icon = newValue;
            return updatedValues;
        });
    }

    return (
        <div className='flex flex-row p-3 gap-1 overflow-y-scroll whitespace-pre-line'>
          {!showLoader ?<div className='w-full'>
                {createNew && <div className='text-black text-2xl p-3'>Neues Changelog für Projekt: {project}</div>}
                {id && 
                    <div className='flex flex-col p-3 border-b'> 
                        <div className='text-black text-3xl pb-3 '>Metadaten:</div>
                        <div className='flex flex-row gap-1 justify-between w-1/5'>
                            <div className='text-black font-semibold '>ID:</div>
                            <div className='text-black'>{id}</div>
                        </div>
                        <div className='flex flex-row gap-1 justify-between w-1/5'>
                            <div className='text-black font-semibold'>Erstellt am:</div>
                            <div className='text-black'>{onlyReadChangelogData?.created_timestamp !== 0 ? onlyReadChangelogData?.created_timestamp?.toDate()?.toLocaleString("de") : "Nicht vorhanden"}</div>

                        </div>
                        <div className='flex flex-row gap-1 justify-between w-1/5'>
                            <div className='text-black font-semibold'>Erstellt von:</div>
                            <div className='text-black'>{createdByName}</div>
                        </div>
                    </div>
                }
                <div className='divider mt-2 mb-2'></div>
                <div className='flex flex-col gap-2'>
                    <InputWithLabel label="ID" inputType="text" disabled inputValue={id || ""} placeholder="Auto-ID" />
                    <InputWithLabel label='Name' inputType="text" placeholder="z.B. Big Update" setInputValue={setChangelogName} inputValue={changelogName} />

                    <div className='p-3 flex flex-col gap-2 border border-black rounded'>
                        <div className='text-black text-l'>Tatsächlicher Content</div>
                        {changelogValues.map((value, index) => (
                            <div key={index} className='p-3 border border-black rounded flex flex-row gap-2 items-center justify-around'>
                                <div className='flex flex-col gap-2 w-5/6'>
                                    <InputWithLabel
                                        label="Titel"
                                        inputType="text"
                                        placeholder="z.B. Event-erstellen"
                                        setInputValue={(newTitle) => handleTitleChange(index, newTitle)}
                                        inputValue={value.title}
                                    />
                                    <InputWithLabel
                                        label="Beschreibung"
                                        inputType="textarea"
                                        placeholder="z.B. Ticketing Anlegen funktioniert wieder"
                                        setInputValue={(newDescription) => handleDescriptionChange(index, newDescription)}
                                        inputValue={value.description}
                                    />
                                    <div className='flex gap-2 w-full'>
                                        <InputWithLabel
                                            label='Icon'
                                            inputType='text'
                                            placeholder='z.B. fa-solid fa-ticket'
                                            setInputValue={(newIcon) => handleIconChange(index, newIcon)}
                                            inputValue={value.icon}
                                            />
                                        <div className='flex flex-col gap-1 justify-between'>
                                            <div className='text-black text-sm'>Icon Vorschau:</div>
                                            <i className={value.icon + " text-2xl self-center"} />
                                        </div>
                                    </div>
                                </div>
                                <button className='text-white h-fit flex items-center gap-2 bg-red-500 rounded px-6 py-1 md:py-2' onClick={() => removeItemFromArray(index)}>
                                    <FontAwesomeIcon icon={faTrashCan} />
                                    <div>Löschen</div>
                                </button>
                            </div>
                        ))}
                        <button className='bg-blue-500 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold text-white' onClick={() => setChangelogValues([...changelogValues, { title: "", description: "" }])}>Neues Item hinzufügen</button>
                    </div>
                    <InputWithLabel
                        label='Email Push Benachrichtigung'
                        inputType='checkbox'
                        checked={changelogSendEmail}
                        onClick={() => setChangelogSendEmail(!changelogSendEmail)}
                        containerClassName='items-start'
                        inputBorder={false}
                    />
                </div>
            </div>:
            <div className='loader_for_changelog_content'></div>}
        </div>
    )
}

function InputWithLabel({ containerClassName = "", label = "", labelClassName = "", inputContainerClassName = "", inputBorder = true, inputType = "text", inputValue = "", setInputValue = () => { }, inputClassName = "", disabled = false, placeholder = "", ...props }) {
    return (
        <div className={`${containerClassName} flex flex-col gap-1`}>
            <label className={`text-sm ${labelClassName}`}>{label}</label>
            <div className={`${inputContainerClassName} ${inputBorder && "border"} border-black rounded p-1`}>
                {inputType === "textarea" ?
                    <textarea className={`${inputClassName} w-full`} disabled={disabled} value={inputValue} onChange={(e) => setInputValue(e.target.value)} placeholder={placeholder} {...props} />
                    :
                    <input className={`${inputClassName} w-full`} disabled={disabled} type={inputType} value={inputValue} onChange={(e) => setInputValue(e.target.value)} placeholder={placeholder} {...props} />
                }
            </div>
        </div>
    )
}

export default Changelogs;
