import { index } from "./algolia";
import { GeoPoint, storage, Timestamp } from "./firebase";
import { useContext, useEffect, useRef, useState } from "react";
import { CategoriesContext } from "./providers/CategoriesProvider";
import ReactInputMask from "react-input-mask";
import { toast } from "react-toastify";


export default function Field({name, value, type, options, allowOther, fileDirectory, fileName, onChange, onLineBreak, isEssential}) {
    const fileInputRef = useRef(null);

    const [currentValue, setCurrentValue] = useState(value);
    
    useEffect(() => value !== currentValue && value?.toString() !== currentValue?.toString() ? setCurrentValue(value) : null, [value]);
    useEffect(() => {
        if(type?.startsWith('array-')) {
            console.log(value?.toString());
            console.log(currentValue?.toString());
        }
        
    }, [currentValue]);
    useEffect(() => onChange && (currentValue || type === 'checkbox') && onChange(currentValue || (type === 'checkbox' ? false : undefined)), [currentValue]);

    const { cities, genres, types } = useContext(CategoriesContext);

    const changeArray = (newArray) => {
        setCurrentValue(newArray);
        onChange(newArray);
    }

    const uploadImage = async e => {
        let image = e.target.files[0];
        await storage.ref(`${fileDirectory}/${fileName??image.name.toLowerCase()}`).put(image);
        onChange(`${fileDirectory}/${fileName??image.name.toLowerCase()}`);
    };
    const handleImagePaste = async () => {
        const items = await navigator.clipboard.read();
        for(let i = 0; i < items.length; i++) {
            for(const type of items[i].types) {
                if(!type.startsWith('image')) continue;
                const blob = await items[i].getType(type);
                const random = Math.floor(Math.random() * 10**10);
                const path = `${fileDirectory}/${fileName??`clipboardimage${random}`}`;
                await storage.ref(path).put(blob);
                onChange(path);
                return;
            }
        }
        toast.warning("Es wurden keine Bilder in der Zwischenablage gefunden", {theme: "dark"})

    };

    const uploadVideo = async e => {
        let video = e.target.files[0];
        await storage.ref(`${fileDirectory}/${fileName??video.name.toLowerCase()}`).put(video);
        onChange(`${fileDirectory}/${fileName??video.name.toLowerCase()}`);
    }
    const handleVideoPaste = async () => {
        const items = await navigator.clipboard.read();
        for(let i = 0; i < items.length; i++) {
            for(const type of items[i].types) {
                if(!type.startsWith('video')) continue;
                const blob = await items[i].getType(type);
                const random = Math.floor(Math.random() * 10**10);
                const path = `${fileDirectory}/${fileName??`clipboardvideo${random}`}`;
                await storage.ref(path).put(blob);
                onChange(path);
                return;
            }
        }
        toast.warning("Es wurden keine Videos in der Zwischenablage gefunden", {theme: "dark"})
    };

    var elements = [];
    switch(type) {
        case 'city': elements = cities; break;
        case 'genre': elements = genres; break;
        case 'type': elements = types; break;
    }
    const [other, setOther] = useState(!(elements?.map((e) => e.id)?.includes(value))??false);
    useEffect(() => setOther(!(elements?.map((e) => e.id)?.includes(currentValue))??false), [currentValue]);

    return <div className="flex-col md:flex-row flex my-1" style={ isEssential ? {backgroundColor: "yellow"} : {}}>
        {name && <div className="w-64 font-semibold">
            {name}:
        </div>}
        <div className="flex-grow border border-black rounded p-1">
            {
                type?.startsWith('array-')
                ? <>
                    {currentValue?.map((item, index) => <div className="flex">
                        <button className="w-5 bg-red-500 rounded my-1 mr-1" onClick={() => {
                            var temp = currentValue;
                            temp.splice(index, 1);
                            changeArray([...temp]);
                        }}>-</button>
                        <Field value={item} type={type.split('-').slice(1).join('-')} onChange={(val) => {
                            var temp = currentValue;
                            temp.splice(index, 1, val);
                            changeArray([...temp]);
                        }} onLineBreak={nextLine => {
                            var temp = currentValue;
                            temp.splice(index + 1, 0, nextLine);
                            changeArray([...temp]);
                        }} />
                    </div>)}
                    <button className="w-5 bg-blue-500 rounded my-1 mr-1" onClick={() => changeArray([...(currentValue??[]), null])}>+</button>
                </>
                : type === 'image'
                ? <div onPaste={handleImagePaste}>
                    <div className="flex gap-3 mb-3">
                        <button className="bg-blue-500 text-white rounded p-3" onClick={() => fileInputRef.current.click()}>Bild hochladen</button>
                        <button className="bg-blue-500 text-white rounded p-3" onClick={handleImagePaste}>Aus Zwischenablage einfügen</button>
                    </div>
                    <input ref={fileInputRef} className="hidden" type="file" accept="image/png, image/jpeg, image/webp" onChange={uploadImage} />
                    <ReferenceImage reference={currentValue} />
                </div>
                : type === 'video'
                ? <div onPaste={handleVideoPaste}>
                    <div className="flex gap-3 mb-3">
                        <button className="bg-blue-500 text-white rounded p-3" onClick={() => fileInputRef.current.click()}>Video hochladen</button>
                        <button className="bg-blue-500 text-white rounded p-3" onClick={handleVideoPaste}>Aus Zwischenablage einfügen</button>
                    </div>
                    <input ref={fileInputRef} className="hidden" type="file" accept="video/mp4,video/x-m4v,video/*" onChange={uploadVideo} />
                    <ReferenceVideo reference={currentValue} />
                </div>
                : type === 'event'
                ? <SearchField value={currentValue} onChange={setCurrentValue} onLineBreak={onLineBreak} recordType="event" path="events" searchableAttribute="name" hintAttribute="start" />
                : type === 'recurring_event'
                ? <SearchField value={currentValue} onChange={setCurrentValue} onLineBreak={onLineBreak} recordType="recurring_event" path="recurring_events" searchableAttribute="name" />
                : type === 'organizer'
                ? <SearchField value={currentValue} onChange={setCurrentValue} onLineBreak={onLineBreak} recordType="organizer" path="organizers" searchableAttribute="name" hintAttribute="city" />
                : type === 'artist'
                ? <SearchField value={currentValue} onChange={setCurrentValue} onLineBreak={onLineBreak} recordType="artist" path="artists" searchableAttribute="name" />
                : type === 'user'
                ? <SearchField value={currentValue} onChange={setCurrentValue} onLineBreak={onLineBreak} recordType="user" path="users" searchableAttribute="username" hintAttribute="email" />
                : type === 'geopoint'
                ? <GeoPointField value={currentValue} onChange={setCurrentValue} />
                : type === 'repeat'
                ? <RepeatInput value={currentValue} onChange={setCurrentValue} />
                : type === 'city' || type === 'genre' || type === 'type'
                ? <>
                    <select onChange={(e) => {
                        if(e.target.value === 'other') {
                            setOther(true);
                            setCurrentValue('');
                        } else {
                            setOther(false);
                            setCurrentValue(e.target.value);
                        }
                    }}>{[...(elements??[]), ...(allowOther ? [null] : [])].map((e) => <option value={e?.id??'other'} selected={value===e?.id || (e === null && !elements?.some(x => x.id===value))}>{e?.data()?.name??e?.data()?.de??'Other'}</option>)}</select>
                    {other && <input type="text" value={value} onChange={(e) => onChange(e.target.value)} />}
                </>
                : type === 'multiline'
                ? <textarea className="w-full" rows="10" value={currentValue} onChange={(e) => setCurrentValue(e.target.value)}></textarea>
                : type === 'timestamp'
                ? <ReactInputMask className="w-full" mask="99.99.9999, 99:99 Uhr" defaultValue={currentValue?.toDate()?.toLocaleString('de-DE', {year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit"})} onChange={(e) => parseDate(e.target.value) && setCurrentValue(Timestamp.fromDate(parseDate(e.target.value)))}/>
                : type === 'dropdown'
                ? <select value={currentValue} onChange={(e) => setCurrentValue(e.target.value)}>
                    {options?.map(e => <option>{e}</option>)}
                </select>
                : <div className="flex">
                    <input autoFocus className={type==='checkbox' ? 'ml-2' : 'w-full'} type={type==='checkbox' ? 'checkbox' : 'text'} checked={currentValue} value={currentValue} readOnly={type==='constant'} onChange={(e) => setCurrentValue(type==='checkbox' ? e.target.checked : e.target.value)} onKeyDown={e => e.key === 'Enter' ? onLineBreak(null) : null} />
                    {type==='link' && <a className="underline" href={currentValue} target="_blank">Öffnen</a>}
                </div>
            }
            
        </div>
    </div>
}

function ReferenceImage({reference}) {
    const [url, setUrl] = useState('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');

    useEffect(() => reference ? storage.ref(reference).getDownloadURL().then(url => setUrl(url)).catch(_ => setUrl('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==')) : setUrl('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='), [reference]);

    return <img className="h-48 w-auto" src={url} alt="" />;
}

function ReferenceVideo({reference}) {
    const [url, setUrl] = useState(null);

    useEffect(() => reference ? storage.ref(reference).getDownloadURL().then(url => setUrl(url)).catch(_ => setUrl(null)) : setUrl(null), [reference]);

    return url ? <video key={url} className="h-48 w-auto" controls>
        <source src={url} type="video/mp4" />
    </video> : <div>Kein Video</div>;
}

function GeoPointField({value, onChange}) {
    const [currentLatitude, setCurrentLatitude] = useState(0);
    const [currentLongitude, setCurrentLongitude] = useState(0);

    useEffect(() => {
        if(!value || (value.latitude === currentLatitude && value.longitude === currentLongitude)) return;
        setCurrentLatitude(value.latitude);
        setCurrentLongitude(value.longitude);
    }, [value]);
    useEffect(() => (currentLatitude !== 0 || currentLongitude !== 0) && onChange(new GeoPoint(currentLatitude, currentLongitude)), [currentLatitude, currentLongitude]);

    return <div className="flex">
        <div>Lat:&nbsp;</div>
        <input className="w-32" type="number" value={currentLatitude} onChange={(e) => setCurrentLatitude(e.target.value)} />
        <div>Lon:&nbsp;</div>
        <input className="w-32" type="number" value={currentLongitude} onChange={(e) => setCurrentLongitude(e.target.value)} />
    </div>;
}

function SearchField({value, onChange, onLineBreak, recordType, path, searchableAttribute, hintAttribute}) {
    const ref = useRef();

    const [currentValue, setCurrentValue] = useState(null);
    // const [initialValue, setInitialValue] = useState(null);
    const [hit, setHit] = useState(null);

    useEffect(() => {
        if(value && value !== '$' + currentValue) {
            if(value.startsWith('$')) {
                setCurrentValue(value.split(/(?:\r\n|\r|\n)+/g)[0].substring(1));
            } else {
                index.search(value.split(/(?:\r\n|\r|\n)+/g)[0], { filters: `recordType:"${recordType}"` }).then(({ hits }) => {
                    if(document.activeElement !== ref.current) {
                        if(hits.length > 0) {
                            setCurrentValue(hits[0][searchableAttribute]??hits[0].objectID.split('-')[1]);
                            setHit(hits[0]);
                        } else {
                            setCurrentValue(value.split(/(?:\r\n|\r|\n)+/g)[0]);
                        }
                    }
                });
            }
            if(value.split(/(?:\r\n|\r|\n)+/g).length > 1)
                onLineBreak(value.split(/(?:\r\n|\r|\n)+/g).slice(1).join('\n'));
        }
    }, [value]);
    
    useEffect(() => currentValue ? index.search(currentValue, { filters: `recordType:"${recordType}"`, typoTolerance: 'false' }).then(({ hits }) => {
        if(hits.length > 0 && ( hits[0].objectID.split('-')[1] === currentValue || hits[0][searchableAttribute].replace(/[^A-Z0-9]+/ig, '').toLowerCase().startsWith(currentValue.replace(/[^A-Z0-9]+/ig, '').toLowerCase()))) {
            setHit(hits[0]);
            onChange(hits[0].objectID.split('-')[1]);
        } else {
            setHit(null);
            onChange('$' + currentValue);
        }
    }) : (() => {
        setHit(null);
        onChange(null);
    })(), [currentValue]);

    return <div className="flex">
        <input ref={ref} type="text" value={currentValue} onChange={(e) => setCurrentValue(e.target.value)} onKeyDown={e => e.key === 'Enter' ? onLineBreak(null) : null} onPaste={e => {
            let paste = (e.clipboardData || window.clipboardData).getData('text');

            setCurrentValue(paste.split(/(?:\r\n|\r|\n)+/g)[0]);

            if(paste.split(/(?:\r\n|\r|\n)+/g).length > 1) {
                onLineBreak(paste.split(/(?:\r\n|\r|\n)+/g).slice(1).join('\n'));
            }
        
            e.preventDefault();
        }} />
        {hit ? <div>
            Gefunden: <a className="underline" href={`/${path}/` + hit.objectID.split('-')[1]} target="_blank">{hit[searchableAttribute]??hit.objectID.split('-')[1]}{hintAttribute && ` (${hit[hintAttribute]?._seconds ? new Date((hit[hintAttribute]._seconds) * 1000).toLocaleString('de-DE') : hit[hintAttribute]})`}</a>
            &nbsp; <button className="underline" onClick={() => { setHit(null); onChange('$' + currentValue); }}>X</button>
        </div> : <div>Noch nicht in Datenbank</div>}
    </div>
}

function RepeatInput({value, onChange}) {
    const [currentValue, setCurrentValue] = useState(value);

    const weekdays = [
        'Sonntag',
        'Montag',
        'Dienstag',
        'Mittwoch',
        'Donnerstag',
        'Freitag',
        'Samstag',
    ];

    const ordinalAdverbs = {
        'first': 'ersten',
        'second': 'zweiten',
        'third': 'dritten',
        'fourth': 'vierten',
        'fifth': 'fünften',
    }

    const change = (key, weekday, checked) => {
        var temp = {...currentValue};
        if(!temp[key]) temp[key] = { weekdays: [] };
        if(checked) {
            temp[key].weekdays.push(weekday);
        } else {
            temp[key].weekdays = temp[key].weekdays.filter(x => x !== weekday);
        }
        setCurrentValue(temp);
        onChange(temp);
    }

    return <div className="grid grid-cols-8 gap-2">
        <div />
        {weekdays.map((weekday) => <div className="text-center">{weekday}</div>)}
        <div className="text-center">jeden</div>
        {weekdays.map((_, index) => <input type="checkbox" checked={currentValue?.every?.weekdays?.includes(index)} onChange={e => change('every', index, e.target.checked)} />)}
        {Object.entries(ordinalAdverbs).map(([key, name]) => <>
            <div className="text-center">{name}</div>
            {weekdays.map((_, index) => <input type="checkbox" checked={(currentValue && currentValue[key]?.weekdays?.includes(index)) || currentValue?.every?.weekdays?.includes(index)} onChange={e => change(key, index, e.target.checked)} />)}
        </>)}
        <div className="text-center">letzten</div>
        {weekdays.map((_, index) => <input type="checkbox" checked={currentValue?.every?.weekdays?.includes(index)} onChange={e => change('last', index, e.target.checked)} />)}
    </div>
}

function parseDate(dateString) {
    var dateParser = /(\d{1,2}).(\d{1,2}).(\d{4}), (\d{2}):(\d{2}) Uhr/;
    var match = dateString.match(dateParser);
    if(!match) return null;
    return new Date(
        match[3],  // year
        match[2]-1,  // monthIndex
        match[1],  // day
        match[4],  // hours
        match[5],  // minutes
    );
}