import { useEffect, useState } from "react";
import {
    useHistory,
    useParams,
} from "react-router-dom";
import { firestore } from "./firebase";

export default function ScraperLog() {
    const history = useHistory();

    const { id, entry } = useParams();

    const [log, setLog] = useState(null);
    useEffect(() => firestore.collection('scraper').doc(id).collection('entries').doc(entry).get().then(e => setLog(e.data()?.log??'Kein Log'), [id, entry]));

    return <div className="h-screen flex flex-col overflow-hidden">
        <div className="bg-gray-500 flex flex-col md:flex-row gap-2 p-4 md:py-0 md:px-2">
            <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={() => history.goBack()}>Zurück</button>
            <div className="bg-gray-500 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold hidden md:block text-gray-500">a</div>
        </div>
        {
            log ? <div className="p-3 overflow-y-scroll whitespace-pre-line">
                {log}
            </div> : <div className="p-3">
                Bitte warten...
            </div>
        }
    </div>;
}