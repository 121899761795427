import { useContext, useEffect, useMemo, useState } from "react";
import { firestore } from "./firebase";
import Sidebar from "./Sidebar";
import { Link, useLocation } from "react-router-dom";
import { CategoriesContext } from "./providers/CategoriesProvider";

export default function Overview() {
    const location = useLocation();
    const { cities } = useContext(CategoriesContext);

    const [organizers, setOrganizers] = useState([]);
    const [eventCounts, setEventCounts] = useState({});

    const [selectedCity, setSelectedCity] = useState('');

    const [popularEventNames, setPopularEventNames] = useState([]);

    const [bothEventsWeekend, setBothEventsWeekend] = useState(false);
    const [fridayEvents, setFridayEvents] = useState({});
    const [saturdayEvents, setSaturdayEvents] = useState({});

    const [eventCountTotal, setEventCountTotal] = useState(0);
    const [eventCountWeek, setEventCountWeek] = useState(0);
    const [eventCountWeekend, setEventCountWeekend] = useState(0);
    const [eventPercentage, setEventPercentage] = useState(0);

    // Set the current week's start date
    const [currentWeekStart, setCurrentWeekStart] = useState(() => {
        const startOfWeek = new Date();
        startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay() + 1); // Set to Monday
        return startOfWeek;
    });

    const handleCityChange = (event) => {
        setSelectedCity(event.target.value);
    };

    const getWeekDates = (startOfWeek) => {
        const startOfWeekDate = new Date(startOfWeek);
        startOfWeekDate.setDate(startOfWeekDate.getDate() - startOfWeekDate.getDay() + 1); // Set to Monday
    
        const endOfWeekDate = new Date(startOfWeekDate);
        endOfWeekDate.setDate(startOfWeekDate.getDate() + 6); // Set to Sunday
    
        return {
            startOfWeek: startOfWeekDate,
            endOfWeek: endOfWeekDate,
        };
    };

    const handlePreviousWeek = () => {
        setCurrentWeekStart(prevStart => {
            const newStart = new Date(prevStart);
            newStart.setDate(newStart.getDate() - 7); // Move to the previous week
            return newStart;
        });
    };
    
    const handleNextWeek = () => {
        setCurrentWeekStart(prevStart => {
            const newStart = new Date(prevStart);
            newStart.setDate(newStart.getDate() + 7); // Move to the next week
            return newStart;
        });
    };

    const maxScore = 60; // 3 fields * 10 points each

    const calculateClubQuality = (docData) => {
        let score = 0;
        if (docData.description) score += 10;
        if (docData.address) score += 10;
        if (docData.images) score += 10;
        if (docData.genre) score += 10;
        if (docData.icon) score += 10;
        if (docData.instagram) score += 10;

        return parseFloat(((score / maxScore) * 100).toFixed(2));
    };

    // Fetch organizers based on the selected city
    useEffect(() => {
        firestore.collection('organizers')
            .where('city', '==', selectedCity)
            .get()
            .then(snapshot => {
                setOrganizers(snapshot.docs);
            });
    }, [selectedCity]);
    const venueDocs = useMemo(() => organizers.filter(doc => doc.data().type === 'venue'), [organizers]);
    const clubDocs = useMemo(() => venueDocs.filter(doc => doc.data().types?.includes('club')), [venueDocs]);
    const venues = useMemo(() => venueDocs.map(doc => ({
        id: doc.id,
        name: doc.data().name,
        note: doc.data().note,
        viewCount: doc.data().view_count || 0,
        quality: calculateClubQuality(doc.data())
    })), [venueDocs]);
    const clubs = useMemo(() => clubDocs.map(doc => ({
        id: doc.id,
        name: doc.data().name,
        note: doc.data().note,
        viewCount: doc.data().view_count || 0,
        quality: calculateClubQuality(doc.data())
    })), [clubDocs]);
    const clubQuality = useMemo(() => clubs.reduce((acc, club) => {
        acc[club.id] = club.quality;
        return acc;
    }, {}), [clubs]);

    // Type counts
    const standardOrganizerCount = useMemo(() => organizers.filter(doc => !doc.data().type || doc.data().type === 'standard').length, [organizers]);
    const labelCount = useMemo(() => organizers.filter(doc => doc.data().type === 'label').length, [organizers]);
    const venueCount = useMemo(() => organizers.filter(doc => doc.data().type === 'venue').length, [organizers]);
    const cafeCount = useMemo(() => organizers.filter(doc => doc.data().types?.includes('cafe')).length, [organizers]);
    const locationCount = useMemo(() => organizers.filter(doc => doc.data().types?.includes('location')).length, [organizers]);
    const barCount = useMemo(() => organizers.filter(doc => doc.data().types?.includes('bar')).length, [organizers]);
    const clubCount = useMemo(() => organizers.filter(doc => doc.data().types?.includes('club')).length, [organizers]);
    
    // Fetch and filter events based on the selected week
    useEffect(() => {
        const { startOfWeek, endOfWeek } = getWeekDates(currentWeekStart);
    
        const startOfWeekend = new Date(startOfWeek);
        startOfWeekend.setDate(startOfWeek.getDate() + 4); // Friday
        const endOfWeekend = new Date(startOfWeek);
        endOfWeekend.setDate(startOfWeek.getDate() + 6); // Sunday
    
        firestore.collection('events')
            .where('city', '==', selectedCity)
            .where('start', '>=', startOfWeek)  // Start of the week
            .where('start', '<=', endOfWeek)    // End of the week
            .get()
            .then(snapshot => {
                const events = snapshot.docs;
                setEventCountTotal(events.length);
    
                // Initialize counts
                const clubEventCountsWeek = {};
                const clubEventCountsWeekend = {};
                const fridayEventsTemp = {};
                const saturdayEventsTemp = {};
    
                events.forEach(eventDoc => {
                    const event = {
                        ...eventDoc.data(),
                        id: eventDoc.id,
                    };
                    const start = event.start.toDate();
                    const venueId = event.venue;
    
                    // Initialize counts for each venue
                    if (!clubEventCountsWeek[venueId]) {
                        clubEventCountsWeek[venueId] = 0;
                    }
                    if (!clubEventCountsWeekend[venueId]) {
                        clubEventCountsWeekend[venueId] = 0;
                    }
                    if (!fridayEventsTemp[venueId]) {
                        fridayEventsTemp[venueId] = '';
                    }
                    if (!saturdayEventsTemp[venueId]) {
                        saturdayEventsTemp[venueId] = '';
                    }
    
                    // Count events for the week
                    if (start >= startOfWeek && start <= endOfWeek) {
                        clubEventCountsWeek[venueId]++;
                    }
    
                    // Count events for the weekend and track Friday/Saturday events
                    if (start >= startOfWeekend && start <= endOfWeekend) {
                        clubEventCountsWeekend[venueId]++;
                        if (start.getDay() === 5) { // Friday
                            fridayEventsTemp[venueId] = event;
                        } else if (start.getDay() === 6) { // Saturday
                            saturdayEventsTemp[venueId] = event;
                        }
                    }
                });
    
                // Sort venues by view count and get the top 5
                const top5Venues = venues
                    .sort((a, b) => b.viewCount - a.viewCount)
                    .slice(0, 10);
    
                // Calculate the total number of actual Friday + Saturday events for the top 5 clubs
                let totalEvents = 0;
                top5Venues.forEach(venue => {
                    const venueId = venue.id;
                    if (fridayEventsTemp[venueId]) totalEvents++;
                    if (saturdayEventsTemp[venueId]) totalEvents++;
                });
    
                const totalPossibleEvents = 20; // 5 clubs * 2 possible events (Friday and Saturday)
                const eventPercentage = (totalEvents / totalPossibleEvents) * 100;
    
                // Update state with counts
                setEventCountWeek(Object.values(clubEventCountsWeek).reduce((a, b) => a + b, 0));
                setEventCountWeekend(Object.values(clubEventCountsWeekend).reduce((a, b) => a + b, 0));
                setEventCounts({
                    week: clubEventCountsWeek,
                    weekend: clubEventCountsWeekend,
                });
    
                setBothEventsWeekend(totalEvents >= 10);
                setFridayEvents(fridayEventsTemp);  // Update the state with the event objects
                setSaturdayEvents(saturdayEventsTemp);  // Update the state with the event objects
    
                // Set the calculated percentage
                setEventPercentage(eventPercentage);
            });
    }, [currentWeekStart, selectedCity, venues]);
    
    

    // Fetch popular events
    useEffect(() => {
        firestore.collection('cities').doc('cologne').get().then(doc => {
            if (doc.exists) {
                const eventIds = doc.data().popular_events.slice(0, 5);
                const eventPromises = eventIds.map(id => 
                    firestore.collection('events').doc(id).get().then(eventDoc => eventDoc.exists ? eventDoc.data().name : 'Event not found')
                );
                Promise.all(eventPromises).then(eventNames => setPopularEventNames(eventNames));
            }
        });
    }, []);

    // Calculate the week date range for display
    const { startOfWeek, endOfWeek } = getWeekDates(currentWeekStart);

    const formatDate = (date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        return `${day}.${month}`;
    };

    const thisWeekDate = `${formatDate(startOfWeek)} - ${formatDate(endOfWeek)}`;

    return (
        <div className="h-full flex w-auto">
            <Sidebar defaultOpen={false} />
            <div className="flex-grow overflow-auto">
                <div className="h-screen flex flex-col overflow-hidden">
                    <div className="bg-gray-600 flex items-center text-white gap-2 p-3 justify-between">
                        <div className="text-xl font-semibold">Übersicht</div>
                    </div>
                    <div className="p-5 overflow-y-scroll">
                        <div>
                            <div className="flex justify-between items-center">
                                <select value={selectedCity} onChange={handleCityChange} className="font-semibold text-4xl">
                                    {cities.map(city => (
                                        <option key={city.id} value={city.id}>
                                            {city.data().name}
                                        </option>
                                    ))}
                                </select>
                                <div className="flex gap-4 items-center">
                                    <div onClick={handlePreviousWeek} className="cursor-pointer">- Letzte</div>
                                    <div className="flex flex-col items-center">
                                        <div className="text-xl font-semibold">Woche</div>
                                        <div className="text-sm">{thisWeekDate}</div>
                                    </div>
                                    <div onClick={handleNextWeek} className="cursor-pointer">Nächste + </div>
                                </div>
                            </div>
                            <div className="flex justify-between mb-6 items-end">
                                <div>
                                    <div className="text-lg mt-5">Anzahl Veranstalter: <span className="font-semibold">{standardOrganizerCount}</span></div>
                                    <div className="text-lg">Anzahl Labels: <span className="font-semibold">{labelCount}</span></div>
                                    <div className="text-lg mb-5">Anzahl Venues insgesamt: <span className="font-semibold">{venueCount}</span></div>
                                    <div className="text-lg">Anzahl Clubs: <span className="font-semibold">{clubCount}</span></div>
                                    <div className="text-lg">Anzahl Locations: <span className="font-semibold">{locationCount}</span></div>
                                    <div className="text-lg">Anzahl Bars: <span className="font-semibold">{barCount}</span></div>
                                    <div className="text-lg mb-5">Anzahl Cafes: <span className="font-semibold">{cafeCount}</span></div>
                                    <div className="text-lg">Anstehende Events:</div>
                                    <div className="text-lg">- diese Woche: <span className="font-semibold">{eventCountWeek}</span></div>
                                    <div className="text-lg mb-5">- dieses Wochenende: <span className="font-semibold">{eventCountWeekend}</span></div>
                                </div>
                                <div className="flex flex-col items-center">
                                    <div className="text-sm">Content Completeness Top 10</div>
                                    <div className="text-5xl bg-slate-300 h-48 w-48 justify-center flex items-center p-2 rounded font-semibold">{Math.round(eventPercentage)}%</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="text-xl font-semibold">Clubs (nach beliebtesten)</div>
                            <div className="">
                                <div className="grid grid-cols-12">
                                    <div className="p-2 col-span-1 flex items-center justify-center">Ranking</div>
                                    <div className="p-2 col-span-1 flex items-center text-center justify-center">Profil-Qualität</div>
                                    <div className="p-2 col-span-2 flex items-center">Name</div>
                                    <div className="p-2 col-span-1 text-center flex items-center">Anzahl Wochenende</div>
                                    <div className="p-2 pl-6 col-span-4 flex items-center">Events Wochenende</div>
                                    <div className="p-2 col-span-2 flex items-center">Wo Events</div>
                                    <div className="p-2 col-span-1 flex items-center justify-center text-center">Status</div>
                                </div>
                                <div className="flex flex-col gap-2">
                                    {clubs
                                        .sort((a, b) => b.viewCount - a.viewCount) // Sort clubs by descending view_count
                                        .map((venue, index) => {
                                            const weekCount = eventCounts.week?.[venue.id] || 0;
                                            const weekendCount = eventCounts.weekend?.[venue.id] || 0;

                                            return (
                                                <div className="grid grid-cols-12 rounded bg-slate-100" key={index}>
                                                    <div className="p-2 col-span-1 items-center flex justify-center text-slate-400">{index + 1}</div>
                                                    <div
                                                        className={`p-2 col-span-1 flex items-center justify-center font-semibold
                                                            ${clubQuality[venue.id] > 90 ? 'text-green-600' : 
                                                            clubQuality[venue.id] > 60 ? 'text-orange-400' : 
                                                            'text-red-600'}`}
                                                    >
                                                        {clubQuality[venue.id]}%
                                                    </div>
                                                    <div className="p-2 col-span-2 items-center flex">
                                                        <Link to={`/organizers/${venue.id}`} target="_blank" rel="noopener noreferrer">{venue.name}</Link>
                                                    </div>
                                                    <div className="p-2 col-span-1 text-center items-center flex justify-center">{weekendCount} / 2</div>
                                                    <div className="p-2 pl-6 col-span-4">
                                                        <div className="whitespace-nowrap text-ellipsis overflow-hidden">FR: {fridayEvents[venue.id] ? <Link to={`/events/${fridayEvents[venue.id].id}`} target="_blank" rel="noopener noreferrer" className="font-semibold">{fridayEvents[venue.id].name}</Link> : <span className="italic">No event</span>}</div>
                                                        <div className="whitespace-nowrap text-ellipsis overflow-hidden">SA: {saturdayEvents[venue.id] ? <Link to={`/events/${saturdayEvents[venue.id].id}`} target="_blank" rel="noopener noreferrer" className="font-semibold">{saturdayEvents[venue.id].name}</Link> : <span className="italic">No event</span>}</div>
                                                    </div>
                                                    <div className="p-2 col-span-2">{venue.note}</div>
                                                    <div className="p-2 col-span-1 text-center items-center flex justify-center">
                                                        {weekendCount >= 2 ? <div className="h-8 w-8 rounded-full bg-green-600" /> : <div className="h-8 w-8 rounded-full bg-red-600"/>}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
