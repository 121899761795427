import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faX } from '@fortawesome/free-solid-svg-icons'
import { setDoc, doc, collection, getDoc, updateDoc } from 'firebase/firestore'
import { firestore, functions } from './firebase'
import { httpsCallable } from 'firebase/functions'

function GenerateDocuments() {
    const [showLoader, setShowLoader] = useState(false)
    const [invoiceNumbersDoc,setInvoiceNumbersDoc] = useState({})
    const [pdfTemplateSelected,setPdfTemplateSelected] = useState("null")
    const [selectedProduct,setSelectedProduct] = useState("null")
    const [eventId,setEventId] = useState("")
    const id = window.location.pathname.split("/")[2]
    const [businessInfoName,setBusinessInfoName] = useState("")
    const [businessInfoStreet,setBusinessInfoStreet] = useState("")
    const [businessInfoPlz,setBusinessInfoPlz] = useState("")
    const [sales,setSales] = useState([{amount: "" , name: "", price: "", sum: ""}])
    const salesSum = sales.map((item) => item.price).reduce((prev,curr) => prev + curr)
    const elgioProductsList = [
        {value:"marketing_bundle", label:"Marketing Bundle"}
    ]
    const pdfSelectOptions = [
        { value:"invoice", label: "Rechnung Generell (Bundles etc.)" },
        // { value:"invoice_ticketing", label:"Abrechnung Ticketing" },
        // { value:"order_confirmation", label: "Auftragsbestätigung Ticketing" },
    ]

    useEffect(() => {
        getDoc(doc(collection(firestore, "settings"),"invoiceNumbers"))
        .then((doc) => {
            if(!doc.exists) return
            setInvoiceNumbersDoc(doc.data())
        })
    }, []);

    const generateRechnungsNummer = async () => {
        const date = new Date()
        const year = date.getFullYear().toLocaleString().slice(3)
        const month = date.getMonth() + 1
        
        const documentPrefixMap = {
            "invoice": "R",
            "invoice_ticketing" : "R-T",
            "order_confirmation" : "A-T"
        }

        const prefixMap = {
            "R"   : "invoiceGeneral",
            "R-T" : "invoiceOrganizerTicketing",
            "R-A" : "invoiceOrganizerAbos",
            "A-T" : "orderConfirmationTicketing"
        }

        const documentPrefix = documentPrefixMap[pdfTemplateSelected] ?? ""
        const firebaseFieldname = prefixMap[documentPrefix] 
        
        let invoiceNumber = invoiceNumbersDoc[firebaseFieldname]
        invoiceNumber += 1
        await updateDoc(doc(collection(firestore,"settings"),"invoiceNumbers"),{[firebaseFieldname]:invoiceNumber})
        
        const rechnungsNummer = (year + month + "-" + documentPrefix + "-" + invoiceNumber).toString()
        
        return rechnungsNummer
    }

    const newDocument = async () => {
        setShowLoader(true)
        const rechnungsNummer = await generateRechnungsNummer()
        try{

        // Assuming `functions` is already defined as your Firebase functions instance
        const generatePDFAdmin = httpsCallable(functions, 'documents-generatePDFAdmin');
        // Call the function with your parameters
        generatePDFAdmin({
            rechnungsNummer,
            sales,
            businessInfoName,
            businessInfoStreet,
            businessInfoPlz,
            selectedProduct,
            eventId,
            pdfTemplateSelected,
            salesSum
        })
        .then((result) => {
            // Handle the response from your Cloud Function here
            setDoc(doc(collection(firestore, "documents"),rechnungsNummer), {
                type: pdfTemplateSelected,
                event: eventId,
                pdf: result.data.pdf,
                product: selectedProduct,
            })
            .then(() => {
                const a = document.createElement("a")
                a.href = result.data.downloadURL
                a.target = "_blank"
                a.download = rechnungsNummer + ".pdf"
                a.click()
            })

                
        })
        .catch((error) => {
            // Handle any errors here
            console.error("Error calling function:", error);
        });


        }
        catch(error){
            console.error(error)
        }
        setShowLoader(false)    
    }

    const removeItemFromArray = (index) => {
        setSales(prevValues => prevValues.filter((_, i) => i !== index));
    }

    const handleTimesCountChange = (index, newValue) => {
        setSales(prevValues => {
            const updatedValues = [...prevValues];
            updatedValues[index].amount = newValue;
            return updatedValues;
        });
    }

    const handleNameChange = (index, newValue) => {
        setSales(prevValues => {
            const updatedValues = [...prevValues];
            updatedValues[index].name = newValue;
            return updatedValues;
        });
    }

    const handlePriceChange = (index,newValue) => {
        setSales(prevValues => {
            const updatedValues = [...prevValues]
            updatedValues[index].price = newValue
            return updatedValues
        })
    }

    const handleSumChange = (index,newValue) => {
        setSales(prevValues => {
            const updatedValues = [...prevValues];
            updatedValues[index].sum = newValue;
            return updatedValues;
        });
    }

    const addEmptyElement = () => {
        setSales((prev) => [...prev,{amount: "" , name: "", price: "", sum: ""}])
    }

    return (
        <div className='h-full flex w-auto'>
            <Sidebar collection={"documents"} itemLink={(item) => `/generate-documents/${item.objectID.slice(1)}`} itemTitle={(item) => item.objectID.slice(1)}/>
            <div className='flex flex-row p-3 gap-1 overflow-y-scroll whitespace-pre-line'>
            {!showLoader 
                ? <div className='w-full'>
                    {id 
                        ? <div className='flex flex-col p-3 border-b'> 
                                <div className='text-black text-3xl pb-3 '>Metadaten:</div>
                                <div className='flex flex-row gap-1 justify-between w-1/5'>
                                    <div className='text-black font-semibold '>ID:</div>
                                </div>
                                <div className='flex flex-row gap-1 justify-between w-1/5'>
                                    <div className='text-black font-semibold'>Erstellt am:</div>

                                </div>
                                <div className='flex flex-row gap-1 justify-between w-1/5'>
                                    <div className='text-black font-semibold'>Erstellt von:</div>
                                    <div className='text-black'>{"createdByName"}</div>
                                </div>
                            </div>
                        : <div className='flex flex-col p-3 border-b gap-3'>
                            <div className='text-black text-3xl pb-3'>Neues Dokument anlegen</div>
                            <div className='text-black text-xl pb-3'>
                                Hier kannst du ein neues <code>document</code> in der collection <code>documents</code> erstellen, und die PDF dafür generieren lassen
                                <br/>
                                (ja ich weiß ist verwirrend, ist mir aber egal).
                            </div>
                            <div className='flex flex-col pb-3 gap-3 '>
                                <div className='text-black text-2xl pb-3'>Generelles</div>
                                <div className='flex flex-col gap-2'>
                                    <label className='text-xl font-bold'>Schritt 1</label>
                                    <select className='p-3' value={pdfTemplateSelected} onChange={(e) => {setPdfTemplateSelected(e.target.value)}}>
                                        <option value="null">Wähle einen Dokumenten type aus</option>
                                        {pdfSelectOptions.map((opt) => {
                                            return( <option value={opt.value}>{opt.label}</option> )
                                        })}
                                    </select>
                                </div>
                                {pdfTemplateSelected === "invoice" && 
                                    <div className='flex flex-col gap-2 animate__animated animate__fadeIn'>
                                        <label className='text-xl font-bold'>Schritt 2</label>                            
                                        <select className='p-3 ' value={selectedProduct} onChange={(e) => {setSelectedProduct(e.target.value)}}>
                                            <option value="null">Wähle ein Produkt aus</option>
                                            {elgioProductsList.map((opt) => {
                                                return(
                                                    <option value={opt.value}>{opt.label}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                }
                                {selectedProduct === "marketing_bundle" && 
                                    <div className='flex flex-col gap-2 animate__animated animate__fadeIn'>
                                        <label className='text-xl font-bold'>Schritt 3</label>
                                        <InputWithLabel label='Event-ID' inputType='text' inputValue={eventId} setInputValue={setEventId}></InputWithLabel>
                                    </div>
                                }
                            </div>
                            <div className="flex flex-col gap-3">
                                <InputWithLabel label='Business Info Name' inputValue={businessInfoName} setInputValue={setBusinessInfoName} placeholder='z.B. Niclas Reif'></InputWithLabel>
                                <InputWithLabel label='Business Info Straße' inputValue={businessInfoStreet} setInputValue={setBusinessInfoStreet} placeholder='z.B. Auenweg 10'></InputWithLabel>
                                <InputWithLabel label='Business Info Rest' inputValue={businessInfoPlz} setInputValue={setBusinessInfoPlz} placeholder='z.B. DE 51377 Leverkusen'></InputWithLabel>
                                <div className='flex flex-col p-3 border gap-3 border-black rounded'>                                        
                                    {sales.map((ele,index) => {
                                        return(
                                            <>
                                                <div className='w-full justify-between items-center pr-2 flex'>
                                                    Element: {index+1}
                                                    <FontAwesomeIcon icon={faX} className="cursor-pointer" onClick={() => {removeItemFromArray(index)}}></FontAwesomeIcon>
                                                </div>
                                                <div className="flex flex-col p-3 gap-3 border border-black rounded">
                                                    <InputWithLabel label='Wie oft wird das Item abgerechnet? (mit `x` am Ende)' inputValue={ele.amount} inputType="text" setInputValue={(newTimesCount) => {handleTimesCountChange(index,newTimesCount)}} placeholder='z.B. 1'></InputWithLabel>
                                                    <InputWithLabel label='Name des Verkauften Items' inputValue={ele.name} inputType='text' setInputValue={(newName) => {handleNameChange(index,newName)}} placeholder='z.B. Social Media Bundle'></InputWithLabel>
                                                    <InputWithLabel label='Preis (in €)' inputValue={ele.price} inputType='text' setInputValue={(newPrice) => {handlePriceChange(index,newPrice)}} placeholder='z.B. 10,00'></InputWithLabel>
                                                    <InputWithLabel label='Summe (in €)' inputValue={ele.sum} inputType='text' setInputValue={(newSum) => {handleSumChange(index,newSum)}} placeholder='z.B. 10,00'></InputWithLabel>
                                                </div>
                                            </>
                                        )
                                    })}
                                    <btn onClick={addEmptyElement} className="p-2 mb-3 cursor-pointer text-white bg-blue-500 w-fit rounded flex gap-2 items-center">
                                        <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                                        <div>Neues Element</div>
                                    </btn>

                                </div>
                            </div>

                            <btn onClick={newDocument} className={`p-2 mb-3 cursor-pointer text-white bg-blue-500 w-fit`} style={{borderRadius:"7px"}}>Neues Dokument anlegen</btn>
                        </div>
                    }
                    </div> 
                : <div className='loader_for_changelog_content'/>
            }
            </div>
        </div>
  )
}

function InputWithLabel({ containerClassName = "", label = "", labelClassName = "", inputContainerClassName = "", inputBorder = true, inputType = "text", inputValue = "", setInputValue = () => { }, inputClassName = "", disabled = false, placeholder = "", ...props }) {
    return (
        <div className={`${containerClassName} flex flex-col gap-1`}>
            <label className={`text-sm ${labelClassName}`}>{label}</label>
            <div className={`${inputContainerClassName} ${inputBorder && "border"} border-black rounded p-1`}>
                {inputType === "textarea" ?
                    <textarea className={`${inputClassName} w-full`} disabled={disabled} value={inputValue} onChange={(e) => setInputValue(e.target.value)} placeholder={placeholder} {...props} />
                    :
                    <input className={`${inputClassName} w-full`} disabled={disabled} type={inputType} value={inputValue} onChange={(e) => setInputValue(e.target.value)} placeholder={placeholder} {...props} />
                }
            </div>
        </div>
    )
}

export default GenerateDocuments