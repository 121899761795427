import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import UserProvider from './providers/UserProvider';
import CategoriesProvider from './providers/CategoriesProvider';
import './fonts/GlacialIndifference/GlacialIndifference-Bold.otf';
import './fonts/GlacialIndifference/GlacialIndifference-Regular.otf';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
    <UserProvider>
      <CategoriesProvider>
        <ToastContainer></ToastContainer>
        <App />
      </CategoriesProvider>
    </UserProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
