import { useContext, useEffect, useRef, useState } from "react";
import {
    useHistory,
    useParams,
    useLocation
} from "react-router-dom";
import Field from "./Field";
import { firestore } from "./firebase";
import { Prompt } from "react-router-dom";
import { UserContext } from "./providers/UserProvider";
import Section from "./Section";
import queryString from "query-string";
import blocklist from "./blocklist/words.json";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { toast } from "react-toastify";

export default function Event() {
    const ref = useRef();

    const history = useHistory();
    const location = useLocation();

    const { role, user } = useContext(UserContext);

    const [queryParams, setQueryParams] = useState({});
    useEffect(() => setQueryParams(queryString.parse(location.search)), [location.search]);

    const { id } = useParams();

    const [data, setData] = useState({});
    const [event, setEvent] = useState(null);

    const [unsavedChanges, setUnsavedChanges] = useState(false);

    const [openEnd, setOpenEnd] = useState(true);

    const [address, setAddress] = useState(null);
    const [postalCode, setPostalCode] = useState(null);
    const [city, setCity] = useState(null);

    const [activities24Hours, setActivities24Hours] = useState([]);
    const [activities7Days, setActivities7Days] = useState([]);

    const [ticketTypes, setTicketTypes] = useState([]);
    const [addingTicketType, setAddingTicketType] = useState(false);

    const [raffles, setRaffles] = useState([]);
    const [addingRaffle, setAddingRaffle] = useState(false);

    useEffect(() => firestore.collection('events').doc(id).get().then(document => {
        if(document.exists) {
            setData({...document.data(), organizer: document.data().organizer?.filter(e => e !== document.data().venue)??[]});
        } else {
            setData({});
        }
        setEvent(document);
        ref.current.scrollTop = 0;
    }), [id]);

    useEffect(() => {
        setOpenEnd(event?.data()?.start?.seconds === event?.data()?.end?.seconds);
        const splitAddress = event?.data()?.address?.split(', ');
        setAddress(splitAddress?.length > 0 ? splitAddress[0] : '');
        setPostalCode(splitAddress?.length > 0 ? splitAddress[1] : '');
        setCity(event?.data()?.city??(splitAddress?.length > 0 ? splitAddress[2] : ''));
    }, [event]);

    //TODO: fix this mess
    //useEffect(() => JSON.stringify(Object.assign({}, data, {address: undefined, venue: undefined})) !== JSON.stringify(Object.assign({}, event?.data(), {address: undefined, venue: undefined})) ? setUnsavedChanges(true) : setUnsavedChanges(false), [data, event]);

    useEffect(() => event && data?.venue && event?.data()?.venue !== data?.venue ? firestore.collection('organizers').doc(data.venue).get().then(document => {
        if(document.data()?.address) {
            setAddress(document.data().address?.split(', ')[0]);
            setPostalCode(document.data().address?.split(', ')[1]);
        }
        if(document.data()?.city) {
            setCity(document.data().city);
        }
    }) : null, [data?.venue, event]);

    useEffect(() => firestore.collection('activities').where('timestamp', '>', new Date(Date.now() - 24 * 60 * 60 * 1000)).where('event', '==', id).onSnapshot(snap => setActivities24Hours(snap.docs)), [id]);
    useEffect(() => firestore.collection('activities').where('timestamp', '>', new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).where('event', '==', id).onSnapshot(snap => setActivities7Days(snap.docs)), [id]);

    useEffect(() => event && event.ref.collection('tickets').onSnapshot(snap => setTicketTypes(snap.docs)), [event]);

    useEffect(() => event && event.ref.collection('raffles').onSnapshot(snap => setRaffles(snap.docs)), [event]);

    function similarity(str1, str2) {
        if(!str1 || !str2) return 0;
        
        const replaceMap = {
            'straße': 'str',
            'strasse': 'str',
            'festival': '',
            'konzert': '',
            'concert': '',
            'halloween': '',
            'karneval': '',
            'silvester': '',
            'neujahr': '',
            'weihnacht': '',
            [new Date().getFullYear().toString()]: '',
            [(new Date().getFullYear().year + 1).toString()]: '',
        };
    
        let str1Prepared = str1.toLowerCase().replace(/[^a-z0-9]/g, '');
        let str2Prepared = str2.toLowerCase().replace(/[^a-z0-9]/g, '');
    
        for(const key of Object.keys(replaceMap)) {
            str1Prepared = str1Prepared.replace(new RegExp(key, 'g'), replaceMap[key]);
            str2Prepared = str2Prepared.replace(new RegExp(key, 'g'), replaceMap[key]);
        }
    
        const len1 = str1Prepared.length;
        const len2 = str2Prepared.length;
        const matrix = [];
        
        for (let i = 0; i <= len1; i++) {
            matrix[i] = [i];
        }
        
        for (let j = 0; j <= len2; j++) {
            matrix[0][j] = j;
        }
        
        for (let i = 1; i <= len1; i++) {
            for (let j = 1; j <= len2; j++) {
                const cost = str1Prepared[i - 1] === str2Prepared[j - 1] ? 0 : 1;
                matrix[i][j] = Math.min(
                    matrix[i - 1][j] + 1, // deletion
                    matrix[i][j - 1] + 1, // insertion
                    matrix[i - 1][j - 1] + cost // substitution
                );
            }
        }
        
        const distance = matrix[len1][len2];
        const maxLength = Math.max(len1, len2);
        const score = 1 - distance / maxLength;
        
        return score;
    }
    const [potentialDuplicateEvents, setPotentialDuplicateEvents] = useState([]);
    useEffect(async () => {
        if(!event?.data()?.pending_review) {
            setPotentialDuplicateEvents([]);
            return;
        }
        
        const res = [];

        const candidates = await firestore.collection('events')
            .where('start', '>', new Date(event.data().start.toMillis() - 21600000))
            .where('start', '<', new Date(event.data().start.toMillis() + 21600000))
            .get();

        for(const candidate of candidates.docs) {
            if(event.id === candidate.id) continue;

            let similarityScore = 0;
            
            if(event.data().identifier === candidate.data().identifier) similarityScore += 3;
            if(similarity(event.data().name, candidate.data().name) >= 0.5) similarityScore++;
            if(event.data().start.toMillis() === candidate.data().start.toMillis()) similarityScore++;
            if((event.data().venue === candidate.data().venue && event.data().venue) || (similarity(event.data().address, candidate.data().address) >= 0.9 && event.data().address !== 'Adresse unbekannt' && event.data().address !== 'Keine Adresse')) similarityScore++;

            if(similarityScore >= 2) {
                res.push(candidate);
            }
        }

        setPotentialDuplicateEvents(res);
    }, [event]);

    const [blocklistedWords, setBlocklistedWords] = useState([]);
    useEffect(() => {
        if(!blocklist || !event?.exists) {
            setBlocklistedWords([]);
            return;
        }
        const nameLower = ' ' + (event.data().name?.toLowerCase()??'') + ' ';
        const descriptionLower = ' ' + (event.data().description?.toLowerCase()??'') + ' ';
        setBlocklistedWords(blocklist.filter(e => nameLower.includes(` ${e} `) || descriptionLower.toLowerCase().includes(` ${e} `)));
    }, [blocklist, event]);

    const back = () => {
        if(!queryParams.return) return false;
        history.push(queryParams.return);
        return true;
    }

    const save = async () => {
        data.organizer && (data.organizer = await Promise.all(data.organizer.filter(e => e).map(async organizer => organizer.startsWith('$') ? await firestore.collection('organizers').add({
            name: organizer.substring(1),
        }).then(e => e.id) : organizer)));
        data.lineup && (data.lineup = await Promise.all(data.lineup.filter(e => e).map(async artist => artist.startsWith('$') ? await firestore.collection('artists').add({
            name: artist.substring(1),
        }).then(e => e.id) : artist)));
        if(address && postalCode && city)
            data.address = address + ', ' + postalCode + ', ' + city.charAt(0).toUpperCase() + city.slice(1);
        else if(address)
            data.address = address;
        if(city)
            data.city = city;
        if(data.venue)
            data.organizer = [...(data.organizer?.filter((e) => e !== event.data()?.venue && e !== data.venue)??[]), data.venue];
        if(openEnd)
            data.end = data.start;
        if(!data.visibility)
            data.visibility = 'public';
        if(data.visibility === 'public')
            data.pending_review = false;
        data.agent = user.uid;
        await firestore.collection('events').doc(id).set(data, { merge: true });
        setUnsavedChanges(false);
        toast.success("Änderungen wurden erfolgreich gespeichert", {theme: "dark"});
        back();
    };

    const shareImage = () => history.push(`/events/${id}/share`);

    const copyLink = () => {
        navigator.clipboard.writeText(`https://app.elgio.de/event/${id}`);
        toast.info("Eventlink in Zwischenablage kopiert", {theme: "dark"});
    };

    const fusion = () => history.push(`/events/${id}/fusion?return=${encodeURIComponent(location.pathname + location.search)}`);

    const remove = async () => {
        if(!window.confirm('Bist du dir sicher, dass du diesen Eintrag löschen willst?')) return;
        await firestore.collection('events').doc(id).delete();
        toast.success("Löschen erfolgreich", {theme: "dark"});
        if(!back())
            history.push('/events' + location.search);
    };

    const verify = async () => {
        if(!window.confirm('Bist du dir sicher, dass du das Event "' + event?.data()?.name + '" freigeben willst?')) return;
        await firestore.collection('events').doc(id).update({
            visibility: 'public',
            pending_review: false,
        });
        toast.success("Freigabe erfolgreich", {theme: "dark"})
        back();
        firestore.collection('events').doc(id).get().then(document => {
            if(document.exists) {
                setData({...document.data(), organizer: document.data().organizer?.filter(e => e !== document.data().venue)??[]});
            } else {
                setData({});
            }
            setEvent(document);
        });
    };

    const block = async () => {
        if(!window.confirm('Bist du dir sicher, dass du das Event "' + event?.data()?.name + '" blockieren willst?')) return;
        await firestore.collection('events').doc(id).update({
            visibility: 'blocked',
            pending_review: false,
        });
        toast.success("Blockieren erfolgreich", {theme: "dark"})
        back();
        firestore.collection('events').doc(id).get().then(document => {
            if(document.exists) {
                setData({...document.data(), organizer: document.data().organizer?.filter(e => e !== document.data().venue)??[]});
            } else {
                setData({});
            }
            setEvent(document);
        });
    };

    //const writePermission = role === 'admin' || event?.data()?.agent === user.uid || !event?.exists;
    const writePermission = true;

    return <div className="h-full flex flex-col">
        <Prompt message="Einige Änderungen wurden noch nicht gespeichert. Trotzdem fortfahren?" when={unsavedChanges} />
        <div className="bg-gray-500 flex flex-col md:flex-row gap-2 p-4 md:py-0 md:px-2">
            {queryParams.return && <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={back}>Zurück</button>}
            {writePermission && <button className="bg-blue-500 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold text-white" onClick={save}>Änderungen speichern</button>}
            <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={shareImage}>Eventgrafik</button>
            <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={copyLink}>Eventlink kopieren</button>
            <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold">
                <a href={"https://elgio.de/event/" + event?.id} target="_blank" rel="noreferrer">Event ansehen</a>
            </button>
            {/*<button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={() => alert('Diese Funktion wurde noch nicht implementiert.')}>Daten von Facebook importieren</button>*/}
            {role === 'admin' && <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={fusion}>Mit anderem Event vereinigen</button>}
            {role === 'admin' && <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={block}>Event blockieren</button>}
            {role === 'admin' && <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={remove}>Event löschen</button>}
            {role === 'admin' && 
            <a href={"https://console.firebase.google.com/u/1/project/elgio-invited/firestore/databases/-default-/data/~2Fevents~2F" + id} 
                target="_blank" rel="noreferrer"
                className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold flex items-center text-center"> 
                    In der Datenbank ansehen
            </a>}
            <div className="bg-gray-500 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold hidden md:block text-gray-500">a</div>
        </div>
        {
            id && event ? <div className="p-3 overflow-scroll" key={event.id} ref={ref}>
                {role === 'admin' && event?.data()?.pending_review && <div className="mb-3 p-3 bg-gray-600 text-white rounded">
                    <div className="font-semibold">Dieses Event erwartet zur Zeit Verifizierung.</div>
                    {
                        potentialDuplicateEvents.length > 0
                            ? <div className="text-red-500">
                                <div>Es wurden die folgenden ähnlichen Events gefunden:</div>
                                {potentialDuplicateEvents.map(e => <div>- {e.data().name} am {event?.data()?.start?.toDate()?.toLocaleString('de-DE')} (<Link to={`/events/${e.id}?return=${encodeURIComponent(location.pathname + location.search)}`} className="underline hover:font-semibold">Event anzeigen</Link>) (<Link to={`/events/${id}/fusion/${e.id}`} className="underline hover:font-semibold">Events vereinigen</Link>)</div>)}
                            </div>
                            : <div className="text-green-500">Es wurden keine ähnlichen Events gefunden.</div>
                    }
                    {
                        blocklistedWords.length > 0
                            ? <div className="text-red-500">Es wurden im Event folgende Wörter auf der Blocklist gefunden: {blocklistedWords.join(', ')}</div>
                            : <div className="text-green-500">Es wurden im Event keine Wörter auf der Blocklist gefunden.</div>
                    }
                    <div className="flex gap-3 mt-3">
                        <button className="bg-gray-400 p-2 bg-green-500" onClick={verify}>Freigeben</button>
                        <button className="bg-gray-400 p-2 bg-red-500" onClick={block}>Blockieren</button>
                    </div>
                </div>}
                <Section title="Metadaten">
                    <div className="flex-col md:flex-row flex my-1">
                        <div className="w-64">ID:</div>
                        <div className="w-64">{id}</div>
                    </div>
                    <div className="flex-col md:flex-row flex my-1">
                        <div className="w-64">Erstellt:</div>
                        <div className="w-64">{event?.data()?.created_timestamp?.toDate()?.toLocaleString('de-DE')}</div>
                    </div>
                    <div className="flex-col md:flex-row flex my-1">
                        <div className="w-64">Zuletzt bearbeitet von:</div>
                        <div className="w-64">{event?.data()?.agent}</div>
                    </div>
                    {event?.data()?.external_identifiers && Object.keys(event.data().external_identifiers).length > 0 && <div className="flex-col md:flex-row flex my-1">
                        <div className="w-64">Scraper:</div>
                        <div className="w-64">{Object.keys(event.data().external_identifiers).join(', ')}</div>
                    </div>}
                    {event?.data()?.partner && <div className="flex-col md:flex-row flex my-1">
                        <div className="w-64">Erstellt mit Partner-Formular:</div>
                        <div className="w-64">{event?.data()?.partner}</div>
                    </div>}
                </Section>
                <Section title="Statistiken">
                    <div className="flex-col md:flex-row flex my-1">
                        <div className="w-64 font-semibold">In den letzten 24 Stunden:</div>
                        <div className="w-32">{activities24Hours.filter(e => e.data().type === 'event_view').length??0} Views</div>
                        <div className="w-32">{activities24Hours.filter(e => e.data().type === 'bookmark').length??0} Merkungen</div>
                        <div className="w-32">{activities24Hours.filter(e => e.data().type === 'event_share').length??0} Teilungen</div>
                        <div className="w-32">{activities24Hours.filter(e => e.data().type === 'attending').length??0} Teilnehmer</div>
                    </div>
                    <div className="flex-col md:flex-row flex my-1">
                        <div className="w-64 font-semibold">In den letzten 7 Tagen:</div>
                        <div className="w-32">{activities7Days.filter(e => e.data().type === 'event_view').length??0} Views</div>
                        <div className="w-32">{activities7Days.filter(e => e.data().type === 'bookmark').length??0} Merkungen</div>
                        <div className="w-32">{activities7Days.filter(e => e.data().type === 'event_share').length??0} Teilungen</div>
                        <div className="w-32">{activities7Days.filter(e => e.data().type === 'attending').length??0} Teilnehmer</div>
                    </div>
                    <div className="flex-col md:flex-row flex my-1">
                        <div className="w-64 font-semibold">Insgesamt:</div>
                        <div className="w-32">{event?.data()?.view_count??0} Views</div>
                        <div className="w-32">{event?.data()?.bookmark_count??0} Merkungen</div>
                        <div className="w-32">{event?.data()?.share_count??0} Teilungen</div>
                        <div className="w-32">{event?.data()?.attendee_count??0} Teilnehmer</div>
                    </div>
                </Section>
                <Section title="Daten" openDefault>
                    {role === 'admin' && <Field name="Sichtbarkeit" value={data?.visibility??'public'} type="dropdown" options={['public', 'private', 'draft', 'blocked']} onChange={(val) => setData({...data, ...{visibility: val}})} />}
                    <Field name="Name" value={data?.name} onChange={(val) => setData({...data, ...{name: val}})} />
                    <Field name="Veranstalter" value={data?.organizer} type="array-organizer" onChange={(val) => setData({...data, ...{organizer: val}})} />
                    <Field name="Startzeitpunkt" value={data?.start} type="timestamp" onChange={(val) => setData({...data, start: val})} />
                    <Field name="Offenes Ende?" value={openEnd} type="checkbox" onChange={(val) => setOpenEnd(val)} />
                    {!openEnd && <Field name="Endzeitpunkt" value={data?.end} type="timestamp" onChange={(val) => setData({...data, end: val})} />}
                    <Field name="Abgesagt?" value={data?.cancelled} type="checkbox" onChange={(val) => setData({...data, cancelled: val})} />
                    <Field name="Venue" value={data?.venue} type="organizer" onChange={(val) => setData({...data, ...{venue: val}})} />
                    <Field name="Straße + Hausnummer" value={address} onChange={(val) => setAddress(val)} />
                    <Field name="Postleitzahl" value={postalCode} onChange={(val) => setPostalCode(val)} />
                    <Field name="Stadt" value={city} type="city" allowOther={true} onChange={(val) => setCity(val)} />
                    <Field name="Preis (in Euro)" value={data?.price} onChange={(val) => setData({...data, ...{price: Number(val)}})}/>
                    <Field name="Ticketlink" value={data?.ticket_link} type="link" onChange={(val) => setData({...data, ...{ticket_link: val}})} />
                    <Field name="Secret Location" value={data?.secret_location} type="checkbox" onChange={(val) => setData({...data, ...{secret_location: val}})} />
                    <Field name="Secret Location Reveal Time" value={data?.secret_location_reveal_time} type="text" onChange={(val) => setData({...data, ...{secret_location_reveal_time: val}})} />
                    <Field name="Lineup" value={data?.lineup} type="array-artist" onChange={(val) => setData({...data, ...{lineup: val}})} />
                    <Field name="Specials" value={data?.specials} type="array-special" onChange={(val) => setData({...data, ...{specials: val}})}/>
                    <Field name="Musikrichtungen" value={data?.genre} type="array-genre" onChange={(val) => setData({...data, ...{genre: val}})} />
                    <Field name="Eventkategorien" value={data?.type} type="array-type" onChange={(val) => setData({...data, ...{type: val}})} />
                    <Field name="Mindestalter" value={data?.min_age} onChange={(val) => setData({...data, ...{min_age: Number(val)}})}/>
                    <Field name="Dresscode" value={data?.dresscode} onChange={(val) => setData({...data, ...{dresscode: val}})}/>
                    <Field name="Beschreibung" value={data?.description} type="multiline" onChange={(val) => setData({...data, ...{description: val}})} />
                    <Field name="Bild" value={data?.images ? data.images[0] : null} type="image" fileDirectory={`event_images/${id}`} onChange={(val) => setData({...data, ...{images: [val]}})} />
                </Section>
                {role === 'admin' && <Section title="Ticketing">
                    <button onClick={() => history.push(`/tickets?event=${id}&return=${encodeURIComponent(location.pathname + location.search)}`)} className="bg-blue-500 text-white rounded px-2 py-3 font-semibold">Alle Tickets anzeigen</button>
                    <div className="flex flex-wrap gap-3 mt-3">
                        {ticketTypes.map(ticketType => <TicketTypeBox event={event} ticketType={ticketType} />)}
                        {addingTicketType ? <TicketTypeBox event={event} onTicketTypeAdded={() => setAddingTicketType(false)} /> : <div className="border rounded px-10 w-full md:w-auto flex items-center justify-center h-64">
                            <button className="bg-blue-500 text-white rounded px-2 py-3 font-semibold" onClick={() => setAddingTicketType(true)}>Ticketart hinzufügen</button>
                        </div>}
                    </div>
                </Section>}
                {role === 'admin' && <Section title="Gewinnspiele">
                    <div className="flex flex-wrap gap-3">
                        {raffles.map(raffle => <RaffleBox raffle={raffle} />)}
                        {addingRaffle ? <RaffleBox event={event} onRaffleAdded={() => setAddingRaffle(false)} /> : <div className="border rounded px-10 w-full md:w-auto flex items-center justify-center h-64">
                            <button className="bg-blue-500 text-white rounded px-2 py-3 font-semibold" onClick={() => setAddingRaffle(true)}>Gewinnspiel hinzufügen</button>
                        </div>}
                    </div>
                </Section>}
            </div> : <div className="p-3">
                Bitte warten...
            </div>
        }
    </div>;
}

function TicketTypeBox({ ticketType, event, onTicketTypeAdded }) {
    const history = useHistory();
    const location = useLocation();

    const [data, setData] = useState(ticketType?.data()??{});

    return <div className="border rounded p-3 w-full md:w-auto min-h-64">
        {ticketType && <Field name="ID" value={ticketType?.id} type="constant" />}
        <Field name="Name" value={data?.name} onChange={(val) => setData({...data, ...{name: val}})} />
        <Field name="Preis (Euro-Cent)" value={data?.price} onChange={(val) => setData({...data, ...{price: Number(val)}})} />
        <Field name="Ticketanzahl insgesamt" value={data?.total_tickets} onChange={(val) => setData({...data, ...{total_tickets: Number(val)}})} />
        {ticketType && <Field name="Tickets verkauft" value={ticketType.data().total_tickets - ticketType.data().available_tickets} type="constant" />}
        {ticketType && <Field name="Tickets verfügbar" value={ticketType.data().available_tickets} type="constant" />}
        {ticketType && data && Object.keys(data).some(e => data[e]?.toString() !== ticketType.data()[e]?.toString()) && <button onClick={() => ticketType.ref.update(data)} className="bg-blue-500 text-white rounded px-2 py-3 font-semibold">Änderungen speichern</button>}
        {!ticketType && data.name && data.price && data.total_tickets && <button onClick={() => event.ref.collection('tickets').add({available_tickets: data.total_tickets, ...data}).then(onTicketTypeAdded)} className="bg-blue-500 text-white rounded px-2 py-3 font-semibold">Live schalten</button>}
        {ticketType && <button onClick={() => history.push(`/tickets?event=${event.id}&type=${ticketType.id}&return=${encodeURIComponent(location.pathname + location.search)}`)} className="bg-blue-500 text-white rounded px-2 py-3 font-semibold">Tickets anzeigen</button>}
    </div>;
}

function RaffleBox({ raffle, event, onRaffleAdded }) {
    const [id, setId] = useState(null);
    const [data, setData] = useState(raffle?.data()??{});
    const [participants, setParticipants] = useState([]);
    const [winners, setWinners] = useState([]);

    useState(() => raffle && raffle.ref.collection('participants').onSnapshot(snap => setParticipants(snap.docs)), [raffle]);
    useState(() => raffle?.data().winners && Promise.all(raffle.data().winners.map(e => firestore.collection('users').doc(e).get())).then(docs => setWinners(docs)), [raffle]);

    return <div className="border rounded p-3 w-full md:w-auto min-h-64">
        {raffle?.data().winners_drawn ? <>
            <div>Die Gewinner wurden gezogen.</div>
            <div>Ziehungzeitpunkt: {raffle.data().draw_timestamp.toDate().toLocaleString('de-DE')}</div>
            <div>Teilnehmer: {participants.length}</div>
            <div>Gewinner: {winners.map(e => <div>
                <a className="underline cursor-pointer" target="_blank" href={`/users/${e.id}`}>@{e.data().username}</a>
                &nbsp;(<a className="underline cursor-pointer" target="_blank" href={`mailto:${e.data().email}`}>@{e.data().email}</a>)
            </div>)}</div>
        </> : <>
            <Field name="ID" value={raffle?.id??id} type={raffle ? 'constant' : 'text'} onChange={(val) => setId(val)} />
            {raffle && <Field name="Teilnehmer" value={participants.length} type="constant" />}
            <Field name="Typ" value={data?.type??'tickets'} type="dropdown" options={['tickets']} onChange={(val) => setData({...data, ...{type: val}})} />
            <Field name="Tickets pro Gewinn" value={data?.tickets_per_win} onChange={(val) => setData({...data, ...{tickets_per_win: Number(val)}})} />
            <Field name="Max. Gewinneranzahl" value={data?.max_winners} onChange={(val) => setData({...data, ...{max_winners: Number(val)}})} />
            <Field name="Ziehungszeitpunkt" value={data?.draw_timestamp} type="timestamp" onChange={(val) => setData({...data, ...{draw_timestamp: val}})} />
            {raffle && data && Object.keys(data).some(e => data[e].toString() !== raffle.data()[e].toString()) && <button onClick={() => raffle.ref.update(data)} className="bg-blue-500 text-white rounded px-2 py-3 font-semibold">Änderungen speichern</button>}
            {!raffle && id && data.type && data.max_winners && data.tickets_per_win && data.draw_timestamp && <button onClick={() => event.ref.collection('raffles').doc(id).set(data).then(onRaffleAdded)} className="bg-blue-500 text-white rounded px-2 py-3 font-semibold">Live schalten</button>}
        </>}
    </div>;
}