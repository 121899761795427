import React, {useState} from "react";
import { auth } from "./firebase.js";

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    const signInWithEmailAndPasswordHandler = (event,email, password) => {
        event.preventDefault();
        auth.signInWithEmailAndPassword(email, password).catch(error => {
        setError("Falsche E-Mail-Adresse oder falsches Passwort");
          console.error("Error signing in with password and email", error);
        });
      };
      
      const onChangeHandler = (event) => {
          const {name, value} = event.currentTarget;
        
          if(name === 'userEmail') {
            setEmail(value);
          }
          else if(name === 'userPassword'){
            setPassword(value);
          }
      };

  return <div className="text-white bg-black grid place-items-center h-screen">
    <div className="w-10/12 md:w-1/3">
      <div className="text-center mb-10 text-4xl md:text-6xl font-bold">ELGIO Admin</div>
      <form className="flex flex-col">
        <div>Bitte melde dich an.</div>
        <input type="email" className="text-black border border-black rounded px-4 py-2 my-1" name="userEmail" value={email} placeholder="E-Mail Adresse" id="userEmail" onChange={(event) => onChangeHandler(event)} />
        <input type="password" className="text-black border border-black rounded px-4 py-2 my-1" name="userPassword" value={password} placeholder="Passwort" id="userPassword" onChange={(event) => onChangeHandler(event)} />
        <button type="submit" className="bg-blue-500 rounded px-2 py-2 my-1 text-lg font-semibold" onClick = {(event) => {signInWithEmailAndPasswordHandler(event, email, password)}}>Login</button>
        <div>{error}</div>
      </form>
    </div>
  </div>;
}