import {
    Switch,
    Route,
    useRouteMatch,
  } from "react-router-dom";
import Report from "./Report";
import Sidebar from "./Sidebar";

export default function Reports() {
    let match = useRouteMatch();
    
    return <div className="h-full flex w-auto">
        <Sidebar collection="reports" itemTitle={(item) => item.objectID.split('-')[1]} itemLink={(item) => `/reports/${item.objectID.split('-')[1]}`} />
        <div className="flex-grow overflow-auto">
            <Switch>
                <Route path={`${match.path}/:id`} component={Report}/>
                <Route path={match.path}>
                    <p className="p-3">
                        Bitte selektiere eine Meldung.
                    </p>
                </Route>
            </Switch>
        </div>
    </div>;
}