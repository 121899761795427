import { useHistory } from "react-router-dom";
import {
    Switch,
    Route,
    useRouteMatch,
  } from "react-router-dom";
import Type from "./Type";
import Sidebar from "./Sidebar";

export default function Types() {
    const history = useHistory();

    const newType = () => {
        const id = prompt('Bitte ID für neue Eventtyp eingeben. Normalerweise englischer Name in Kleinbuchstaben und zusammengeschriben (z.B. "hiphop" oder "kpop").');
        if(id) history.push(`/types/${id}`);
    };

    let match = useRouteMatch();
    
    return <div className="h-full flex w-auto">
        <Sidebar newItem={newType} newItemText="Neuer Eventtyp" collection="types" itemTitle={(item) => item.de} itemLink={(item) => `/types/${item.objectID.split('-')[1]}`} />
        <div className="flex-grow overflow-auto">
            <Switch>
                <Route path={`${match.path}/:id`} component={Type}/>
                <Route path={match.path}>
                    <p className="p-3">
                        Bitte selektiere ein Eventtyp.
                    </p>
                </Route>
            </Switch>
        </div>
    </div>;
}