import { useContext, useEffect, useState } from "react";
import { Prompt } from "react-router-dom";
import {
    useParams,
    useHistory,
    useLocation,
} from "react-router-dom";
import Field from "./Field";
import { firestore } from "./firebase";
import { UserContext } from "./providers/UserProvider";
import Section from "./Section";
import queryString from "query-string";
import { toast } from "react-toastify";


export default function Ticket() {
    //Context
    const { role,user } = useContext(UserContext);
    const { id } = useParams();

    const history = useHistory();
    const location = useLocation();

    //Query params
    const [queryParams, setQueryParams] = useState({});
    useEffect(() => setQueryParams(queryString.parse(location.search)), [location.search]);
    
    //Item
    const [data, setData] = useState({});
    const [ticket, setTicket] = useState(null);
    useEffect(() => firestore.collection('tickets').doc(id).get().then(document => {
        setData(document.data());
        setTicket(document);
    }), [id]);

    //Additional data
    const [event, setEvent] = useState(null);
    useEffect(() => ticket && firestore.collection('events').doc(ticket.data().event).get().then(document => setEvent(document)), [ticket]);
    const [ticketType, setTicketType] = useState(null);
    useEffect(() => ticket && event && event.ref.collection('tickets').doc(ticket.data().type).get().then(document => setTicketType(document)), [ticket, event]);

    //Unsaved changes
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    useEffect(() => JSON.stringify(data) !== JSON.stringify(ticket?.data()) ? setUnsavedChanges(true) : setUnsavedChanges(false), [ticket, data]);

    //Functions
    const back = () => {
        if(!queryParams.return) return false;
        history.push(queryParams.return);
        return true;
    }

    const save = async () => {
        data.agent = user.uid;
        await firestore.collection('tickets').doc(id).set(data, { merge: true });
        setUnsavedChanges(false);
        toast.success("Speichern erfolgreich", {theme: "dark"})
        back();
    };

    return <div>
        <Prompt message="Einige Änderungen wurden noch nicht gespeichert. Trotzdem fortfahren?" when={unsavedChanges} />
        <div className="bg-gray-500 flex flex-col md:flex-row gap-2 p-4 md:py-0 md:px-2">
            {queryParams.return && <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={back}>Zurück</button>}
            <button className="bg-blue-500 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold text-white" onClick={save}>Änderungen speichern</button>
            <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={() => window.open(`https://us-central1-elgio-invited.cloudfunctions.net/tickets-getPDFTickets?ids=${id}`, '_blank')}>PDF-Ticket herunterladen</button>
            {role === 'admin' && 
            <a href={"https://console.firebase.google.com/u/1/project/elgio-invited/firestore/databases/-default-/data/~2Ftickets~2F" + id} 
                target="_blank" rel="noreferrer"
                className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold flex items-center text-center"> 
                    In der Datenbank ansehen
            </a>}
            <div className="bg-gray-500 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold hidden md:block text-gray-500">a</div>
        </div>
        {
            id && ticket ? <div className="p-3" key={ticket.id}>
                <Section title="Metadaten">
                    <div className="flex-col md:flex-row flex my-1">
                        <div className="w-64">ID:</div>
                        <div className="w-64">{id}</div>
                    </div>
                </Section>
                <Section title="Ticket" openDefault>
                <div className="flex-col md:flex-row flex my-1">
                        <div className="w-64">Code:</div>
                        <div className="w-64">{data?.code}</div>
                    </div>
                    <div className="flex-col md:flex-row flex my-1">
                        <div className="w-64">Event:</div>
                        <div className="w-64">{event?.data()?.name??data?.event}</div>
                    </div>
                    <div className="flex-col md:flex-row flex my-1">
                        <div className="w-64">Tickettyp:</div>
                        <div className="w-64">{ticketType?.data()?.name??data?.type}</div>
                    </div>
                    <div className="flex-col md:flex-row flex my-1">
                        <div className="w-64">Anmerkung:</div>
                        <div className="w-64">{data?.comment}</div>
                    </div>
                </Section>
                <Section title="Status" openDefault>
                    <div className="flex-col md:flex-row flex my-1">
                        <div className="w-64">Status:</div>
                        <div className="w-64">{data?.status}</div>
                    </div>
                </Section>
                <Section title="Zahlung" openDefault>
                    <div className="flex-col md:flex-row flex my-1">
                        <div className="w-64">Ticketpreis (Euro-Cent):</div>
                        <div className="w-64">{data?.price}</div>
                    </div>
                    <div className="flex-col md:flex-row flex my-1">
                        <div className="w-64">Rechnungsnummer:</div>
                        <div className="w-64">{data?.payment}</div>
                    </div>
                </Section>
                <Section title="Daten" openDefault>
                    <Field name="Vorname" value={data?.first_name} onChange={(val) => setData({...data, ...{first_name: val}})} />
                    <Field name="Nachname" value={data?.last_name} onChange={(val) => setData({...data, ...{last_name: val}})} />
                    <Field name="E-Mail" value={data?.email} onChange={(val) => setData({...data, ...{email: val}})} />
                    {/* <Field name="User" value={data?.user} type="user" onChange={(val) => setData({...data, ...{user: val}})} /> */}
                </Section>
            </div> : <div className="p-3">
                Bitte warten...
            </div>
        }
    </div>;
}