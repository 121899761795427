import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { firestore } from "./firebase";
import queryString from "query-string";
import Sidebar from "./Sidebar";
import FirebaseStorageImage from "./widgets/FirebaseStorageImage.jsx";
import { collection, doc, getDoc } from "firebase/firestore";
import PlaceHolderImg from "./designs/No_Img.png";

export default function Backlog() {
    const history = useHistory();
    const location = useLocation();

    const [queryParams, setQueryParams] = useState({});
    const [tasks, setTasks] = useState([]);
    const [tasksSum, setTasksSum] = useState(0);

    useEffect(() => {
        setQueryParams(queryString.parse(location.search));
    }, [location.search]);

    useEffect(() => {
        const unsubscribe = firestore.collection('backlog')
            .orderBy(queryParams.orderField ?? 'task', queryParams.orderDirection ?? 'asc')
            .onSnapshot(async snapshot => {
                const t = snapshot.docs;
                setTasksSum(t.length);
                setTasks(t);
            });

        return () => unsubscribe();
    }, [queryParams]);

    const open = (task) => {
        switch (task.data().type) {
            case 'check_found_organizer':
                window.open(task.data().object.replace('m.', 'www.'), '_blank');
                break;
            case 'check_new_organizer':
                history.push(`/organizers/${task.data().object}?return=${encodeURIComponent(location.pathname + location.search)}`);
                break;
            case 'check_new_event':
                history.push(`/events/${task.data().object}?return=${encodeURIComponent(location.pathname + location.search)}`);
                break;
            case 'check_duplicate_event':
                history.push(`/events/${task.data().object[0]}/fusion/${task.data().object[1]}?return=${encodeURIComponent(location.pathname + location.search)}`);
                break;
            case 'check_new_artist':
                history.push(`/artists/${task.data().object}?return=${encodeURIComponent(location.pathname + location.search)}`);
                break;
            case 'check_edited_artist':
                history.push(`/artists/${task.data().object[0]}/edit-suggestion/${task.data().object[1]}?return=${encodeURIComponent(location.pathname + location.search)}`);
                break;
            case 'check_suggestion':
                history.push(`/suggestions/${task.data().object}?return=${encodeURIComponent(location.pathname + location.search)}`);
                break;
            case 'check_report':
                history.push(`/reports/${task.data().object}?return=${encodeURIComponent(location.pathname + location.search)}`);
                break;
            default:
                break;
        }
    };

    return (
        <div className="h-full flex w-auto">
            <Sidebar defaultOpen={false} />
            <div className="flex-grow overflow-auto">
                <div className="h-screen flex flex-col overflow-hidden">
                    <div className="bg-gray-600 flex flex-col md:flex-row gap-2 p-3 items-center">
                        <div className="text-gray-400 text-xl font-semibold">
                            Offene Tasks: <span className="text-white"> {tasksSum}</span>
                        </div>
                    </div>
                    <div className="p-3 overflow-y-scroll">
                        <div className="grid grid-cols-12">
                            <div className="p-1 col-span-2">
                                <button
                                    className="font-semibold"
                                    onClick={() =>
                                        history.replace({
                                            pathname: location.pathname,
                                            search: `?orderField=task&orderDirection=${(queryParams.orderField === 'task' && queryParams.orderDirection === 'asc') || !queryParams.orderField ? 'desc' : 'asc'}`,
                                        })
                                    }
                                >
                                    Task &nbsp;
                                    {queryParams.orderField === 'task' || !queryParams.orderField
                                        ? queryParams.orderDirection === 'desc'
                                            ? <>&uarr;</>
                                            : <>&darr;</>
                                        : null}
                                </button>
                            </div>
                            <div className="p-1 col-span-6">
                                <button
                                    className="font-semibold"
                                    onClick={() =>
                                        history.replace({
                                            pathname: location.pathname,
                                            search: `?orderField=details&orderDirection=${queryParams.orderField === 'details' && queryParams.orderDirection === 'asc' ? 'desc' : 'asc'}`,
                                        })
                                    }
                                >
                                    Details &nbsp;
                                    {queryParams.orderField === 'details'
                                        ? queryParams.orderDirection === 'desc'
                                            ? <>&uarr;</>
                                            : <>&darr;</>
                                        : null}
                                </button>
                            </div>
                            <div className="p-1 col-span-2">
                                <button
                                    className="font-semibold"
                                    onClick={() =>
                                        history.replace({
                                            pathname: location.pathname,
                                            search: `?orderField=created_timestamp&orderDirection=${queryParams.orderField === 'created_timestamp' && queryParams.orderDirection === 'desc' ? 'asc' : 'desc'}`,
                                        })
                                    }
                                >
                                    Erstellungszeit &nbsp;
                                    {queryParams.orderField === 'created_timestamp'
                                        ? queryParams.orderDirection === 'asc'
                                            ? <>&uarr;</>
                                            : <>&darr;</>
                                        : null}
                                </button>
                            </div>
                            <div className="font-semibold p-1 text-center">Action</div>
                            <div className="font-semibold p-1 text-center">Erledigt?</div>
                        </div>
                            {tasks.map(task => {
                                let taskData = task.data();                                                                
                                
                                return (
                                    <div className="flex flex-col">
                                        <div key={task.id} className="grid grid-cols-12 border-t border-b">
                                            <div className="overflow-hidden p-1 col-span-2 flex items-center">{taskData.task}</div>
                                            {['check_duplicate_event', 'check_new_event', 'check_new_organizer', 'check_new_artist', 'check_edited_artist'].includes(taskData.type) ? (() => {
                                                let objectType;
                                                switch(taskData.type) {
                                                    case 'check_duplicate_event':
                                                    case 'check_new_event':
                                                        objectType = 'event';
                                                        break;
                                                    case 'check_new_organizer':
                                                        objectType = 'organizer';
                                                        break;
                                                    case 'check_new_artist':
                                                    case 'check_edited_artist':
                                                        objectType = 'artist';
                                                        break;
                                                    default:
                                                        break;
                                                }
                                                return <div className="overflow-hidden py-2 px-1 pr-7 col-span-6 flex items-center justify-between">
                                                    {Array.isArray(taskData.object) ? taskData.object.map((obj, index) =>  (
                                                        <div key={index} className="w-1/2">
                                                            <EntityDetails entity={obj} type={objectType} />
                                                        </div>
                                                    )) : <EntityDetails entity={taskData.object} type={objectType} />}
                                                </div>;
                                            })() : (
                                                <div className="overflow-hidden py-2 px-1 pr-7 col-span-6 flex items-center justify-between">
                                                    {taskData.details}
                                                </div>
                                            )}
                                            <div className="overflow-hidden p-1 col-span-2 flex items-center">{taskData.created_timestamp?.toDate()?.toLocaleString('de-DE') ?? 'N/A'}</div>
                                            <div className="overflow-hidden p-1 flex items-center justify-center">
                                                <button
                                                    className="py-2 px-3 bg-slate-200 rounded"
                                                    onClick={() => open(task)}
                                                >
                                                    {taskData.type === 'check_duplicate_event' ? 'Mergen' : 'Öffnen'}
                                                </button>
                                            </div>
                                            <div className="overflow-hidden p-1 flex items-center justify-center">
                                                <button
                                                    className="py-2 px-3 bg-green-400 rounded"
                                                    onClick={() => window.confirm(`Bist du dir sicher, dass du "${taskData.task}" erledigt hast?`) ? task.ref.delete() : null}
                                                >
                                                    Task erledigt
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            } )}
                        </div>
                    </div>
            </div>
        </div>
    );
}

function EntityDetails({ entity, type }) {
    const [entityData, setEntityData] = useState(null);

    useEffect(() => {
        if(!entity || !type) {
            setEntityData(null);
            return;
        }

        getDoc(doc(collection(firestore, type + 's'), entity)).then(doc => {
            setEntityData({
                ...doc.data(),
                id: doc.id,
                image: doc.data().images?.[0],
                details: type === 'event' ? [
                    doc.data().start?.toDate()?.toLocaleString('de-DE') ?? 'Kein Startdatum',
                    doc.data().venue_data?.name,
                    doc.data().address,
                ] : type === 'artist' ? [
                ] : type === 'organizer' ? [
                ] : [],
            });
        });
    }, [entity, type]);

    if(!entityData) return null;

    return <div className="flex gap-2 items-center">
        <FirebaseStorageImage alt="" reference={entityData.image} className="w-36 h-20 bg-black object-cover rounded shrink-0" />
        <div>
            <div>{entityData.name}</div>
            {entityData.details?.map((detail, index) => <div key={index} className="text-sm text-slate-400">{detail}</div>)}
        </div>
    </div>
}
